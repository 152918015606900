.cookie-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #212529;
    color: #ffffff;
    padding: .75rem 1rem;
    z-index: 201;
}

@media screen and (max-width: 767px) {
    .cookie-bar {
        display: flex;
        flex-direction: column;
    }
}
@media screen and (min-width: 768px) {
    .cookie-bar {
        display: flex;
        flex-wrap: wrap;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        bottom: 4rem;
        width: 700px;
    }
}
@media screen and (min-width: 1200px) {
    .cookie-bar {
        bottom: 1rem;
    }
}
.modal-header.cookie-modal__header {/*multiple classes needed for specificity*/
    background-color: transparent;
    box-shadow: none;
}
.modal-footer.cookie-modal__footer {/*multiple classes needed for specificity*/
    box-shadow: none;
}
@media screen and (max-width: 767px) {
    .modal-footer.cookie-modal__footer {
        text-align: right;
    }
}
.cookie-bar__text {
    display: block;
    @media screen and (max-width: 767px) {
        order: 1;
    }
}

@media screen and (max-width: 767px) {
    .cookie-bar__text p {
        font-size: 12px;
        line-height: 1.3;
        margin-bottom: 10px;
    }
}

@media screen and (min-width: 768px) {
    .cookie-bar__text {
        display: block;
        flex: auto;
    }
}

.cookie-bar__detail,
a.cookie-bar__detail {
    display: inline;
}

@media screen and (max-width: 767px) {
    .cookie-bar__detail {
        order: 3;
    }
}
@media screen and (min-width: 768px) {
    .cookie-bar__detail {
        flex: auto;
        /*margin-left: 1rem;*/
        width: auto;
        margin-left: 10px;
        order: 3;
    }
}
.cookie-bar__detail-btn {
    display: inline;
    text-decoration: underline;
    vertical-align: inherit;
    cursor: pointer;
    margin-right: .25em;
}
@media screen and (max-width: 767px) {
    .cookie-bar__detail-btn {
        font-size: 12px !important;
        padding: 8px 10px !important;
        border: none;
    }
}

.cookie-bar__detail-btn:hover, cookie-bar__detail-btn:active {
    color: #fff;
    text-decoration: none;
}

.cookie-bar__detail-btn:visited {
    color: #fff;
    text-decoration: underline;
}

.cookie-bar__buttons {
    margin-top: .75rem;
    text-align: left;
}
@media screen and (max-width: 767px) {
    .cookie-bar__buttons {
        order: 2;
        margin-top: 0;
        margin-bottom: .75rem;
    }
}
@media screen and (min-width: 768px) {
    .cookie-bar__buttons {
        flex: none;
        margin-left: 0;
        margin-top: 0;
    }
}
.cookie-bar__accept {
    margin-left: 0;
}

@media screen and (max-width: 767px) {
    .cookie-bar__accept {
        margin-left: 0;
        font-size: 12px !important;
        padding: 8px 10px !important;
    }
}

/* modal */
.modal-dialog.cookie-modal__dialog {/*multiple classes needed for specificity*/
    width: auto;
    margin: 0.5rem;
    height: unset;
}
@media screen and (min-width: 576px) {
    .modal-dialog.cookie-modal__dialog {
        max-width: calc(500rem/16);
        margin: calc(30rem/ 16) auto;
    }
}

.cookie-modal__item {
    margin-bottom: .75rem;
}
.cookie-modal__item--all {
    margin-bottom: 1rem;
}
.cookie-modal__label {
    font-size: 1.5rem;
    /*font-weight: bold;*/
}
.cookie-modal__link {
    display: inline-block;
    margin-left: .5rem;
    text-decoration: underline;
}
.cookie-modal__group {
    margin-bottom: .75rem;
}
.cookie-modal__group-body {
    overflow: hidden;
    padding-left: .75rem;
    padding-top: .75rem;
}
.cookie-modal__group-toggle-icon {
    transform: rotate(180deg);
    transition: transform 120ms ease-out;
    font-size: .45rem;
}
.cookie-modal__group-toggle.collapsed .cookie-modal__group-toggle-icon {
    transform: rotate(0);
}

.cookie-modal .modal-content {
    padding: calc(25rem/16);
}

.modal-content .modal-body.cookie-modal__body {/*multiple classes needed for specificity*/
    overflow-y: unset;
}

.cookie-modal .close {
    font-size: calc(60rem /16);
    margin-top: calc(-32rem/16);
    margin-right: calc(-18rem/16);
}
@media screen and (max-width: 767px) {
    .cookie-modal .close {
        font-size: calc(60rem /16);
        margin-top: calc(-32rem/16);
        margin-right: calc(-18rem/16);
    }
}

/* switch */
.cookie-modal__switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    vertical-align: middle;
    margin-right: .5rem;
}
.cookie-modal__switch-toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}
.cookie-modal__switch-toggle:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 34px;
}
input:checked + .cookie-modal__switch-toggle {
    background-color: var(--color-primary);
}
input:focus + .cookie-modal__switch-toggle {
    box-shadow: 0 0 2px var(--color-primary);
}
input:checked + .cookie-modal__switch-toggle:before {
    transform: translateX(26px);
}

input[disabled] + .cookie-modal__switch-toggle {
    background-color: #ccc;
    cursor: default;
}

.cookie-modal__switch-toggle--is-disabled {
    opacity: 0.7;
}

[hidden]{
    display: none!important;
}

.cookie-bar__buttons-wrapper {
    display: flex;
}

@media screen and (max-width: 767px) {
    .cookie-bar__buttons-wrapper {
        column-gap: 4px;
    }
}