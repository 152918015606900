.primary-nav-btn {
    border: 1px solid #c0bdb7;
    border-left: none;
    border-right: none;
    margin-right: 38px;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 16px;
}
.primary-nav-btn__btn {
    background-image: linear-gradient(180deg, #aa1500 0%, #8c0f00 100%);
}
.primary-nav-btn__btn {
    display: inline-block !important;
    float: none;
    margin: 0;
    margin-right: 10px;
    border-radius: 0;
    background: #aa1500;
    background-image: linear-gradient(180deg, #aa1500 0%, #8c0f00 100%);
    color: #ffffff;
    text-align: center;
    height: 52px;
    padding: 18px 21px 19px 15px;
    border: none;
}
.primary-nav-btn__btn:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 26px 7px 26px 0;
    border-color: transparent #ffffff transparent transparent;

}
.primary-nav-btn__btn .icon-bar {
    height: 0;
    border-top: 3px solid;
    width: 19px;
    margin: auto;
    border-radius: 0;
}
.primary-nav-btn__btn .icon-bar + .icon-bar {
    margin-top: 3px;
}