.consent{
    background: var(--color-muted);
}
.consent__overlay{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: calc(15rem/16);
    color: #fff;
}
