.login-box {
    margin-top: 10px;
    overflow: hidden;
    padding: 20px 25px;
    background-color: #ECE9E3;
    color: #6F6F6F;
    font-size: 14px;
}
@media screen and (min-width: 768px) {
    .login-box {
        margin-top: 0;
    }
}
@media screen and (min-width: 1200px) {
    .login-box {
        padding: 34px 40px 40px 61px;
    }
}
.login-box__title {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 20px;
    color: #000;
    font-family: "FS Emeric Medium", sans-serif;
}
.login-box__link,
.login-box__link:hover,
.login-box__link:focus {
    text-decoration: underline;
    color: #990000;
}

.advantages-box {
    border: 1px dotted #585858;
    padding: 15px 20px;
    font-size: 16px;
    font-family: "FS Emeric Medium", sans-serif;
}
@media screen and (min-width: 768px) {
    .advantages-box {
        padding: 36px 41px;
        font-size: 20px;
    }
}
.advantages-box__list,
.advantages-box__list > ul {
list-style-type: none;
    padding: 0;
    margin-bottom: 0;
}
.advantages-box__list > li,
.advantages-box__list > ul > li {
    text-indent: -1.1em;
    padding-left: 2.2em;
}
.advantages-box__list > li:before,
.advantages-box__list > ul > li:before {
    content: "\E04E";
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
    color: #4C880B;
    font-size: 15px;
    margin-right: 10px;
    vertical-align: -.15em;
}
@media screen and (min-width: 768px) {
    .advantages-box__list > li:before,
    .advantages-box__list > ul > li:before {
        font-size: 22px;
    }
}
@media screen and (min-width: 1281px) {
    .login-slide,
    .slide.login-slide {
        padding-top: 160px;
    }
}

