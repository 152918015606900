.acco-teaser {
    font-size: 14px;
    line-height: calc(18 / 14);
    background: #ffffff;
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
}
@media screen and (max-width: 767px) {
    .acco-teaser {
        padding: 15px 12px 12px;
        border-bottom: 1px solid #c0bdb7;
    }
}
@media screen and (min-width: 768px) {
    .acco-teaser {
        margin-bottom: 24px;
    }
}
.acco-teaser__img-container {
    position: relative;
    flex: 0 0 auto;
    color: #CCCCCC;
}
.acco-teaser__body {
    flex: 1 1 auto;
    padding: 0 0 0 11px;
}
@media screen and (min-width: 768px) {
    .acco-teaser__body {
        padding: 10px 20px 15px;
    }
    .acco-teaser--small .acco-teaser__body {
        padding-left: 15px;
        padding-right: 15px;
    }
}
.acco-teaser__footer {
    flex: 0 0 auto;
    padding: 0 0 0 11px;
}
@media screen and (min-width: 768px) {
    .acco-teaser__footer {
        padding: 0 15px 10px;
    }
}
.acco-teaser__embed-responsive {
    padding-bottom: calc(224 / 324 * 100%);
}
.acco-teaser__title {
    margin-top: 0;
    margin-bottom: 4px;
    color: #8c0f00;
    font-family: var(--font-default-bold);
    font-size: 14px;
    line-height: calc(20 / 16);
    text-decoration: underline;
}
@media screen and (min-width: 992px) {
    .acco-teaser__title {
        font-size: 16px;
        text-decoration: none;
        text-transform: uppercase;
    }
}
.acco-teaser__text {
    color: #4a4a4a;
}
.acco-teaser__price {
    font-size: 24px;
    line-height: calc(22/ 24);
    font-family: var(--font-default-bold);
    white-space: nowrap;
}

.acco-teaser__price__decimal{
    display: inline-block;
    font-size: 14px;
    vertical-align: super;
    margin-left: -5px;
}
.acco-teaser__currency {
    font-size: 14px;
}
.acco-teaser__badge {
    position: absolute;
    top: 0;
    left: 10px;
    z-index: 1;
}
@media screen and (min-width: 768px) {
    .acco-teaser__badge {
        left: 20px;
    }
}

/* modifiers */
.acco-teaser--with-bg {
    background-color: #f6f4f0;
}


/* hover */
@media screen and (min-width: 768px) {
    .acco-teaser:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
        opacity: 0;
        transition: opacity 120ms ease-out;
    }
    .acco-teaser:hover:after {
        opacity: 1;
    }
    .acco-teaser:hover .acco-teaser__title {
        text-decoration: underline;
    }
    .acco-teaser__img-container:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to right, rgba(0,0,0,.4) 0%, transparent 40%, transparent 60%, rgba(0,0,0,.4) 100%);
        opacity: 0;
        transition: opacity 120ms ease-out;
        pointer-events: none;
    }
    .acco-teaser:hover .acco-teaser__img-container:after {
        opacity: 1;
    }
    .acco-teaser--small .acco-teaser__img-container:after {
        display: none;
    }

    .acco-teaser .slick-next,
    .acco-teaser .slick-prev {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 120ms ease-out;
        z-index: 2;
    }
    .acco-teaser .slick-next {
        left: auto;
        right: 0;
    }
    .acco-teaser:hover .slick-next,
    .acco-teaser:hover .slick-prev {
        opacity: 1;
    }
}
