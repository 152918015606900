/*Datepicker*/
/*.ui-datepicker {*/
    /*background: #ffffff;*/
    /*box-shadow: 0 2px 3px rgba(0, 0, 0, 0.27);*/
/*}*/
.ui-datepicker-inline {
    width: auto;
    max-width: 100%;
    margin: auto;
}
.ui-datepicker-inline table {
    font-size: 1em;
}
.ui-datepicker table {
    table-layout: fixed;
}

.ui-datepicker .ui-datepicker-header {
    border-bottom: 1px solid #c0bdb7;
}
.ui-datepicker .ui-datepicker-title {
    font-size: 15px;
}
@media screen and (min-width: 768px) {
    .ui-datepicker .ui-datepicker-title {
        text-transform: uppercase;
        font-family: var(--font-default-bold);
    }
}
.ui-datepicker thead {
    border-bottom: 1px dashed #c0bdb7;
}
.ui-datepicker th {
    font-weight: normal;
    font-family: var(--font-default-medium);
}
.ui-datepicker td {
    padding: 0;
}
.ui-datepicker td {
    border-left: 1px solid #c0bdb7;
    border-bottom: 1px solid #c0bdb7;
}
.ui-datepicker td:first-child {
    border-left: none;
}
.ui-datepicker tr:last-child td {
    border-bottom: none;
}
.ui-datepicker a.ui-state-active {
    background-color: #a81400;
    color: #ffffff;
}
.ui-datepicker td a,
.ui-datepicker td span {
    position: relative;
    text-align: center;
    background: none;
    padding: 4px;
    color: #1e1d1b;
    font-size: 15px;
}
.ui-datepicker td.ui-state-disabled {
    opacity: .6;
}
.ui-datepicker .is-arrival > a {
    color: #1e1d1b;
    background-color: transparent;
    background: linear-gradient(to left top, #eae8e4 50%, transparent 50%)
}

.ui-datepicker .is-in-range.ui-state-disabled,
.ui-datepicker .is-departure.ui-state-disabled {
    opacity: 1;
}
.ui-datepicker .is-in-range > a,
.ui-datepicker .is-in-range.ui-state-disabled > span {
    background-color: #eae8e4;
}
.ui-datepicker .is-departure > a,
.ui-datepicker .is-departure > span,
.ui-datepicker .is-departure.ui-state-disabled > span {
    color: #1e1d1b;
    background-color: transparent;
    background: linear-gradient(to right bottom, #eae8e4 50%, transparent 50%)
}
.ui-datepicker .is-departure {
    position: relative;
}
.ui-datepicker .is-departure:after {
    content: attr(title);
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    min-width: 24px;
    height: 24px;
    text-align: center;
    background: #000;
    color: #ffffff;
    font-size: 10px;
    font-family: var(--font-default-bold);
    border-radius: 50%;
    padding: 3px;
}

/*.ui-datepicker .ui-state-highlight {*/
    /*background: rgba(140,15,0,0.5);*/
    /*color: #ffffff;*/
/*}*/

/*.ui-datepicker .ui-state-arrival-date a,*/
/*.ui-datepicker .ui-state-arrival-date span,*/
/*.ui-datepicker .ui-state-hover,*/
/*.ui-datepicker .ui-state-active{*/
    /*background: #8c0f00;*/
    /*color: #ffffff;*/
/*}*/


.ui-datepicker-calendar .ui-state-disabled {
    opacity: .6;
}

.ui-datepicker-prev.ui-state-hover,
.ui-datepicker-next.ui-state-hover{
    background: none;
    color: #000000;
}
.ui-datepicker-prev.ui-state-disabled,
.ui-datepicker-next.ui-state-disabled{
    visibility: hidden;
}

.ui-datepicker-prev span,
.ui-datepicker-next span{
    display: none!important;
}


.ui-datepicker-next:after{
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
    content: "\E006";
}

.ui-datepicker-prev:after{
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
    content: "\E006";
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}


.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: auto;
    height: auto;
    line-height: 0;
}

.ui-datepicker-calendar .ui-state-disabled{
    opacity: 1;
}

.ui-datepicker-calendar .ui-state-disabled span{
    background: none;
}

.ui-datepicker.ui-datepicker-multi {
    width: auto!important;
    padding: 0 10px;
}

.date-range-selected > .ui-state-active,
.date-range-selected > .ui-state-default {
   background: none;
   background-color: #d4a29b;
}


@media screen and (max-width: 767px) {
    .ui-datepicker-multi-2 .ui-datepicker-group {
        width: auto;
        float: none;
    }
    .ui-datepicker-multi .ui-datepicker-group table {
        width: 100%;
        margin: 0;
    }
    .ui-datepicker-group + .ui-datepicker-group {
        margin-top: 15px;
    }
}
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
    border-left: 1px solid #c0bdb7;
    background-color: transparent;
}
.ui-datepicker select.ui-datepicker-month + select.ui-datepicker-year {
    border-left: none;
}

.ui-datepicker:not(.ui-datepicker-inline) {
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0 0 2px;
    z-index: 104001 !important; /* higher than z-index of modal */
}