/*detail*/
.header-bar__detail {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding-bottom: 20px;
    background: #fff;
    box-shadow: 0 0 30px rgba(0,0,0,.1);
    transform: translateY(-100%);
    will-change: transform;
    transition: transform 230ms ease, visibility .1ms 230ms;
}
.header-bar.is-open .header-bar__detail {
    visibility: visible;
    transform: translateY(0);
    transition: transform 230ms ease, visibility .1ms;
}
.header-bar__detail-close {
    position: absolute;
    right: 30px;
    padding: 10px 10px 0 10px;
    z-index: 101;
    font-size: 28px;
    will-change: transform;
    transform: translateZ(0);
}