.star-rating {
    color: #c1aa50;
    font-size: 8px;
    vertical-align: super;
    white-space: nowrap;
    display: inline-block;
}
.star-rating__icon {
    font-size: 12px;
}
.star-rating__text {
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    vertical-align: .5em;
}