:root {
    --compare-table-horizontal-padding: 15px;
}
.compare-table {
    width: calc(100% + 2 * var(--compare-table-horizontal-padding));
    max-width: calc(100% + 2 * var(--compare-table-horizontal-padding));
    margin: 0 calc(var(--compare-table-horizontal-padding) * -1);
    table-layout: fixed;
}
.compare-table th {
    font-weight: normal;
}
.compare-table td {
    text-align: center;
}
.compare-table th,
.compare-table td {
    box-sizing: border-box;
    padding: 12px 15px;
    width: 18%
}
.compare-table th:first-child,
.compare-table td:first-child {
    width: 46%;
}
.compare-table__bordered-cell {
    /*border-bottom: 1px dotted #c0bdb7;*/
}
.compare-table__bordered-cell {
    position: relative;
}
.compare-table__bordered-cell:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: var(--compare-table-horizontal-padding);
    right: var(--compare-table-horizontal-padding);
    border-bottom: 1px dotted #c0bdb7;
}
.compare-table__remove {
    font-family: var(--font-default-medium);
    color: #8c0f00;
}
.compare-table__remove .icon {
    font-size: 8px;
}
td.compare-table__no-vertical-padding-cell,
th.compare-table__no-vertical-padding-cell {
    padding-top: 0;
    padding-bottom: 0;
}