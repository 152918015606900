.alert {
    border-radius: 0;
}
.alert-danger {
    background: #a81400;
    color: #fff;
}
.alert-info {
    background-color: #fff;
    color: #1e1d1b;
    border-color: #c0bdb7;
}