.sidebar-discount {
    padding: 12px;
    background-color: #F6F4F0;
    margin-bottom: 1px;
    text-align: center;
    font-size: 16px;
    line-height: 1;
    color: #000000;
}
@media screen and (min-width: 1200px) {
    .sidebar-discount {
        font-size: 18px;
    }
}
.sidebar-discount__subtitle {
    font-size: 15px;
    margin-top: 5px;
}
.sidebar-discount__icon {
    font-size: 33px;
    margin-bottom: 10px;
}