.full-height-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.full-height-page__header {
    flex: none
}
.full-height-page__page-wrapper {
    flex: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.full-height-page__content {
    flex: auto;
}
.full-height-page__footer {
    flex: none;
}