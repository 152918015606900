.stacktable tr,
.stacktable td,
.stacktable th {
    display: block;
}
.stacktable .st-val,
section.seminarraeume .konferenzpauschalen table td.st-val {
    border-top: none;
}

.stacktable .st-key,
section.seminarraeume .konferenzpauschalen table th.st-key,
section.seminarraeume .konferenzpauschalen table td.st-key {
    padding-bottom: 0;
}