.twitter-typeahead {
    /*override inline stlye*/
    display: block !important;
}
.tt-menu {
    right: 0;
    background-color: #fff;
    border: 1px solid #d8d8d8;
    box-shadow: 0 2px 6px rgba(7,9,9,.25);
    padding: 3px 0;
    max-height: 245px;
    overflow-y: auto;
    color: #6f6f6f;
}
.tt-suggestion {
    padding: 4px 20px;
}
.tt-selectable:hover,
.tt-selectable.tt-cursor {
    cursor: pointer;
    background: #e2e2e2;
    color: #333;
}