/*.breadcrumb {
    display: inline-block;
    background: inherit;
    margin-bottom: 0;
}
.breadcrumb>li>a {
    color: var(--color-primary);
    text-decoration: underline;
}
.breadcrumb>li>a:hover {
    color: var(--color-secondary);
}
.breadcrumb>.active>a {
    color: inherit;
    text-decoration: none;
}*/

.breadcrumb {
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    background: none;
    border-radius: 0;
    padding-top: 12px;
}

.breadcrumb span,
.breadcrumb a{
    border: none!important;
    font-size: 14px;
    margin-right: 4px;
    margin-left: 4px;
}

.breadcrumb a:first-child{
    margin-left: 0;
}

.breadcrumb>li+li:before {
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    padding: 0 5px;
    font-size: 7px;
    color: #8c0f00;
    content: "\E005"!important;
    position: relative;
    top: -1px;
}

/* TABLET */
@media (max-width: 1024px), screen and (max-width: 1280px), (max-height: 800px){
    .breadcrumb-wrap {
        padding-left: 158px;
    }
}