body.mice .start-here{
    display: none;
}

body.mice .page-wrapper{
    padding-top: 0;
}

.mice-container{

    background: #eae8e4;
    -webkit-box-shadow: inset 45px 0px 47px -6px rgba(210,208,205,1);
    -moz-box-shadow: inset 45px 0px 47px -6px rgba(210,208,205,1);
    box-shadow: inset 45px 0px 47px -6px rgba(210,208,205,1);
}

.mice-container .container{
    max-width: 1100px;
}

.mice-container h1{
    margin: 0 0 50px 0;
    font-family: "FS Emeric Medium", sans-serif;
    font-weight: normal;
}

.mice-container h2{
    margin: 0 0 20px 0;
    font-family: FS Emeric Light, sans-serif;
    font-weight: normal;
    padding-top: 7px;
}

.mice-container h2 strong{
    margin: 0;
    font-family: "FS Emeric Medium", sans-serif;
    font-weight: normal;
}

.mice-container .leftfilter h3{
    font-family: "FS Emeric Medium", sans-serif;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
}

.mice-container .leftfilter select{
    width: 100%;
    height: 40px;
    border: none;
    padding: 0 20px;
}

.mice-container .leftfilter label{
    font-size: 16px;
}


.mice-container .location-select{
    margin-bottom: 19px;
}

.mice-container .my-checkbox{
    position: relative;
    padding-left: 40px;
    margin-bottom: 10px;
    display: block;
    font-size: 16px;
    font-family: FS Emeric Light, sans-serif;
    cursor: pointer;
}

.mice-container .my-checkbox:before{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    display: block;
    width: 27px;
    height: 27px;
    background: #ffffff;
}

.mice-container .my-checkbox.active:after{
    font-size: 12px;
    position: absolute;
    top: 8px;
    left: 5px;
    content: "\E04E";
    display: inline-block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0,0);
    -ms-transform: translate(0,0);
    transform: translate(0,0);
}

.mice-container a.showMap,
.mice-container a.showList{
    color: #ab1500;
    font-size: 18px;
    margin-bottom: 10px;
    display: block;
}

.mice-container a.showMap i,
.mice-container a.showList i{
    margin-right: 5px;
    font-size: 17px;
}

.mice-container  section.zimmerdetails .infos h3 {
    padding-top: 10px;
    margin: 0 0 10px 0;
}

.mice-container .infos p{
    font-size: 15px;
    line-height: 20px;
    margin: 0;
}

.mice-container section.zimmerdetails .pruefen{
    cursor: pointer;
}

.mice-container section.zimmerdetails .pruefen .on{
    display: none;
}

.mice-container section.zimmerdetails .pruefen .off{
    display: block;
}

.mice-container section.zimmerdetails .pruefen.active{
    background: #1e1d1b;
}

.mice-container section.zimmerdetails .pruefen.active .on{
    display: inline-block;
}

.mice-container section.zimmerdetails .pruefen.active .off{
    display: none;
}

.mice-container section.zimmerdetails .pruefen i{
    display: none;
}

.mice-container section.zimmerdetails .pruefen.active i{
    font-size: 10px;
    margin-right: 5px;
    display: inline-block;
}

.mice-container .topInfo{
    position: fixed;
    height: 100px;
    visibility: hidden;
    z-index: 5;
}

.mice-container .topInfo.op{
    visibility: visible;
}

.mice-container .top-info-text{
    background: #ffffff;
    height: 60px;
    width: 752px;
    padding: 15px 0 0 25px;
}

.mice-container a.top-anfrage{
    display: block;
    background: #8c0f00;
    color: #ffffff;
    font-size: 18px;
    font-family: "FS Emeric Medium", sans-serif;
    text-align: center;
    height: 60px;
    width: 100%;
    padding-top: 14px;
}

.mice-container a.reset-filter{
    color: #8c0f00;
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 10px;
    display: none;
}

.mice-container a.reset-filter i{
    font-size: 11px;
    margin-right: 8px;
}

.mice-container .info-label{
    float: left;
    width: 117px;
    margin-right: 15px;
    font-size: 16px;
    font-family: FS Emeric Light, sans-serif;
}

.mice-container .my-bestuhlung{
    position: relative;
    display: block;
    width: 36px;
    height: 36px;
    background: #fff;
    float: left;
    margin-right: 15px;
    cursor: pointer;
}

.mice-container .my-bestuhlung i{
    position: absolute;
    top: 9px;
}

.mice-container .my-bestuhlung i.icon-runde-tische{
    left: 8px;
}

.mice-container .my-bestuhlung i.icon-u-tafel{
    left: 10px;
}

.mice-container .my-bestuhlung i.icon-block-tafel{
    left: 11px;
}

.mice-container .my-bestuhlung i.icon-parlament{
    left: 9px;
}

.mice-container .my-bestuhlung i.icon-theater{
    left: 9px;
}

.mice-container .my-bestuhlung i.icon-cocktail{
    left: 9px;
}




.mice-container .my-bestuhlung.active{
    background: #8c0f00;
}

.mice-container .my-bestuhlung.active i{
    color: #ffffff;
}


.mice-container .room input{
    float: left;
    height: 36px;
    width: 85px;
    margin-right: 80px;
}

.mice-container #roomSearch{
    padding-left: 60px;
    padding-bottom: 139px;
    overflow: hidden;
}

.mice-container .my-checkbox{
    float: left;
}

.mice-container .room{
    margin-bottom: 40px;
    padding: 10px;
    border: 1px solid transparent;
}

.mice-container .room.error{
    border: 1px solid red;
}

.mice-container .room small{
    display: none;
}

.mice-container .room.error small{
    display: block;
    margin-top: 5px;
    color: red;
    text-transform: initial;
}


.mice-container .room h3{
    font-family: "FS Emeric Medium", sans-serif;
    font-weight: 400;
    font-size: 18px;
    text-transform: uppercase;
}


.mice-container #roomSearch .room-minus i,
.mice-container #roomSearch .more i {
    margin: 0 10px;
    position: relative;
    top: 2px;
}

.mice-container #roomSearch .room-minus,
.mice-container #roomSearch .more {
    float: left;
    display: block;
    border-top: 2px solid #d2cec6;
    border-bottom: 2px solid #d2cec6;
    width: 100%;
    font-size: 16px;
    font-family: "FS Emeric Medium", sans-serif;
    color: #8f1000;
    padding: 3px 0;
    cursor: pointer;
}

.mice-container #roomSearch .pruefen {
    float: left;
    display: block;
    background: #8f1000;
    text-align: center;
    color: #fff;
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 16px;
    padding: 5px 0;
    width: 50%;
    cursor: pointer;
}

.mice-container #roomSearch:before {
    position: absolute;
    content: '';
    width: 11px;
    top: 0;
    height: 90%;
    left: 10px;
    bottom: 0;
    border-radius: 212px / 1378px;
    box-shadow: inset 0 0 87px 0 rgba(0,0,0,.1);
    box-shadow: 0 0 34px 0 rgba(0,0,0,.7);
}

.mice-container #roomSearch:after {
    position: absolute;
    content: '';
    width: 36px;
    top: -30px;
    height: 100%;
    left: -13px;
    bottom: 0;
    background: #eae8e4;
}

#my-mice-map{
    display: block;
    height: 760px;
    width: 100%;
    margin-bottom: 35px;
}

.infoBox>img{
    z-index: 2;
}

.mapInfo{
    position: relative;
    background: #eae8e4;
    width: 440px;
    padding: 30px;
    -moz-box-shadow: 0 0 5px #888;
    -webkit-box-shadow: 0 0 5px#888;
    box-shadow: 0 0 5px #888;
}

.mapInfo p{
    font-family: FS Emeric Light, sans-serif;
    font-size: 18px;
    line-height: 24px;
}

.mapInfo:before{
    position: absolute;
    top: -10px;
    left: 215px;
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: #eae6e4;
    z-index: -1;

    -webkit-box-shadow: -1px 1px 5px #888;
    -moz-box-shadow: -1px 1px 5px #888;
    box-shadow: -1px 1px 5px #888;

    -ms-transform: rotate(135deg); /* IE 9 */
    -webkit-transform: rotate(135deg); /* Chrome, Safari, Opera */
    transform: rotate(135deg);
}

.mapInfo:after{
    position: absolute;
    top: -10px;
    left: 215px;
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: #eae6e4;

    -ms-transform: rotate(135deg); /* IE 9 */
    -webkit-transform: rotate(135deg); /* Chrome, Safari, Opera */
    transform: rotate(135deg);
}


.mapInfo .cta .buchen i{
    font-size: 10px;
    margin-right: 5px;
    display: none;
}

.mapInfo .cta .buchen.active{
    background: #000000;
}

.mapInfo .cta .buchen.active i{
    display: inline-block;
}


.pruefen{
    margin-bottom: 15px;
}



/* MOBILE */
@media (max-width: 768px){

    .mice-container .infos p {
        font-size: 14px;
    }


    .mice-container #roomSearch {
        padding-left: 0;
    }

    .mice-container #roomSearch:before,
    .mice-container #roomSearch:after{
        display: none;
    }

    .mice-container .room input {
        margin-right: 14px;
        margin-bottom: 14px;
    }

    #my-mice-map{
        height: 400px;
    }

    .mice-container .topInfo{
        top: 0;
    }

    .mice-container .top-info-text{
        width: 345px;
    }

    .mice-container a.top-anfrage {
        width: 345px;
    }

    .mice-container .info-label{
        width: 75px;
        height: 67px;
    }

    .mice-container .my-bestuhlung{
        margin-right: 6px;
        margin-bottom: 6px;
    }

    .mice-container .my-bestuhlung i{
        top: 11px;
    }

    .mice-container #roomSearch .room-minus, .mice-container #roomSearch .more {
        font-size: 13px;
    }


}