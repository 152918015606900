.inline-row {
    margin-left: -4px;
    margin-right: -4px;
}
.inline-row__col {
    display: inline-block;
    padding-left: 4px;
    padding-right: 4px;
}
@media screen and (min-width: 768px) {
    .inline-row {
        margin-left: -8px;
        margin-right: -8px;
    }
    .inline-row__col {
        padding-left: 8px;
        padding-right: 8px;
    }
    .inline-row--gutter-30 {
        margin-left: 15px;
        margin-right: -15px;
    }
    .inline-row--gutter-30 .inline-row__col {
        padding-left: 15px;
        padding-right: 15px;
    }
}