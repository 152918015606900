.booking-info {
    padding-top: 15px;
    padding-bottom: 20px;
    font-size: 13px;
    line-height: calc(18 / 13);
}
@media screen and (min-width: 768px) {
    .booking-info {
        padding-top: 50px;
        padding-bottom: 70px;
        font-size: 16px;
    }
}

.booking-info__title {
    margin-top: 0;
    margin-bottom: 7px;
    font-size: 20px;
}
@media screen and (min-width: 768px) {
    .booking-info__title {
        font-size: 32px;
        margin-bottom: 18px;
    }
}
.booking-info__block + .booking-info__block {
    margin-top: 15px;
    padding-top: 14px;
    border-top: 1px dotted #c0bdb7;
}
@media screen and (min-width: 768px) {
    .booking-info__block + .booking-info__block {
        margin-top: 45px;
        padding: 0;
        border-top: none;
    }
}

.booking-info h3 {
    margin-top: 0;
    margin-bottom: 2px;
    font-size: 14px;
    font-family: var(--font-default-bold);
    text-transform: uppercase;
}
@media screen and (min-width: 768px) {
    .booking-info h3 {
        font-size: 16px;
        margin-bottom: 7px;
    }
}
.booking-info h4 {
    margin-top: 10px;
    margin-bottom: 2px;
    font-size: 14px;
    font-family: var(--font-default-bold);
}
@media screen and (min-width: 768px) {
    .booking-info h4 {
        margin-top: 5px;
        margin-bottom: 4px;
        font-size: 15px;
    }
}
.booking-info p {
    margin-bottom: 0;
}