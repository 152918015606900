.nav-tabs {
    border: none;
}
.nav-tabs > li + li {
    margin-left: 5px;
}
.nav-tabs > li {
    margin-bottom: 0;
}
.nav-tabs > li > a {
    background-color: #dfdcd6;
    border-radius: 0;
    padding: 9px 12px 9px 12px;
    margin-top: 5px;
    font-size: 14px;
    color: inherit;
    border: none;
    margin-right: 0;
    line-height: 1.1;
}
@media screen and (min-width: 768px) {
    .nav-tabs > li > a {
        padding-left: 30px;
        font-size: 16px;
        line-height: inherit;
    }
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
    background: #ffffff;
    color: inherit;
    border: none;
}

@media screen and (min-width: 768px) {
    .nav-tabs > li > a {
        min-width: 280px;
    }
}
.nav-tabs > li.active > a, 
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    background: #ffffff;
    color: inherit;
    margin-top: 0;
    border: none;
    padding: 12px 12px 13px 12px;
}
@media screen and (min-width: 768px) {
    .nav-tabs > li.active > a,
    .nav-tabs > li.active > a:hover,
    .nav-tabs > li.active > a:focus {
        padding-left: 30px;
    }
}

.nav-tabs-justified {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: stretch;
}
.nav-tabs-justified > li {
    float: none;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}
.nav-tabs-justified > li > a {
    flex: auto;
}

@media screen and (max-width: 767px) {
    .nav-tabs__badge {
        margin-top: -3px;
    }
}