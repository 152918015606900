.sidebar-teaser__img {
    position: relative;
}
.sidebar-teaser__img:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(ellipse at left bottom, rgba(236,234,230,1) 0%
    ,rgba(236,234,230,.8) 20%
    ,rgba(236,234,230,0) 50%); /* W3C */
}
.sidebar-teaser__content {
    padding: 0 30px;
    margin-top: -65px;
}