.multi-select {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    max-height: 350px;
}
.multi-select ul {
    list-style-type: none;
    padding-left: 0;
}
.multi-select>ul {
    max-width: 440px;
    margin: auto;
}

/*.multi-select li {*/
    /*padding: 10px 0 10px 10px;*/
/*}*/
.multi-select>ul>li {
    border-bottom: 1px solid #ffffff;
    padding-bottom: 0;
}
.multi-select-item {
    display: block;
    padding: 10px 0 10px 20px;
    cursor: pointer;
}
.multi-select-item .multi-select {
    padding-left: 30px;
}
.multi-select-item:hover,
.multi-select-item.is-selected {
    background: rgba(0, 0, 0, .1);
}

.left-city-choice{
    width: 100%;
    height: 350px;
    background: #f1f0ee;
}

.right-hotel-choice{
    width: 100%;
    height: 350px;
    background: #e9e8e4;
    -webkit-box-shadow: inset 9px 0px 47px -6px rgba(221,220,216,1);
    -moz-box-shadow: inset 9px 0px 47px -6px rgba(221,220,216,1);
    box-shadow: inset 9px 0px 47px -6px rgba(221,220,216,1);
    overflow-y: scroll;
}

.right-select-choice{
    width: 100%;
    height: 381px;
    background: #ffffff;
    padding-top: 110px;
}

.right-hotel-choice ul,
.left-city-choice ul{
    display: block;
    padding: 15px 0 0 0;
    margin: 0;
    list-style: none;
}

.right-hotel-choice ul li,
.left-city-choice ul li{
    position: relative;
    display: block;
    padding: 5px 0 5px 30px;
    cursor: pointer;
}

.right-hotel-choice ul li{
    padding: 5px 0 5px 60px;
}

.left-city-choice ul li:hover{
    font-family: "FS Emeric Bold", sans-serif;
}

.right-hotel-choice ul li.active,
.left-city-choice ul li.active{
    font-family: "FS Emeric Bold", sans-serif;
    cursor: auto;
}

.left-city-choice ul li.active:after {
    position: absolute;
    right: -15px;
    top: 5px;
    z-index: 5;
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 0 15px 15px;
    border-color: transparent transparent transparent #f1f0ee;
}

.addInfos select,
.addInfos input{
    background: #eae8e4;
}

 i.my-point,
 i.my-cal{
    position: absolute;
    top: 22px;
    left: 13px;
}


#page-header__date,
#page-header__date_bar{
  line-height: 21px;
}

#page-header__hotel,
#page-header__date{
    padding-left: 45px;
}


#page-header__date_bar,
#page-header__hotel_bar,
.header-bar__btn,
#page-header__date,
#page-header__hotel{
    font-family: FS Emeric Light, sans-serif;
}

.bookNow{
    font-family: "FS Emeric Medium", sans-serif;
}

.top-slogan{
    font-family: FS Emeric Light, sans-serif;
    font-size: 29px;
    position: relative;
    top: 3px;
    left: 20px;
    display: none;
}
@media (min-width: 1500px){
    body.hotelseite .top-slogan{
        display: block;
    }
}

/* TABLET */
@media (max-width: 1024px), screen and (max-width: 1280px), (max-height: 800px){
    .header-bar--has-flag .header-bar__content,
    .header-bar--has-flag .header-bar__detail {
        padding-left: 133px;
    }

    .right-hotel-choice ul li {
        padding: 5px 0 5px 40px;
    }
}