@import '/web/static/css/libs/select2.min.css';
@import '/web/static/css/style/font-face.css';
@import '/web/static/css/style/variables.css';
@import '/web/static/css/style/base.css';

@import '/web/static/css/style/Arrange.css';

@import '/web/static/css/style/base-layout.css';
@import '/web/static/css/style/page-sidebar.css';
@import '/web/static/css/style/btn.css';
@import '/web/static/css/style/icons.css';
@import '/web/static/css/style/jump-nav.css';
@import '/web/static/css/style/hotelnav.css';
@import '/web/static/css/style/main-nav.css';
@import '/web/static/css/style/main-nav-mobile.css';
@import '/web/static/css/style/nav-list.css';
@import '/web/static/css/style/sidebar-teaser.css';
@import '/web/static/css/style/main-nav__sub.css';
@import '/web/static/css/style/hotel-list.css';
@import '/web/static/css/style/hotel-overview.css';
@import '/web/static/css/style/form.css';
@import '/web/static/css/style/alert.css';
@import '/web/static/css/style/breadcrumb.css';
@import '/web/static/css/style/wysiwyg.css';
@import '/web/static/css/style/table.css';
@import '/web/static/css/style/header-bar.css';
@import '/web/static/css/style/header-bar__detail.css';
@import '/web/static/css/style/multi-select.css';
@import '/web/static/css/style/datepicker.css';
@import '/web/static/css/style/inline-datepicker.css';
@import '/web/static/css/style/label.css';
@import '/web/static/css/style/layout.css';
@import '/web/static/css/style/typo.css';

@import '/web/static/css/style/slick.css';
@import '/web/static/css/style/magnific-popup.css';
@import '/web/static/css/style/mice.css';
@import '/web/static/css/style/content.css';
@import '/web/static/css/style/booking-bar.css';
@import '/web/static/css/style/top-slider.css';
@import '/web/static/css/style/stacktable.css';
@import '/web/static/css/style/bewertungen.css';
@import '/web/static/css/style/loading-spinner.css';
@import '/web/static/css/style/circle-spinner.css';


@import '/web/static/css/style/row--same-height.css';
@import '/web/static/css/style/inline-row.css';
@import '/web/static/css/style/flex-row.css';

@import '/web/static/css/style/nav-tabs.css';
@import '/web/static/css/style/custom-checkbox.css';
@import '/web/static/css/style/custom-radio.css';
@import '/web/static/css/style/radio-list.css';
@import '/web/static/css/style/number-spinner.css';
@import '/web/static/css/style/number-slider.css';
@import '/web/static/css/style/rangeSlider.css';
@import '/web/static/css/style/acco-teaser.css';
@import '/web/static/css/style/trust-you.css';
@import '/web/static/css/style/flag-badge.css';
@import '/web/static/css/style/badge.css';
@import '/web/static/css/style/room-panel.css';
@import '/web/static/css/style/simple-panel.css';
@import '/web/static/css/style/booking-info.css';
@import '/web/static/css/style/primary-nav-btn.css';
@import '/web/static/css/style/cart.css';
@import '/web/static/css/style/cart-box.css';
@import '/web/static/css/style/map.css';
@import '/web/static/css/style/slick-slider.css';
@import '/web/static/css/style/booking-selection.css';
@import '/web/static/css/style/modal.css';
@import '/web/static/css/style/star-rating.css';
@import '/web/static/css/style/twitter-typeahead.css';
@import '/web/static/css/style/compare-table.css';
@import '/web/static/css/style/hero.css';
@import '/web/static/css/style/collapse-link.css';
@import '/web/static/css/style/box.css';
@import '/web/static/css/style/figure.css';
@import '/web/static/css/style/help-slide.css';
@import '/web/static/css/style/quick-filter.css';
@import '/web/static/css/style/expandable-block.css';
@import '/web/static/css/style/panomax.css';
@import '/web/static/css/style/full-height-page.css';
@import '/web/static/css/style/discount-overlay.css';
@import '/web/static/css/style/sidebar-discount.css';
@import '/web/static/css/style/discount-bar.css';
@import '/web/static/css/style/liverate-chat.css';
@import '/web/static/css/style/cookie.css';
@import '/web/static/css/style/loading-progress.css';
@import '/web/static/css/style/footer.css';
@import '/web/static/css/style/stretch-link.css';

@import '/web/static/css/style/oocss.css';
@import '/web/static/css/style/login.css';
@import '/web/static/css/style/cookie-consent-overlay.css';

/* fix bootstrap css flash on load */
html {
    display: block;
}