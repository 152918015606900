a {
    color: inherit;
}
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}
address {
    margin-bottom: 0;
}
textarea {
    resize: vertical;
}
label {
    font-weight: inherit;
}
mark {
    background: transparent;
    color: inherit;
}

legend {
    border-bottom: none;
    margin: 0;
}

hr {
    border-color: #b0b7bf;
}

ul ul,
ol ul {
    list-style: disc;
}

.hr--double:after {
    content: '';
    display: block;
    border: inherit;
    margin-top: 1px;
}
.hr--dotted {
    border-style: dotted;
}
.hr--light {
    border-color: #c0bdb7;
}
.hr--medium-grey {
    border-color: #4a4a4a;
}
.hr--broad {
    border-top-width: 2px;
}