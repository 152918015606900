.box { padding: 18px; }
@media screen and (min-width: 768px) {
    .box { padding: 25px; }
    .box--20 { padding: 20px; }
}
.box__full-width {
    margin-left: -18px;
    margin-right: -18px;
}
@media screen and (min-width: 768px) {
    .box__full-width {
        margin-left: -25px;
        margin-right: -25px;
    }
    .box--20 .box__full-width {
        margin-left: -20px;
        margin-right: -20px;
    }
}