
body.portal .booking-bar-portal{
    position: relative;
    opacity: 1;
    width: 100%;
    height: 154px;
    background: #8c0f00 url(/static/img/pattern_red.png);
    background-size: 40px 38px;

    transition: opacity 1s;
}

body.portal .booking-bar-portal.hideMe{
    opacity: 0;
}


#page-header__hotel_bar,
#page-header__date_bar{
    padding-left: 45px;
}


.header-bar__btn,
.header-bar__btn:focus,
.header-bar__btn:visited,
.header-bar__btn:hover{
    background: #000000!important;
}


form.bar-form{
    margin-top: 50px;
    z-index: 5;
    position: relative;
}


@media (max-width: 768px) {

    body.portal section.booking-bar-portal {
        display: block;
        height: auto;
        padding: 1em;
        transition: initial;
        opacity: initial;
        min-height: auto;
        position: initial;
    }
    body.portal section.booking-bar-portal form.bar-form {
        margin-top: 0;
    }
    body.portal section.booking-bar-portal form.bar-form .col { margin-bottom: 0.75em; }
    body.portal section.booking-bar-portal form.bar-form .col:last-child { margin-bottom: 0; }
}





/*new booking bar*/
.booking-bar {
    padding: 40px 0;
    background-color: #dfdcd6;
}
.booking-bar.affix {
    position: fixed;
    top: 0;
    left: 366px;
    right: 60px;
    z-index: 100;
    padding: 8px;
}
.booking-bar.affix .container {
    padding: 0;
    width: auto;
}
@media screen and (min-width: 1200px) {
    .booking-bar.affix {
        left: 416px;
    }
}
@media screen and (min-width: 1281px) {
    .booking-bar.affix {
        left: 407px;
    }
    .portal .booking-bar.affix {
        left: 520px;
    }
}
.booking-bar.affix .booking-bar__label {
    display: none;
}
.booking-bar--visible-affix {
    position: absolute;
    height: 0;
}
.booking-bar--visible-affix .booking-bar {
    opacity: 0;
}
.booking-bar--visible-affix .affix.booking-bar {
    opacity: 1;
}