.circle-spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border-top: 2px solid var(--color-primary);
    border-right: 2px solid transparent;
    animation: circle-spinner .6s linear infinite;
}
@keyframes circle-spinner {
    to {transform: rotate(360deg);}
}