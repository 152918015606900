.flag-badge {
    background: #8c0f00;
    color: #ffffff;
    text-transform: uppercase;
    padding: 5px 6px;
    /*todo flag bg*/
}
@media screen and (min-width: 768px) {
    .flag-badge {
        padding: 7px 10px;
    }
}
.flag-badge__title {
    font-family: var(--font-default-medium);
    font-size: 13px;
    padding-bottom: 3px;
    border-bottom: 1px solid rgba(255,255,255,.7);
}
.flag-badge__value {
    font-family: var(--font-default-bold);
    font-size: 13px;
    text-align: center;
}
@media screen and (min-width: 768px) {
    .flag-badge__value {
        font-size: 27px;
    }
}