.figure {
    position: relative;
}
.figure__fullscreen {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    background: none;
    color: #ffffff;
    font-size: 26px;
    line-height: 0;
    transition: transform 120ms ease-out;
    transform-origin: center center;
}
.figure__fullscreen:hover {
    transform: scale(1.1);
}
