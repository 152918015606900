.loading-progress {
    position: relative;
    padding-top: 6px;
    max-width: 450px;
    margin: auto;
}
.loading-progress__bar {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;

    display: block;
    height: 6px;
    width: 100%;
    color: var(--color-primary);
}
.loading-progress__bar[value]::-webkit-progress-bar {
    background: #AAAAAA;
}
.loading-progress__bar[value]::-webkit-progress-value {
    background: var(--color-primary);
}
.loading-progress__steps {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 0;
    list-style-type: none;
    counter-reset: loading-progress;
    text-align: left;
}
.loading-progress__step {
    display: block;
    position: relative;
}

.loading-progress__step:after {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    background: #AAAAAA;
    /*position: absolute;*/
    /*top: 0;*/
    /*left: 50%;*/
    /*transform: translateX(-50%);*/
}
.loading-progress__step:before {
    content: '';
    background: var(--color-primary);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transform: scale(1);
    transition: opacity 120ms ease, transform 200ms 40ms cubic-bezier(0.25, 0.1, 0, 7);
}
.loading-progress__step.is-active:before {
    opacity: 1;
    transform: scale(1.01);
}
.loading-progress__step-text {
    position: absolute;
    bottom: -40px;


    display: flex;
    flex-direction: row;
    align-items: flex-end;
    line-height: 1.15;

    font-family: var(--font-default-medium);

    left: -35px;
    right: -35px;
    font-size: .75rem;
}
@media screen and (max-width: 767px) {
    .loading-progress__step-text {
        font-size: .65rem;
        left: -18px;
        right: -18px;
    }
    .loading-progress__step:first-child .loading-progress__step-text {
        left: 0;
        right: -35px;
    }
    .loading-progress__step:last-child .loading-progress__step-text {
        left: -35px;
        right: 0;
    }
}
.loading-progress__step-text:before {
    counter-increment: loading-progress;
    content: counter(loading-progress);
    font-size: 20px;
    font-family: var(--font-default-bold);
    margin-right: 6px;
}

.loading-progress-img {
    width: 90px;
}
@media screen and (min-width: 768px) {
    .loading-progress-img {
        width: 160px;
    }
}