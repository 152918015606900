.hero {
    position: relative;
    width: 100%;
}
.embed-responsive.hero__embed-responsive {
    padding-bottom: calc(350 / 320 * 100%);
}
@media screen and (min-width: 768px) {
    .embed-responsive.hero__embed-responsive {
        padding-bottom: calc(640 / 1680 * 100%);
    }
}
.hero__content {
    position: absolute;
    bottom: 12px;
    left: 0;
    right: 0;
    z-index: 1;
    text-align: center;
    color: #ffffff;
}
@media screen and (min-width: 1200px) {
    .hero__content {
        bottom: 64px;
    }
}
@media screen and (max-width: 767px) {
    .hero__input-wrapper {
        padding: 10px 12px;
        background-color: rgba(30, 29, 27, 0.7);
        content-visibility:auto;
    }

    .hero__input-wrapper.is-fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    .hero__input-wrapper.is-fixed .hero__input-item .hero__input {
        display: none;
    }

    .hero__input-wrapper.is-fixed .hero__input-item+.hero__input-item {
        margin-top: 0;
    }
}

.hero__title {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    color: #ffffff;
    font-size: 28px;
    font-family: var(--font-default-light);
    max-width: 270px;
    margin: auto;
    pointer-events: none;
    line-height: 1.1;
}
@media screen and (min-width: 768px) {
    .hero__title {
        max-width: none;
        font-size: 40px;
        padding: 0 20px;
    }
}
@media screen and (min-width: 1500px) {
    .hero__title {
        font-size: 60px;
        max-width: 630px;
    }
}
.hero__sub-title {
    font-size: 16px;
    font-family: var(--font-default-bold);
    margin-top: 15px;
}
@media screen and (min-width: 768px) {
    .hero__sub-title {
        margin-top: 20px;
    }
}

.hero__link {
    color: #aa1500;
    font-family: var(--font-default-medium);
    text-decoration: underline;
}
.hero__link:hover {
    color: #ffffff;
    text-decoration: underline;
}
@media screen and (max-width: 767px) {
    .hero__input-item + .hero__input-item {
        margin-top: 10px;
    }
}
.hero__input {
    font-size: 16px;
    padding-left: 11px;
    padding-right: 11px;
    border: none;
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.5);
}
@media screen and (min-width: 768px) {
    .hero__input {
        height: 40px;
        font-size: 16px;
        padding: 9px 15px;
        box-shadow: none;
        background: none; /*input wrapper as box shadow and background*/
    }
    .hero__input-wrapper {
        background: #ffffff;
        box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.5);
    }
    .hero__btn {
        height: 40px;
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 16px;
    }
    .hero__input-item + .hero__input-item .hero__input {
        border-left: 1px solid #d1cec8;
    }
}
@media screen and (min-width: 1200px) {
    .hero__input {
        height: 52px;
        font-size: 20px;
        padding: 12px 20px;
    }

    .hero__btn {
        height: 52px;
        padding-top: 13px;
        padding-bottom: 13px;
        font-size: 18px;
    }
    .booking-bar.affix .hero__input {
        height: 44px;
        padding: 8px 15px;
    }
    .booking-bar.affix .hero__btn {
        height: 44px;
        font-size: 16px;
        padding-top: 11px;
        padding-bottom: 11px;
    }
}
@media screen and (min-width: 1400px) {
    .booking-bar.affix .hero__btn {
        font-size: 18px;
        padding-top: 9px;
        padding-bottom: 9px;
    }
}
.hero__input-icon {
    font-size: 18px;
    color: #8d0600;
    vertical-align: -.18em;
    margin-right: 3px;
}
@media screen and (min-width: 768px) {
    .hero__input-icon {
        vertical-align: -0.08em;
    }
}
.hero .slick-dots {
    display: none;
}

/* variants */
@media screen and (min-width: 768px) {
    .hero--centered-content  .hero__content {
        bottom: auto;
        top: 50%;
        transform: translateY(-50%);
    }
}
