.jump-nav{
    position: relative;
    width: 100%;
}
@media screen and (min-width: 768px) {
    .jump-nav {
        height: 70px;
    }
}
.hero + .jump-nav,
.hero + .booking-bar--visible-affix + .jump-nav {
    margin-top: 40px;
}

.jump-nav.shadowslide{
    margin-bottom: 40px;
}

.jump-nav .container{
    position: relative;
    z-index: 2;
    text-align: center;
}

.jump-nav ul{
    margin: 0;
    padding: 0;
    list-style: none;
}

.jump-nav ul li{
    display: inline;
    margin: 0 40px 0 0;
    padding: 0;
}

.jump-nav ul li:last-child{
    margin: 0;
}

.jump-nav ul li a{
    color: #ab1500;
}

.jump-nav ul li a:hover,
.jump-nav ul li a:focus,
.jump-nav ul li a.active{
    color: #000000;
    padding-bottom: 5px;
    border-bottom: 2px solid #000000;
}