.cart {
    font-size: 14px;
}
@media screen and (min-width: 768px) {
    .cart {
        font-size: 15px;
    }
}
.cart__body {
    padding: 0;
}
.cart__body--hotels {
    padding: 0;
}
.cart__hotel {
    padding: 0 11px 9px;
}
@media screen and (min-width: 768px) {
    .cart__hotel {
        padding: 0;
    }
}

@media screen and (max-width: 767px) {
    .cart__hotel + .cart__hotel {
        border-top: 1px dotted #c0bdb7;
        padding-top: 8px;
    }
}
@media screen and (min-width: 768px) {
    .cart__hotel + .cart__hotel {
        margin-top: 40px;
    }
}

.cart__hotel-title {
    margin-top: 0;
    margin-bottom: 6px;
    font-family: var(--font-default-bold);
    font-size: 14px;
}
@media screen and (min-width: 768px) {
    .cart__hotel-title {
        font-size: 26px;
        font-family: var(--font-default-light);
    }
}

.cart__room + .cart__room {
    margin-top: 6px;
}
@media screen and (min-width: 768px) {
    .cart__room {
        overflow: hidden;
    }
    .cart__room + .cart__room {
        margin-top: 19px;
        border-top: 1px solid #d1cec8;
        padding-top: 11px;
    }
}

.cart__room-title {
    margin-top: 0;
    margin-bottom: 3px;
    font-family: var(--font-default-bold);
    font-size: 15px;
}

.cart__room-info {
    font-size: 13px;
    line-height: calc(18 / 13);
}

.cart__room-info-price {
    font-family: var(--font-default-medium);
}
@media screen and (min-width: 768px) {
    .cart__room-info {
        font-size: 14px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        margin-right: -9px;
        margin-left: -9px;
    }
    .cart__room-info-item {
        flex: 0 0 auto;
        min-width: 0;
        min-height: 0;
        border-left: 1px solid #d1cec8;
        padding-left: 9px;
        padding-right: 9px;
    }
    .cart__room-info-price {
         flex-grow: 0;
         font-family: var(--font-default-medium);
         text-align: right;
     }
    .cart__room-info-breakfast {
        flex-grow: 10;
    }
    .cart__room-info .icon {
        font-size: 19px;
        vertical-align: -0.15em;
        margin-right: 3px;
    }
    .cart__room-info .cart__room-info-child-icon {
        font-size: 15px;
        vertical-align: sub;
    }
}

