.hotel-list {
    padding-left: 0;
    list-style-type: none;
}
.hotel-list>li {
    padding-left: 28px;
    text-indent: -27px;
    padding-bottom: 10px;
}
.hotel-list>li:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 12px;
    margin-right: 7px;
}

.hotel-list a:hover {
    /*color: var(--color-primary);*/
    font-family: "FS Emeric Bold", sans-serif;
}

.hotel-list>.-premium:before { background-color: #9b8868; }
.hotel-list>.-comfort:before { background-color: #b8ae9b; }
.hotel-list>.-smart:before { background-color: #c8b368; }
