.help-slide__title {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 5px;
    font-family: var(--font-default-bold);
    text-transform: uppercase;
}
.help-slide__text {
    margin-bottom: 5px;
}
@media screen and (min-width: 768px) {
    .help-slide__title {
        font-size: 28px;
        margin-bottom: 10px;
    }

    .help-slide__text {
        font-size: 20px;
        margin-bottom: 25px;
    }
}