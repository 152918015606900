.range-slider {
    padding: 0 10px;
}
.range-slider__bar {
    height: 2px;
}
.range-slider__bar.noUi-target {
    border: none;
    box-shadow: none;
    background-color: #1e1d1b;
    border-radius: 0;
}
.range-slider__bar .noUi-connect {
    box-shadow: none;
    background-color: #b49e57;
    margin-top: -1px;
    height: 4px;
}
.range-slider__bar .noUi-handle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: none;
    border: none;
    background-color: #8c0f00;
    left: -10px;
    top: -9px;
}
.range-slider__bar .noUi-handle:before,
.range-slider__bar .noUi-handle:after {
    display: none;
}