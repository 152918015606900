.nav-list>ul {
    padding-left: 0;
    list-style-type: none;
}
.nav-list>ul>li>a,
.nav-list>ul>li>span {
    position: relative;
    display: block;
    padding-top: 10px;
    color: inherit;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    font-family: FS Emeric Light, sans-serif;


}

/*Border bottom*/
.nav-list>ul>li>a:after,
.nav-list>ul>li>span:after {
    content: '';
    display: block;
    width: 31px;
    height: 1px;
    margin-top: 8px;
    border-bottom: 1px dotted;
}

/*Hover & Active State*/
.nav-list>ul>li>a:hover,
.nav-list>ul>li>span:hover,
.nav-list>ul>.is-open>a,
.nav-list>ul>.is-open>span {
    font-family: "FS Emeric Bold", sans-serif;
}

