.slick-slider {
    position: relative;
}
.slick-slider:not(.slick-initialized) > div + div { display: none; }
.slick-slider .slick-next,
.slick-slider .slick-prev {
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 2;
    background: none;
    border: none;
    text-indent: -999em;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
    padding: 20px;
}
.slick-slider .slick-prev:after,
.slick-slider .slick-next:after {
    display: block;
    content: "\E004";
    text-indent: 0;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
    font-size: 24px;
}
.slick-slider .slick-next {
    left: auto;
    right: 0;
}
.slick-slider .slick-next:after {
    content: "\E005";
}

.slick-slider--hidden-dots .slick-dots {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
@media screen and (max-width: 767px) {
    .slick-slider--hidden-arrows-on-xs .slick-next,
    .slick-slider--hidden-arrows-on-xs .slick-prev {
        display: none !important; /*override inline style*/
    }
}

.slick-slide img {
    width: 100%;
}