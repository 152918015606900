@media screen and (max-width: 767px) {
    .booking-selection {
        padding: 0 12px;
    }
}
.booking-selection__adult {
    font-size: calc(19 / 16 * 1em);
    vertical-align: -0.15em;
}
.booking-selection__child {
    font-size: calc(14 / 16 * 1em);
    vertical-align: -0.26em;
}
.booking-selection__currency {
    display: inline-block;
    transform: translateY(-0.2em);
    /* vertical-align: is not used because it affects the surrounding text too*/
}

.booking-selection__plus-sign{
     font-family: sans-serif;
 }

.booking-selection__plus-sign{
    font-family: sans-serif;
}

@media screen and (max-width: 767px) {
    .booking-selection {
        font-size: 14px;
    }
    .booking-selection__section {
        border-top: 1px solid #c0bdb7;
        padding: 10px 0 15px;
    }
    .booking-selection__title {
        font-size: inherit;
        margin-top: 0;
        margin-bottom: 10px;
        font-family: var(--font-default-medium);
    }
    .booking-selection__price {
        font-size: 20px;
        line-height: 1;
    }
}

@media screen and (min-width: 768px) {
    .booking-selection {
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        font-size: 14px;
        border-bottom: 1px dotted #c0bdb7;
    }
    .booking-selection th {
        font-family: var(--font-default-bold);
        padding: 0 9px 9px;
        line-height: 1;
    }
    .booking-selection td {
        padding: 6px 9px 5px;
        vertical-align: top;
    }
    .booking-selection__head {
        border-bottom: 1px solid #c0bdb7;
    }
    .booking-selection__bordered-item {
        border-right: 1px solid #c0bdb7;
    }
    .booking-selection__price {
        font-size: 32px;
        line-height: 1;
    }
    .booking-selection__currency {
        font-size: 18px;
    }
    .booking-selection--no-padding-right.booking-selection--no-padding-right {
        padding-right: 0;
    }
    .booking-selection--no-padding-left.booking-selection--no-padding-left {
        padding-left: 0;
    }
    .booking-selection--no-padding-top.booking-selection--no-padding-top {
        padding-top: 0;
    }
}
@media screen and (min-width: 1200px) {
    .booking-selection {
        font-size: 16px;
    }
    .booking-selection th {
        padding: 0 18px 15px;
    }
    .booking-selection td {
        padding: 13px 18px 12px;
    }
}
.booking-selection__select {
    min-width: 65px;
}

.booking-selection--discount-icon {
    font-size: 18px;
    vertical-align: -.05em;
}
@media screen and (min-width: 768px) {
    .booking-selection--discount-icon {
        font-size: 26px;
    }
}