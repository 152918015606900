.flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /*justify-content: space-between;*/
}
.flex-row__item {
    flex: 0 1 auto;
}
.flex-row--bordered .flex-row__item {
    padding-right: 15px;
    border-right: 1px solid #c0bdb7;
    margin-right: 15px;
}
.flex-row--bordered .flex-row__item:last-child {
    border-right: none;
    padding-right: 0;
}