/*Text Helper*/
.text-truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.text-bottom {
    vertical-align: bottom;
}
.text-underline {
    text-decoration: underline;
}
.page-wrapper p a.text-underline,
.page-wrapper li a.text-underline {
    border-bottom: none;
}

/*Text color*/
.text-primary { color: var(--color-primary); }
a.text-primary { color: var(--color-primary); }
a.text-primary:hover { color: var(--color-secondary); }
.text-success { color: #4c880b; }

.text-premium { color: #9b8868; }
.text-comfort { color: #b8ae9b; }
.text-smart { color: #c8b368; }

.text-default { color: var(--color-body-text) }

.text-white { color: #ffffff; }

.text-info {
    color: var(--color-dark-grey);
}
.text-success { color: #4c880b; }
.text-danger { color: var(--color-primary); }
.text-color-default { color: #262626; }

.text-muted {
    color: #aaaaaa;
}

/*Backgrounds*/

.bg-white {
    background-color: #ffffff;
}
.bg-beige {
    background-color: #f6f4f0;
}
.bg-beige-dark {
    background-color: #b8ae9b;
    color: #ffffff;
}
.bg-beige-medium {
    background-color: #eae8e4;
}
.bg-premium { background-color: #9b8868; }
.bg-comfort { background-color: #b8ae9b; }
.bg-smart { background-color: #c8b368; }
.bg-dark {
    background-color: #1e1d1b;
    color: #ffffff;
}
.bg-light-pattern {
    background: #edebe7 url('/static/img/pattern_x-light.png')
}
.bg-info {
    background-color: #dcdcdc;
}

/*Aspect ractions*/
/*.embed-responsive { width: 100%; }*/
/*.embed-responsive.embed-responsive-16by11 {*/
    /*padding-top: 68.75%;*/
/*}*/
/*.embed-responsive-25by14 {*/
    /*padding-top: calc(14/25*100%);*/
/*}*/
/*.embed-responsive.embed-responsive-2by1 {*/
    /*padding-top: calc(1/2*100%);*/
/*}*/
/*.embed-responsive.embed-responsive-3by2 {*/
    /*padding-top: calc(2/3*100%);*/
/*}*/
.embed-responsive.embed-responsive-9by4 {
    padding-top: calc(4/9*100%);
}
.embed-responsive.embed-responsive-1by1 {
    padding-top: 100%;
    padding-bottom: 0;
}
/*.embed-responsive.embed-responsive-9by5 {*/
    /*padding-top: 56.1%;*/
/*}*/
.embed-responsive.embed-responsive-topslider,
.embed-responsive.embed-responsive-topslider-portal {
    padding-top: 49.24242%;
}
@media screen and (min-width: 768px) {
    .embed-responsive.embed-responsive-topslider {
        padding-top: 41.5%;
    }
    .embed-responsive.embed-responsive-topslider-portal {
        padding-top:44%;
    }
}

/*Row gutters (mobile)*/
.row--gutter-width-0 {
    margin-left: 0;
    margin-right: 0;
}
.row--gutter-width-0>.col,
.row--gutter-width-0>.col {
    padding-left: 0;
    padding-right: 0;
}
.row--gutter-width-10 {
    margin-left: -10px;
    margin-right: -10px;
}
.row--gutter-width-20 {
    margin-left: -10px;
    margin-right: -10px;
}
.row--gutter-width-20>.col,
.row--gutter-width-20>.col {
    padding-left: 10px;
    padding-right: 10px;
}
.row--gutter-width-2 {
    margin-left: -1px;
    margin-right: -1px;
}
.row--gutter-width-2>.col,
.row--gutter-width-2>.col {
    padding-left: 1px;
    padding-right: 1px;
}
.row--gutter-width-24 {
    margin-left: -12px;
    margin-right: -12px;
}
@media screen and (min-width: 768px) {
    .row--gutter-width-44 {
        margin-left: -22px;
        margin-right: -22px;
    }
    .row--gutter-width-44>.col,
    .row--gutter-width-44>.arrange>.col {
        padding-left: 22px;
        padding-right: 22px;
    }
}


/* row--same-height */
@media screen and (min-width: 768px) {
    .row--same-height__item {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
    }
    /*
     * the .row--same-height selector has to be after
     * .row--same-height__item to increase the specificity
     * in case of chaining the two selectors
     * (which is needed for nested rows)
     */
    .row--same-height {
        display: flex;
        flex-direction: row;
        /*align-items: stretch;*/
        /*justify-content: space-between;*/
        overflow: hidden;
        flex-wrap: wrap;
    }
    .row--same-height:before,
    .row--same-height:after {
        display: none;
    }
    .row--same-height > .col {
        display: flex;
        flex-direction: column;
        height: auto;
    }
    .row--same-height__item--fixed-height {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;
    }
    .row--same-height__item__variable-height {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
    }
    .row--same-height__item--center {
        justify-content: center;
    }
}

.row--vertical-gutter-30 {
    margin-top: -30px;
}
.row--vertical-gutter-30 > .col,
.row--vertical-gutter-30 > [class^="col"],
.row--vertical-gutter-30 > [class*=" col"] {
    margin-top: 30px;
}

/*JS-specific*/
.isClickable,
.js-lightbox {
    cursor: pointer;
}

/*Arrange*/

.arrange-xs {
    display: table;
    min-width: 100%;
    table-layout: auto;
}

/*.arrange-xs > .arrange__item {*/
    /*display: table-cell;*/
    /*vertical-align: middle;*/
    /*float: none;*/
/*}*/
/*.arrange-xs > .arrange__item--top {*/
    /*vertical-align: top;*/
/*}*/
/*.arrange-xs > .arrange__item--bottom {*/
    /*vertical-align: bottom;*/
/*}*/


@media screen and (min-width: 768px) {
    .arrange {
        display: table;
        min-width: 100%;
        table-layout: auto;
    }
    .arrange.row {
        /*width: auto;*/
    }
    .arrange__item {
        display: table-cell;
        vertical-align: middle;
        float: none;
    }
    .arrange__item--top {
        vertical-align: top;
    }
    .arrange__item--bottom {
        vertical-align: bottom;
    }
}

/*Centering Helper*/
.center-container {
    text-align: center;
    font-size: 0;
    /*padding: 5px;*/
}
.center-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
.center-container__item {
    display: inline-block;
    vertical-align: middle;
    max-height: 100%;
    max-width: 100%;
}

.absolute-centered {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
}

.list-unstyled {
    margin-bottom: 0;
}

/*Margins (mobile)*/
.no-margin.no-margin {
    margin: 0;
}
/*Margins (mobile)*/
.mt0 { margin-top: 0; }
/*.mt10 { margin-top: 5px; }*/
.mt20 { margin-top: 10px; }
.mt30 { margin-top: 15px; }
.mt45 { margin-top: 45px; }
.mb0.mb0.mb0 { margin-bottom: 0;}
.mb15 { margin-bottom: 10px;}
.mb40 { margin-bottom: 40px;}
.mb50 { margin-bottom: 50px;}
.mr0.mr0 { margin-right: 0}

/* Margins mobile */
@media screen and (max-width: 767px) {
    .mt-xs-20 {
        margin-top: 20px;
    }
}

/*Margins (not mobile)*/
@media screen and (min-width: 768px) {
    .mt20 { margin-top: 20px; }
}
.mt0 { margin-top: 0; }
.mt5 { margin-top: 5px; }
.mt10 { margin-top: 10px; }
.mt15 { margin-top: 15px; }
.mt25 { margin-top: 25px; }
.mt30 { margin-top: 30px; }
.mt40 { margin-top: 40px; }

.mb0 { margin-bottom: 0; }
.mb5 { margin-bottom: 5px; }
.mb10 { margin-bottom: 10px; }
.mb15 { margin-bottom: 15px; }
.mb25 { margin-bottom: 25px; }
.mb20 { margin-bottom: 20px; }
.mb30 { margin-bottom: 30px; }
.mb60 { margin-bottom: 60px; }
@media screen and (max-width: 767px) {
    .mb60 { margin-bottom: 40px; }
}

.ml5 { margin-left: 5px; }
.ml20 { margin-left: 20px; }

.mr10 {margin-right: 10px;}

/*Paddings*/
.no-padding.no-padding.no-padding { padding: 0; }
.pt0.pt0 { padding-top: 0px; }
.pt10 { padding-top: 10px; }
.pt10 { padding-top: 10px; }
.pb40 {
    padding-bottom: 40px;
}

.no-border,
.page-wrapper p a.no-border,
.page-wrapper li a.no-border {
    border: none;
}

.list-inline { margin-bottom: 0; }
.list-inline>li { margin-bottom: 10px; }
.list-inline--no-mb>li { margin-bottom: 0; }

.list-inline--bordered > li + li {
    border-left: 1px solid #c0bdb7;
}
.list-inline--gutter-30 {
    margin-left: 0;
    margin-right: 0;
}
.list-inline--gutter-30 > li {
    margin-right: 30px;
    padding: 0;
}

.icon-in-text {
    vertical-align: -.18em;
    margin-right: 3px;
}
.icon-in-text.icon-pin-2 {
    vertical-align: -.1em;
}
.icon-in-text.icon-dropdown {
    font-size: .35em;
}
.icon-in-text.icon-phone {
    vertical-align: -.12em;
}
.icon-in-text.icon-email-open {
    vertical-align: -.1em;
}
.icon-in-text.icon-airplane-2 {
    font-size: .7em;
}
.icon-in-text.icon-teddy {
    font-size: 18px;
}
.icon-in-text.icon-delete {
    font-size: 14px;
    vertical-align: -.1em;
}
.icon-in-text.icon-arrow-textlink {
    font-size: 0.7em;
    vertical-align: -0.1em;
}
.icon-in-text--right {
    margin-right: 0;
    margin-left: 3px;
}

.img-full-width {
    display: block;
    width: 100%;
    height: auto;
}

.vertical-center {
    display: flex;
    align-items: center;
}

.fz10 { font-size: 10px; }
.fz14 { font-size: 14px; }
.fz15 { font-size: 15px; }
.fz17 { font-size: 17px; }
.fz18 { font-size: 18px; }
.fz20 { font-size: 20px; }

@media screen and (max-width: 767px) {
    .block-on-xs {
        display: block;
    }
}

.todo {
    padding: 20px;
    background: orange;
    font-size: 1.3em;
}

/*todo move*/
.panel--no-styling {
    margin-bottom: 0;
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
}

.standalone-logo {
    display: block;
    margin-top: 20px;
    margin-left: 20px;
}
@media screen and (min-width: 1500px) {
    .standalone-logo {
        margin-left: 30px;
    }
}

.info-btn {
    display: inline-block;
    width: 19px;
    height: 19px;
    margin-left: 4px;
    border-radius: 50%;
    background-color: #4e504d;
    color: #ffffff;
    text-align: center;
    font-family: var(--font-default-medium);
    line-height: 1.4;
    vertical-align: .1em;
}

.collapse-icon {
    transform: rotate(180deg);
    transition: transform 120ms ease-out;
}
.collapsed .collapse-icon {
    transform: none;
}
.collapse-btn-text--collapsed,
.collapsed .collapse-btn-text {
    display: none;
}
.collapsed .collapse-btn-text--collapsed,
.collapse-btn-text {
    display: inline-block;
}

.position-relative {
    position: relative;
}

.d-block {
    display: block;
}
.d-none {
    display: none;
}
.va-base-line {
    vertical-align: baseline;
}

a.reset-tracking-styling{ /*needed to reset styling of footer for task #576793*/
    color: inherit !important;
    border: none !important;
    font-family: var(--font-default-light) !important;
}

.content-visibility--auto {
    content-visibility: auto;
}