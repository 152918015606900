.discount-bar {
    padding: 7px 13px;
    margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
    .discount-bar {
        padding: 13px 22px;
        margin-bottom: 25px;
    }
}
.discount-bar__icon {
    font-size: 20px;
    position: relative;
    top: 5px;
}
@media screen and (min-width: 768px) {
    .discount-bar__icon-wrapper {
        height: 33px;
    }
    .discount-bar__icon {
        font-size: 33px;
        position: static;
    }
    .discount-bar__item.discount-bar__item {
        align-self: center;
    }
    .discount-bar .custom-radio {
        position: relative;
        top: 2px;
    }
}
