.modal {
    z-index: 104000;
}
.modal-content {
    border-radius: 0;
    border: none;
    font-size: 15px;
}
.modal-header,
.modal-body,
.modal-footer {
    padding: 0;
    border: none;
    background: #ffffff;
}
.modal-close {
    font-size: 12px;
    background: none;
    border: none;
    padding: 9px 11px;
    z-index: 1;
}
.modal-close:hover {
    color: var(--color-primary);
}
@media screen and (max-width: 767px) {
    .modal-close {
        margin: -9px -11px;
    }
}
@media screen and (min-width: 768px) {
    .modal-close {
        position: absolute;
        top: 0;
        right: 0;

    }
}

@media screen and (max-width: 767px) {
    .modal-dialog {
        margin: 0;
    }
    .modal-dialog,
    .modal-content,
    .modal-container {
        height: 100%;
        overflow: hidden;
    }
    .modal-container {
        display: flex;
        flex-direction: column;
    }
    .modal-header {
        padding: 11px 14px;
        background-color: #f7f4ef;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
        flex: 0 0 auto;
    }
    .modal-title {
        margin: 0;
        font-size: 20px;
    }
    .modal-body {
        flex: 1 1 auto;
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }
    .modal-body-box {
        padding: 12px;
    }
    .modal-footer {
        text-align: left;
        position: relative;
        flex: 0 0 auto;
        box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.2);
    }
    .modal-footer--bordered {
        box-shadow: none;
        border-top: 1px solid #d1cec8;
    }
    .modal-footer-icon {
        position: absolute;
        left: 50%;
        bottom: 100%;
        transform: translateX(-50%);
        background: #ffffff;
        font-size: 18px;
        line-height: 1;
        border-radius: 26px 26px 0 0;
        border: 1px solid #d1cec8;
        border-bottom: none;
        padding: 7px 19px 0;
    }
}

@media screen and (min-width: 768px) {
    .modal-dialog {
        width: 1044px;
        max-width: 100%;
    }
    .modal-content {
        padding: 25px 0 40px;
    }
    .modal-container {
        margin-left: auto;
        margin-right: auto;
        max-width: 821px;
        /*padding: 50px 15px;*/
        padding: 0 15px;
    }
    .modal-header {
        margin-bottom: 40px;
    }
}
@media screen and (max-width: 767px) {
    .modal-body-section + .modal-body-section{
        border-top: 1px solid #c0bdb7;
        padding-top: 10px;
    }
}
@media screen and (min-width: 768px) {
    .modal-body-section {
        padding-bottom: 40px;
    }
    .modal-body-section + .modal-body-section {
        padding-top: 20px;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    }
    .modal-body-section:last-child {
        padding-bottom: 0;
    }
}
.modal-body-section-title {
    font-family: var(--font-default-light);
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 1.33;
}
@media screen and (min-width: 768px) {
    .modal-body-section-title {
        font-family: var(--font-default-bold);
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: 15px;
    }
}
.modal-body-section-title-icon  {
    vertical-align: .2em;
    font-size: 25px;
    margin-right: 0;
}
.modal-body-section-title-icon.icon-calendar-2  {
    font-size: 18px;
}
.modal-body-section-title-icon.icon-teddy  {
    font-size: 20px;
}

@media screen and (min-width: 768px) {
    .modal-footer {
        margin-top: 40px;
    }
    .modal-title {
        margin: 0;
        font-size: 32px;
    }
    .modal-dialog.modal-lg {
        width: 1200px;
    }
    .modal-lg .modal-container {
        max-width: 1052px;
    }
}

/*modal scroll in */
@media screen and (max-width: 768px) {
    .modal--scroll-in {
        transform: translateX(100%);
        display: block !important; /*override inline style*/
        visibility: hidden;
        transition: transform 140ms ease-in-out, visibility 0.1ms 140ms;
    }
    .modal--scroll-in.in {
        visibility: visible;
        transform: none;
        transition: transform 140ms ease-in-out;
    }
}

/*modal full screen*/
@media screen and (min-width: 768px) {
    .modal--full-screen {
        padding-right: 0 !important; /*override bs inline style*/
    }
    .modal--full-screen .modal-dialog {
        margin: 0;
    }
    .modal--full-screen .modal-dialog,
    .modal--full-screen .modal-content,
    .modal--full-screen .modal-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        padding: 0;
    }
    .modal--full-screen .modal-container {
        max-width: none;
        width: auto;
        padding: 0;
        display: flex;
        flex-direction: column;
    }
    .modal--full-screen .modal-header {
        padding: 20px;
        flex: 0 0 auto;
        margin-bottom: 0;
    }
    .modal--full-screen .modal-body {
        flex: 1 1 auto;
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }
}
@media screen and (min-width: 1500px) {
    .modal--full-screen .modal-header {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media screen and (min-width: 768px) {
    .modal-backdrop {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 200;
        background-color: #000;
    }
    .modal-dialog--centered {
        max-height: 90vh;
        overflow-y: auto;
        margin-top: 50vh;
        transform: translateY(-75%);
        transition: transform 240ms ease;
    }
    .modal.in .modal-dialog--centered {
        transform: translateY(-50%); /* override inline style */
    }
}
