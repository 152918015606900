@font-face {
    font-family: "iconfont";
    src: url('/static/icons/font/iconfont.eot?#iefix') format('eot'),
        url('/static/icons/font/iconfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
    min-width: 0;
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.icon-rotate-180 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.icon-rotate-270 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
    -webkit-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
    filter: none;
}

.icon-GMmaster:before { content: "\E001" }
    .icon-airplane:before { content: "\E002" }
    .icon-apple:before { content: "\E003" }
    .icon-arrow-back:before { content: "\E004" }
    .icon-arrow-next:before { content: "\E005" }
    .icon-arrow-textlink:before { content: "\E006" }
    .icon-arrow_down:before { content: "\E007" }
    .icon-b:before { content: "\E008" }
    .icon-block-tafel:before { content: "\E009" }
    .icon-brand:before { content: "\E00A" }
    .icon-breakfast:before { content: "\E00B" }
    .icon-business:before { content: "\E00C" }
    .icon-c:before { content: "\E00D" }
    .icon-calendar:before { content: "\E00E" }
    .icon-camera:before { content: "\E00F" }
    .icon-category:before { content: "\E010" }
    .icon-close:before { content: "\E011" }
    .icon-close_menu:before { content: "\E012" }
    .icon-closed:before { content: "\E013" }
    .icon-cocktail-event:before { content: "\E014" }
    .icon-cocktail:before { content: "\E015" }
    .icon-coffee:before { content: "\E016" }
    .icon-contact:before { content: "\E017" }
    .icon-d:before { content: "\E018" }
    .icon-download:before { content: "\E019" }
    .icon-dropdown:before { content: "\E01A" }
    .icon-e-tanken:before { content: "\E01B" }
    .icon-e:before { content: "\E01C" }
    .icon-email:before { content: "\E01D" }
    .icon-f:before { content: "\E01E" }
    .icon-facebook:before { content: "\E01F" }
    .icon-fahne:before { content: "\E020" }
    .icon-fahne_small:before { content: "\E021" }
    .icon-fitness:before { content: "\E022" }
    .icon-g:before { content: "\E023" }
    .icon-google_plus_new:before { content: "\E024" }
    .icon-googleplus:before { content: "\E025" }
    .icon-group_logo:before { content: "\E026" }
    .icon-h:before { content: "\E027" }
    .icon-kissen:before { content: "\E028" }
    .icon-l:before { content: "\E029" }
    .icon-leaf:before { content: "\E02A" }
    .icon-line:before { content: "\E02B" }
    .icon-linkedIn:before { content: "\E02C" }
    .icon-list:before { content: "\E02D" }
    .icon-lounge:before { content: "\E02E" }
    .icon-m:before { content: "\E02F" }
    .icon-m2:before { content: "\E030" }
    .icon-menu:before { content: "\E031" }
    .icon-minibar:before { content: "\E032" }
    .icon-minus:before { content: "\E033" }
    .icon-n:before { content: "\E034" }
    .icon-o:before { content: "\E035" }
    .icon-p:before { content: "\E036" }
    .icon-panorama:before { content: "\E037" }
    .icon-park:before { content: "\E038" }
    .icon-parlament:before { content: "\E039" }
    .icon-pc:before { content: "\E03A" }
    .icon-pin:before { content: "\E03B" }
    .icon-play:before { content: "\E03C" }
    .icon-plus:before { content: "\E03D" }
    .icon-poi:before { content: "\E03E" }
    .icon-profile:before { content: "\E03F" }
    .icon-q:before { content: "\E040" }
    .icon-r:before { content: "\E041" }
    .icon-restaurant:before { content: "\E042" }
    .icon-roomservice:before { content: "\E043" }
    .icon-run-guide:before { content: "\E044" }
    .icon-runde-tische:before { content: "\E045" }
    .icon-s:before { content: "\E046" }
    .icon-search:before { content: "\E047" }
    .icon-shirt:before { content: "\E048" }
    .icon-skikeller:before { content: "\E049" }
    .icon-tageslicht:before { content: "\E04A" }
    .icon-attention:before { content: "\E04B" }
    .icon-terasse:before { content: "\E04C" }
    .icon-theater:before { content: "\E04D" }
    .icon-tick:before { content: "\E04E" }
    .icon-tripadvisor:before { content: "\E04F" }
    .icon-u-tafel:before { content: "\E050" }
    .icon-w:before { content: "\E051" }
    .icon-wellness:before { content: "\E052" }
    .icon-wlan:before { content: "\E053" }
    .icon-x:before { content: "\E054" }
    .icon-y:before { content: "\E055" }
    .icon-z:before { content: "\E056" }
    .icon-a:before { content: "\E057" }
    .icon-group_logo2:before { content: "\E058" }
    .icon-brille:before { content: "\E059" }
    .icon-daumen:before { content: "\E05A" }
    .icon-euro:before { content: "\E05B" }
    .icon-herz:before { content: "\E05C" }
    .icon-soc-facebook:before { content: "\E05D" }
    .icon-soc-foursquare:before { content: "\E05E" }
    .icon-soc-insatgram:before { content: "\E05F" }
    .icon-soc-tripadvisor:before { content: "\E060" }
    .icon-soc-yelp:before { content: "\E061" }
    .icon-stempel-austria:before { content: "\E062" }
    .icon-stempel:before { content: "\E063" }
    .icon-stern:before { content: "\E064" }
    .icon-uhr:before { content: "\E065" }
    .icon-weitersagen:before { content: "\E066" }
    .icon-soc-pinterest:before { content: "\E067" }
    .icon-soc-twitter:before { content: "\E068" }
    .icon-soc-facebook-blog:before { content: "\E069" }
    .icon-check:before { content: "\E06A" }
    .icon-center:before { content: "\E06B" }
    .icon-soc-twitter-blog:before { content: "\E06C" }
    .icon-closed-cirle:before { content: "\E06D" }
    .icon-airplane-2:before { content: "\E06E" }
    .icon-check-circle:before { content: "\E06F" }
    .icon-family:before { content: "\E070" }
    .icon-delete:before { content: "\E071" }
    .icon-edit:before { content: "\E072" }
    .icon-user:before { content: "\E073" }
    .icon-person:before { content: "\E074" }
    .icon-grid:before { content: "\E075" }
    .icon-cart:before { content: "\E076" }
    .icon-money:before { content: "\E077" }
    .icon-calendar-2:before { content: "\E078" }
    .icon-reload:before { content: "\E079" }
    .icon-fullscreen:before { content: "\E07A" }
    .icon-star:before { content: "\E07B" }
    .icon-swipe:before { content: "\E07C" }
    .icon-teddy:before { content: "\E07D" }
    .icon-train:before { content: "\E07E" }
    .icon-pin-2:before { content: "\E07F" }
    .icon-email-open:before { content: "\E080" }
    .icon-phone:before { content: "\E081" }
    .icon-bed:before { content: "\E082" }
    .icon-discount:before { content: "\E083" }
    .icon-teddy_neu:before { content: "\E084" }
    .icon-insta:before { content: "\E085" }
    .icon-filter:before { content: "\E086" }
    .icon-filter-test:before { content: "\E087" }
    .icon-uhr-outline:before { content: "\E200" }
    .icon-temperature:before { content: "\E201" }
    .icon-route:before { content: "\E205" }
    .icon-soc-pinterest-blog:before { content: "\E206" }
    .icon-sleeping-experience:before { content: "\E207" }
    