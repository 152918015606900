.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}.custom-checkbox:after {
     clear: both;
 }
.custom-checkbox__input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-checkbox__box {
    position: relative;
    float: left;
    margin-right: 7px;
    overflow: hidden;
    border: 1px solid #c4c6c7;
    background: #ffffff;
    width: 16px;
    height: 16px;
    margin-top: 0.25em;
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 7px;
    color: var(--color-primary);
    visibility: hidden;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary);
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
}
/*disabled state*/
.custom-checkbox__input[disabled] ~ .custom-checkbox__box,
.custom-checkbox__input[disabled] ~ .custom-checkbox__text {
    opacity: .4;
}
.custom-checkbox__hint {
    font-size: 7px;
}
.custom-checkbox--in-text {
    display: inline-block;
    position: relative;
    top: 0.45em;
    margin-left: .5em;
}