.wysiwyg {
    /*Needed For responsive tables*/
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
    margin-top: 20px;
}

.wysiwyg h1:first-child,
.wysiwyg h2:first-child,
.wysiwyg h3:first-child,
.wysiwyg h4:first-child,
.wysiwyg h5:first-child,
.wysiwyg h6:first-child {
    margin-top: 0;
}

.wysiwyg ul,
.wysiwyg ol {
    padding-left: 30px;
}
ul.list-default,
.wysiwyg ul {
    list-style-type: none;
}
ul.list-default>li,
.wysiwyg ul>li {
    text-indent: -0.37em;
}
ul.list-default>li:before,
.wysiwyg ul>li:before {
    content: "•";
    display: inline-block;
    margin-right: 5px;
    color: var(--color-primary);
}

/* For table in wysiwyg see table.css*/
