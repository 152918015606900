.hotelnav-switch__title {
    display: block;
    margin: 0 0 12px 0;
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 18px;
    color: #a3211f;
    line-height: 1.15;
}

.hotelnav-switch__title a {
    text-decoration: underline;
}

.hotelnav-switch__title a:hover {
    text-decoration: none;
    color: #000;
}

.hotelnav-switch__title--black{
    color: #000000;
}

.hotelnav-switch ul{
    margin: 0 0 40px 0;
    padding: 0;
    list-style: none;
}

.hotelnav-switch ul li{
    margin-bottom: 8px;
}

.hotelnav-switch ul li a{
    font-family: FS Emeric Light, sans-serif;
    font-size: 14px;
}
@media screen and (min-width: 1300px) {
    .hotelnav-switch ul li a{
        font-size: 18px;
    }
}

.hotelnav-switch ul li a:hover{
    font-weight: bold;
}

.linktext{
    float: left;
    width: 85%;
    text-transform: initial;
}

.hotelnav-switch .flag{
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('/static/img/flags_small.png') no-repeat;
    background-size: 20px 60px;
    margin-right: 10px;
    position: relative;
    top: 7px;
    float: left;
}

.hotelnav-switch .flag.code_5C{
    background-position: 0 -20px;
}

.hotelnav-switch .flag.code_1S{
    background-position: 0 -40px;
}

.hotelnav-switch .big-flags{
    background: url('/static/img/flags_slide.png') no-repeat;
    background-size: 90px 270px;
    display: inline-block;
    width: 90px;
    height: 90px;
    float: left;
    margin-right: 10px;
    font-family: FS Emeric Light, sans-serif;
    font-size: 16px;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    padding-top: 24px;
}

.hotelnav-switch .big-flags.comfort{
    background-position: 0 -90px;
}

.hotelnav-switch .big-flags.smart{
    background-position: 0 -180px;
}

strong.hotel-einleitung{
    padding: 30px 0;
    display: block;
}