.collapse-link {
    color: var(--color-primary);
    font-family: var(--font-default-medium);
    font-size: 13px;
    text-align: left;
}
@media screen and (max-width: 767px) {
    .collapse-link__icon {
        font-size: 7px;
        transform: rotate(180deg);
        transition: transform 120ms ease-out;

    }
    .collapse-link.collapsed .collapse-link__icon{
        transform: none;
    }
}
@media screen and (min-width: 768px) {
    .collapse-link {
        color: #ababab;
        font-size: 15px;
        text-decoration: underline;
        text-align: center;
    }
    .collapse-link__icon {
        display: inline-block;
        border-radius: 50%;
        background: #ababab;
        color: #FFFFFF;
        width: 23px;
        height: 23px;
        font-size: 8px;
        line-height: 22px;
    }
    .collapse-link__icon:before {
        content: "\E033";
    }

    /*collapsed*/
    .collapse-link.collapsed {
        color: var(--color-primary);
    }
    .collapse-link.collapsed .collapse-link__icon {
        background: var(--color-primary);
    }
    .collapse-link.collapsed .collapse-link__icon:before {
        content: "\E03D";
    }
}
