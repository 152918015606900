@media screen and (max-width: 767px) {
    #main-nav-mobile { visibility: visible }
    #page-header__hotel_bar,
    #page-header__date_bar { height: 3em; }

    .booking-bar-portal i.my-point,
    .booking-bar-portal i.my-cal {
        position: absolute;
        top: 1em;
        left: 13px;
    }

    .navbar-collapse.collapse.in{
        display: block!important;
    }

    .mobile-nav-toggle{
        position: relative;
        float: left;
        padding: 0;
        border-radius: 0;
        border: none;
    }


    .mobile-nav-toggle.collapsed i.icon-menu{
        display: inline;
        top: 1px;
        position: relative;
    }

    .mobile-nav-toggle.collapsed i.icon-close{
        display: none;
    }

    .mobile-nav-toggle i.icon-close{
        display: inline;
        color: #ffffff;
        font-size: 22px;
        top: 6px;
        position: relative;
    }

    .mobile-nav-toggle i.icon-menu{
        display: none;
    }

    .mobile-main-nav__body  {
        padding: 7px 12px 25px 12px;
    }
    .mobile-main-nav__head  {
        display: block;
        padding: 10px 12px;
        overflow: hidden;
        text-transform: uppercase;
    }
    .mobile-main-nav__head .mobile-nav-toggle {
        height: auto;
        margin-right: 10px;
        margin-top: 4px;
    }
    .mobile-main-nav__head .icon-bar {
        width: 18px;
    }

    .mobile-main-nav ul{
        /*margin: 15px;*/
        margin: 0;
        list-style: none;
        padding: 0;
    }

    .mobile-main-nav ul li{
        margin: 0;
        padding: 0;
        border-bottom: 1px dotted #4a4a4a;
        display: block;
    }

    .mobile-main-nav ul li .icon-minus{
        display: none;
    }

    .mobile-main-nav ul li.active > a > .icon-minus{
        display: inline-block;
    }
    .mobile-main-nav ul li.active > a > .icon-plus{
        display: none;
    }

    /* hotelNAV */
    .mobile-main-nav ul.hotelNav  ul > li.hasChildren .icon-plus {
        display: inline-block;
    }

    .mobile-main-nav ul.hotelNav  ul > li.hasChildren .icon-minus {
        display: none;
    }

    .mobile-main-nav ul.hotelNav ul > li.hasChildren.active .icon-plus {
        display: none;
    }

    .mobile-main-nav ul.hotelNav ul > li.hasChildren.active .icon-minus {
        display: inline-block;
    }
    /* hotelNav untermenü */
    .mobile-main-nav ul.hotelNav  ul > li.hasChildren ul > li.hasChildren .icon-plus {
        display: inline-block;
    }

    .mobile-main-nav ul.hotelNav  ul > li.hasChildren ul > li.hasChildren .icon-minus {
        display: none;
    }

    .mobile-main-nav ul.hotelNav ul > li.hasChildren ul > li.hasChildren.active  .icon-plus {
        display: none;
    }

    .mobile-main-nav ul.hotelNav ul > li.hasChildren ul > li.hasChildren.active .icon-minus {
        display: inline-block;
    }

    .mobile-main-nav ul li:last-child{
        /*border-bottom: none;*/
    }

    .mobile-main-nav ul li a{
        display: block;
        font-size: 15px;
        padding: 9px 10px;
        text-transform: uppercase;
    }

    .mobile-main-nav ul li.active > ul{
        display: block;
    }

    .mobile-main-nav__body > ul > li > a{
        /*padding-left: 0;*/
        padding-left: 15px;
    }
    .mobile-main-nav__body > ul > li.hasChildren > a{
        padding-left: 0;
    }

    .mobile-main-nav ul li a i{
        margin-right: 5px;
        font-size: 10px;
    }

    /*first level */
    .mobile-main-nav__body > ul > li:last-child {
        border-bottom: none;
    }

    /* at least second level*/
    .mobile-main-nav ul li ul{
        margin: 0 0 10px 10px;
    }

    /*second level*/
    .mobile-main-nav__body > ul > li > ul > li{
        border-bottom: none;
    }
    .mobile-main-nav__body > ul > li > ul > li > a{
        padding-top: 3px;
        padding-bottom: 2px;
    }

    /*at least third level*/
    .mobile-main-nav ul ul ul li{
        margin-left: 24px;
        text-transform: none;
    }
    .mobile-main-nav ul ul ul a{
        padding-left: 0;
        text-transform: none;
    }



    .mobile-main-nav ul li ul{
        display: none;
    }

    .mobile-main-nav .hotelNav > .hasChildren.active > ul > li.hasChildren.active > ul {
        display: block;
    }

    .mobile-main-nav-container{
        -webkit-box-shadow: -4px 8px 11px -8px rgba(205,205,205,1);
        -moz-box-shadow: -4px 8px 11px -8px rgba(205,205,205,1);
        box-shadow: -4px 8px 11px -8px rgba(205,205,205,1);
    }

    .openLogin {
        position: inherit;
        min-height: 224px;
        width: 100%;
        padding-bottom: 20px;
        background-color: #ffffff;
    }

    .openLogin .red-button-login {
        padding: 8px 22px;
    }

    .openSearch {
        position: inherit;
    }

    .openSearch button {
        border-bottom: none;
        padding: 12px 0;
    }

    .openSearch button i {
        font-size: 20px;
        top: 3px;
    }

    .sidebar-bottom{
        margin-top: 30px;
    }

    .sidebar-bottom .language.open .dropdown-menu {
        top: -1px;
        bottom: auto;
        left: -217px;
        width: 369px;
        position: relative;
    }

    .sidebar-bottom .language span {
        padding: 26px 0;
    }

    .mobile-main-nav ul.hotelNav{
        margin-top: 25px;

    }

    .mobile-main-nav ul.hotelNav li{
        border-bottom: none;
    }

    .mobile-main-nav ul.hotelNav li a{
        padding: 9px 21px;
    }


    .mobile-nav-btn{
        width: 100%;
        border-radius: 0;
        padding: 6px 10px;
        background: transparent;
    }

    .mobile-nav-btn i{
        font-size: 24px;
        /*position: relative;*/
        /*top: -4px;*/
    }

    .mobile-nav-btn i.icon-calendar{
        top: -7px;
    }


    .mobile-nav-btn.collapsed{
/*        background: #ffffff;*/
    }

    .mobile-nav-btn .icon-close{
        display: inline-block;
        color: #ffffff;
    }

    .mobile-nav-btn.collapsed .icon-close{
        display: none;
    }


    .mobile-nav-btn .icon-calendar{
        display: none;
    }

    .mobile-nav-btn.collapsed .icon-calendar{
        display: inline-block;
    }

    .mobiletopform label{
        display: block;
        background: #ffffff;
        padding: 5px 10px 6px 10px;
    }

    .mobiletopform .nopadding{
        padding: 0;
    }

    .mobiletopform select.form-control {
        position: relative;
        padding: 5px 9px;
        background: #ffffff;
        border: none;
        height: 34px;
    }

    .mobilebooking{
        background: #000000;
        color: #ffffff;
        font-family: "FS Emeric Medium", sans-serif;
    }


    .mobiletopform ::-webkit-input-placeholder {
       color: #000000;
    }

    .mobiletopform :-moz-placeholder { /* Firefox 18- */
        color: #000000;
    }

    .mobiletopform ::-moz-placeholder {  /* Firefox 19+ */
        color: #000000;
    }

    .mobiletopform :-ms-input-placeholder {
        color: #000000;
    }

    .hotelswitch{
        display: block;
        position: relative;
        background: #ffffff;
        padding: 6px 8px;
    }

    .hotelswitch i{
        position: relative;
        top: 2px;
    }


    #hoteswitchDetail{
        position: relative;
        background: #f1f0ee;
        height: 0;
        overflow: hidden;
    }

    #hoteswitchDetail.isOpen{
        height: auto;
    }

    #hoteswitchDetail ul{
        margin: 0;
        padding: 0 15px 15px 15px;
        list-style: none;
    }

    #hoteswitchDetail ul li{
        margin: 0;
        padding: 0;
        list-style: none;
        padding: 9px 0;
        border-bottom: 1px solid #ffffff;
    }

    #hoteswitchDetail ul li span{
        font-family: FS Emeric Light, sans-serif;
        text-transform: uppercase;
        font-size: 16px;
        display: block;
    }

    #hoteswitchDetail ul li span i{
        position: relative;
        top: -1px;
        font-size: 10px;
        margin-right: 5px;
    }

    #hoteswitchDetail ul li span i.icon-minus{
        display: none;
    }

    #hoteswitchDetail ul li.active span{
        font-family: "FS Emeric Medium", sans-serif;
        text-transform: capitalize;
    }

    #hoteswitchDetail ul li.active span i.icon-minus{
        display: inline;
    }

    #hoteswitchDetail ul li.active span i.icon-plus{
        display: none;
    }


    #hoteswitchDetail ul li ul{
        display: none;
    }

    #hoteswitchDetail ul li.active ul{
        display: block;
        padding: 10px 0 0 35px;
    }

    #hoteswitchDetail ul li.active ul li{
        padding: 5px 0;
        border: none;
        text-transform: capitalize;
    }

    #hoteswitchDetail .selected{
        color: #a3211f;
    }

    .toTop-link {
        padding: 1em;
        background: #000;
        color: #fff;
        position: fixed;
        bottom: 1em;
        right: 1em;
        display: none;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 30px;
        height: 30px;
        z-index: 1024;
    }
    .toTop-link:visited,
    .toTop-link:active { color: #fff; }

    .toTop-link i {
        display: block;
        -webkit-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
        transform: rotate(-135deg);
        position: absolute;
        left: calc(50% - 8px);
        top: calc(50% - 6px);
        font-size: 12px;
    }
}

@media screen and (min-width: 768px) {
    .cart-btn {
        box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.5);
    }
    .cart-btn,
    .cart-btn:hover {
        color: #ffffff;
    }
}

.cart-btn {
    position: relative;
    font-size: 24px;
    border: none;
    text-align: center;
    z-index: 100;
}
.cart-btn__count {
    display: block;
    position: absolute;
    top: 10px;
    left: 21px;
    min-width: 17px;
    height: 17px;
    background-color: #aa1500;
    color: #ffffff;
    font-size: 9px;
    border-radius: 50%;
    line-height: 1;
    padding: 4px 3px;
    text-align: center;
    font-family: var(--font-default-bold);
}
.cart-btn__count:empty {
    display: none;
}
@media screen and (min-width: 768px) {
    .cart-btn {
        position: fixed;
        top: 0;
        right: 0;
        background-color: #1e1d1b;
        color: #ffffff;
        width: 60px;
        height: 60px;
        font-size: 27px;
        padding: 13px 0;
    }
    .cart-btn__count {
        top: 15px;
        left: 25px;
    }
}

.login-btn {
    position: relative;
    padding-right: 14px;
    padding-left: 7px;
}
.login-btn__label {
    position: absolute;
    top: 32px;
    right: 3px;
    left: 3px;
    text-transform: uppercase;
    font-family: "FS Emeric Bold", sans-serif;
    text-align: center;
    font-size: 11px;
    line-height: 1.7;
}
@media screen and (min-width: 768px) {
    .login-btn__label {
        display: none;
    }
}

.login-btn__status,
.mobile-nav-btn .login-btn__status {
    font-size: 11px;
    position: absolute;
    top: 10px;
    right: 5px;
    color: #4c880b;
}

.mobile-lang-switcher.mobile-lang-switcher {
    background-color: #4a4a4a;
    color: #ffffff;
}
.mobile-lang-switcher.mobile-lang-switcher li {
    border-top: none;
    border-top: 1px dotted rgba(255,255,255,.4);
    padding-left: 14px;
    padding-right: 14px;
}
.mobile-lang-switcher.mobile-lang-switcher a {
    padding: 5px 0;
    text-transform: none;

}
/*.mobile-lang-switcher.mobile-lang-switcher li:last-child {*/
    /*border-bottom: none;*/
/*}*/