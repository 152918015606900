html {
    font-size: 13px;
}
@media screen and (min-width: 768px) {
    html {
        font-size: 15px;
    }
}
@media screen and (min-width: 1200px) {
    html {
        font-size: 16px;
    }
}
body {
    font-family: FS Emeric Light, sans-serif;
    font-size: 1.1em;
    line-height: 1.7;
    color: var(--color-body-text);
}

h1, .h1 {
    font-size: 36px;
}
@media screen and (max-width: 767px) {
    h1, .h1 {
        margin-top: 0;
    }
}

.h1--xl {
    font-size: 30px;
}
@media screen and (min-width: 768px) {
    .h1--xl {
        font-size: 60px;
    }
}

h2, .h2 {
    font-size: 26px;
}

h3,
.h3{
    line-height: 1.15;
}

h4{
    line-height: 1.5;
}

h5, .h5 {
    font-size: 15px;
    line-height: 1.3;
}

strong,
.strong {
    font-family: "FS Emeric Bold", sans-serif;
    font-weight: normal;
}
.font-medium {
    font-family: var(--font-default-medium);
    font-weight: normal;
}
.font-light {
    font-family: var(--font-default-light);
    font-weight: normal;
}

p {
  margin: 0 0 30px;
}


/*Font-sizes*/
/*.fz15 { font-size: 13px; }*/
/*.fz18 { font-size: 14px; }*/
.fz20 { font-size: 16px; }
/*.fz30 { font-size: 18px; }*/
/*.fz40 { font-size: 22px; }*/
/*.fz80 { font-size: 40px; }*/

@media screen and (min-width: 768px) {
    /*.fz15 { font-size: 14px; }*/
    /*.fz18 { font-size: 16px; }*/
    .fz20 { font-size: 18px; }
    /*.fz30 { font-size: 22px; }*/
    /*.fz40 { font-size: 30px; }*/
    /*.fz80 { font-size: 60px; }*/
}

@media screen and (min-width: 1200px) {
    /*.fz15 { font-size: 15px; }*/
    /*.fz18 { font-size: 18px; }*/
    .fz20 { font-size: 20px; }
    /*.fz30 { font-size: 30px; }*/
    /*.fz40 { font-size: 40px; }*/
    /*.fz80 { font-size: 80px; }*/
}