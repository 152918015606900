.form-control-no-styling {
    background: none;
    border: none;
    padding: 0;
    text-align: right;
    padding-right: 10px;
}

/*Form controls*/
.form-control {
    height: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: left;
}
.form-control {
    font-size: 15px;
}
.form-control,
.form-control:focus {
    border-radius: 0;
    background: #ffffff;
    color: #1e1d1b;
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #d1cec8;
}
.form-control:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
}
.form-control[readonly] {
    cursor: pointer;
    background: #ffffff;
}
.form-control.bg-grey[readonly] {
    background: #f1f0ee !important;
}
.form-control--bg-grey,
.form-control--bg-grey:focus {
    box-shadow: none;
    background-color: #4a4a4a;
    color: #ffffff;
    border: 1px solid transparent;
}
.form-control--bg-grey:focus {
    border: 1px solid rgba(255, 255, 255, .6);
}
.form-control--bg-grey::placeholder {
    color: #ffffff;
}

/*Form group box*/
.form-group-box {
    padding: 10px 10px 10px 20px;
    margin-right: 6px;
}
.form-group-box .control-label {
    margin-right: 20px;
}

.form-control-with-icon {
    position: relative;
}
.form-control-with-icon .form-control {
    padding-right: 40px;
}
.form-control-with-icon__icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    line-height: 0;
}
.form-control-with-icon--left {
    padding-left: 40px;
    padding-right: 0;
}
.form-control-with-icon--left .form-control-with-icon__icon {
    left: 12px;
    right: auto;
}

.form-errors .parsley-errors-list.filled {
    display: block;
    list-style: none;
    margin: 0;
    background: #a81400;
    color: #ffffff;
    padding: 3px 5px;
}

/*form inline*/
.form-inline {
    margin-right: -15px;
}
.form-inline .form-group {
    margin-bottom: 10px;
}
.form-inline .control-label {
    margin-right: 7px;
}
.form-inline .form-control {
    margin-right: 15px;
}

.badges-input {
    height: auto;
    min-height: 40px;
    padding-top: 4px
}
.badges-input .badge {
    margin-top: 4px;
    margin-right: 4px;
}
.badges-input .badge:hover,
.badges-input .badge:focus,
.badges-input .badge:active {
    background: var(--color-primary);
    color: #ffffff;
}

.select-dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
    right: 0;
}
.select-dropdown-menu li > a,
.page-wrapper .select-dropdown-menu li > a {
    color: inherit;
    border-bottom: none;
    display: block;
    padding: 3px 20px;
}
.select-dropdown-menu li > a:hover,
.select-dropdown-menu li > a:focus,
.page-wrapper .select-dropdown-menu li > a:hover,
.page-wrapper .select-dropdown-menu li > a:focus {
    text-decoration: none;
    background-color: #f5f5f5;
}
.select-dropdown-menu .dropdown-header {
    font-size: 1em;
    padding: 0;
    font-family: var(--font-default-medium);
    color: inherit;
}
.select-dropdown-menu ul {
    list-style-type: none;
    padding: 0;
}