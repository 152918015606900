.trust-you {
    color: #2a5caa;
    font-size: 12px;
}
.trust-you__logo {
    display: inline-block;
    width: 53px;
    height: 6px;
    margin-right: 3px;
    background: url("/static/img/logos/trustyou.png") no-repeat center center;
    background-size: contain;
    overflow: hidden;
    text-indent: -999em;
}
.trust-you__score {
    font-family: var(--font-default-bold);
}
.trust-you__text {
    display: none;
}
@media screen and (min-width: 768px) {
    .trust-you:not(.trust-you--small) {
        position: relative;
        height: 40px;
        padding-left: 8px;
        line-height: 1;
    }
    .trust-you:not(.trust-you--small) .trust-you__score {
        font-size: 24px;
        line-height: 1;
    }
    .trust-you:not(.trust-you--small) .trust-you__logo {
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 40px;
        height: 4px;
        margin: 0;
        transform-origin: top left;
        transform: rotate(-90deg);
    }
    .trust-you:not(.trust-you--small) .trust-you__text {
        display: block;
    }
}