.room-panel {
    border-bottom: 1px dotted #c0bdb7;
}
@media screen and (max-width: 767px) {
    .room-panels > .room-panel:last-child {
        border-bottom: none;
    }
    .room-panel-slide {
        padding-bottom: 4px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    }
}
.room-panel__head {
    display: block;
    padding: 10px 0;
    font-size: 12px;
}
.page-wrapper li .room-panel__head {
    border: none;
    color: inherit;
}
@media screen and (min-width: 768px) {
    .room-panel__head {
        font-size: 14px;
    }
}
.room-panel__body {
    padding-bottom: 20px;
}
@media screen and (min-width: 768px) {
    .room-panel__body {
        padding-bottom: 35px;
    }
}
@media screen and (max-width: 767px) {
    .room-panel__content-body {
        padding: 7px 12px 0;
        font-size: 13px;
    }
}

.page-wrapper li a.btn-primary {
    color: #FFF;
}

.room-panel__title {
    font-family: var(--font-default-medium);
    font-size: 14px;
    line-height: calc(18 / 14);
    margin-top: 0;
    margin-bottom: 0;
}
@media screen and (min-width: 768px) {
    .room-panel__title {
        font-family: var(--font-default-light);
        font-size: 32px;
    }
}
@media screen and (min-width: 768px) {
    .room-panel__price {
        font-size: 20px;
    }
}
.room-panel__collapse-icon {
    font-size: 7px;
    color: #8c0f00;
    transform: rotate(180deg);
    transition: transform 120ms ease-out;
}
@media screen and (min-width: 768px) {
    .room-panel__collapse-icon {
        font-size: 10px;
    }
}
.room-panel__head.collapsed .room-panel__collapse-icon {
    transform: none;
}
.room-panel__head:not(.collapsed) .room-panel__head-img {
    display: none;
}