.number-slider {
    position: relative;
    font-size: 0; /*remove whitspace*/
    white-space: nowrap;
    border: 1px solid #d1cec8;
}
.number-slider__item {
    display: inline-block;
    min-width: 34px;
    height: 36px;
    padding: 5px;
    color: #1e1d1b;
    border: none;
    border-right: 1px solid #d1cec8;
    border-left: 1px solid transparent; /*needed otherwise slicks center calculation is off*/
    font-size: 15px;
    font-family: var(--font-default-light);
    text-align: center;
    background: none;
}
/*.number-slider__item + .number-slider__item {*/
    /*border-left-color: transparent; !* if border-left is set to none slicks center calculation is wrong*!*/
/*}*/
.number-slider__item.slick-center {
    background-color: #eae8e4;
}
.number-slider__arrow {
    position: absolute;
    top: -1px; /*border */
    left: 50%;
    transform: translateX(-50%);
}
.number-slider__arrow:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7px 0 7px;
    border-color: #d1cec8 transparent transparent transparent;
}
.number-slider__arrow:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #ffffff transparent transparent transparent;
}

/*.number-slider:after {*/
    /*content: '';*/
    /*position: absolute;*/
    /*top: 0;*/
    /*bottom: 0;*/
    /*left: 50%;*/
    /*margin-left: -1px;*/
    /*width: 2px;*/
    /*background: red;*/
/*}*/

.number-slider .slick-track {
    min-width: 100%;
    white-space: nowrap;
}
.number-slider .slick-slide {
    display: inline-block;
    float: none;
}