/*.number-spinner {*/
    /*!*display: block*!*/
/*}*/

.number-spinner__input.number-spinner__input {
    min-width: 35px;
    text-align: center;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    padding: 3px;
    box-shadow: none;
    border: none;
    font-size: 14px;
    height: 34px;
}
.number-spinner__input::-webkit-inner-spin-button,
.number-spinner__input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.number-spinner__button {
    font-size: 15px;
    padding: 7px 17px;
}