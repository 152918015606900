.btn {
    border-radius: 0;
    outline:0 !important;
    border: none;
    padding-top: 8px;
    padding-bottom: 8px;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background: linear-gradient(to bottom, rgba(170,21,0,1) 0%,rgba(140,15,0,1) 100%); /* W3C */
}
.btn-primary:active {
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
}
.btn-default {
    background-color: #eae8e4;
}
.btn-no-styling {
    height: auto;
    padding: 0;
    margin-bottom: 0;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: transparent;
    border: none;
    outline:0 !important;
}
.btn-ghost {
    border: 1px solid #8c0f00;
    color: #8c0f00;
    font-family: var(--font-default-medium);
    font-size: 16px;
    background: transparent;
}
.btn-ghost:hover {
    background: #8c0f00;
    color: #ffffff;
}
.btn-ghost-static,
.btn-ghost-static:hover,
.btn-ghost-static:focus,
.btn-ghost-static:active {
    display: inline-block;
    padding: 8px 12px;
    border: 1px solid #8c0f00;
    color: #8c0f00;
    font-family: var(--font-default-medium);
    font-size: 16px;
    background: transparent;
    cursor: pointer;
}
.btn__icon {
    margin-right: 3px;
    vertical-align: -.1em;
}
.btn__icon--right {
    margin-right: 0;
    margin-left: 3px;
}
.btn__icon.icon-check {
    font-size: 13px;
    vertical-align: baseline;
}
.btn__icon.icon-pin {
    font-size: 18px;
}
@media screen and (max-width: 767px) {
    .btn-block-on-xs {
        display: block;
        white-space: normal;
        width: 100%;
    }
    .btn-block-on-xs + .btn-block-on-xs {
        margin-top: 12px;
    }
}
.btn-lg {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1.33;
}
@media screen and (max-width: 767px) {
    .btn-lg {
        font-size: 17px;
    }
}

.btn-bigger-click-area {
    padding: 10px;
    margin: -10px;
}

@media screen and (max-width: 767px) {
    .facebook-btn {
        display: block;
    }
    .facebook-btn img {
        display: block;
        width: 100%;
        height: auto;
    }
}