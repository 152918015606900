html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content*/
}
body {
    position: relative;
}
.container.container {
    /*max-width: 970px;*/
    width: 1050px;
    max-width: 100%;
}
.container--narrow.container--narrow {
    width: 750px;
}
.container--wide.container--wide {
    width: 1280px;
}
@media screen and (max-width: 767px) {
    .container.container {
        padding-left: 12px;
        padding-right: 12px;
    }
    .container--full-width-on-xs.container--full-width-on-xs {
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    body {
        width: 982px;
    }
}
@media screen and (max-width: 767px) {
    body {
        min-width: 320px;
        max-width: 100%;
        overflow-x: hidden;
    }
}

/*:root {
    --base-layout-sidebar-width: 250px;
    --base-layout-sidebar-height-mobile: 50px;
    --base-layout-sidebar-width--mq-1500: 280px;
}*/
.page-header-bar {
    display: none;
}
@media screen and (min-width: 768px) {
    .page-wrapper {
        position: relative;
        padding-left: 220px;
    }
    .page-header-bar {
        display: block;
        position: fixed;
        top: 0;
        left: 250px;
        right: 0;
        background: #ffffff;
        z-index: 100;
        transform: translateZ(0);
    }
    .page-wrapper--no-header-img {
        padding-top: 140px;
    }
}
@media screen and (min-width: 1200px){
    .page-wrapper {
        padding-left: 280px;
    }
}
@media screen and (min-width: 1500px) {
    .page-wrapper {
        padding-left: 280px;
        max-width:1920px;
    }
    .page-header-bar {
        left: 280px;
        max-width:1640px;
    }
}
@media screen and (min-width: 1600px) {
    .page-wrapper--no-header-img {
        padding-top: 100px;
    }
}
.page-wrapper.page-wrapper--no-sidebar {
    padding-left: 0;
}

.breadcrumb-wrap{
    position: relative;
    top: 0;
    left: 0;
    height: 60px;
    background: #f6f4ef;
    padding-left: 255px;
}
@media screen and (min-width: 768px) {
    .page-wrapper--no-header-img .breadcrumb-wrap {
        position: absolute;
        left: 170px;
        padding-left: 230px;
    }
}
@media screen and (min-width: 1200px) {
    .page-wrapper--no-header-img .breadcrumb-wrap {
        padding-left: 255px;
    }
}

.breadcrumb-wrap--small-border {
    padding-left: 150px;
}