.hotel-overview {
    padding-top: 20px;
    padding-bottom: 20px;
}
@media screen and (min-width: 768px) {
    .hotel-overview {
        padding-top: 100px;
        padding-bottom: 40px;
    }
}
@media screen and (min-width: 1400px) {
    .hotel-overview {
        padding-top: 180px;
        padding-bottom: 60px;
    }
}

.hotel-overview__list-wrapper {
    position: relative;
}
@media screen and (max-width: 767px) {
    .hotel-overview__list-wrapper {
        margin-bottom: 60px;
        padding: 0 15px 0 5px;
    }
}

/*.hotel-overview__list-wrapper::after {*/
/*    content: '';*/
/*    position: absolute;*/
/*    inset: auto 0 -30px 0;*/
/*    border-bottom: 1px dotted var(--color-primary);*/
/*}*/
@media screen and (min-width: 768px) {
    .hotel-overview__list-wrapper + .hotel-overview__list-wrapper {
        margin-top: 50px;
    }
    .hotel-overview__list-wrapper + .hotel-overview__list-wrapper:after {
        content: '';
        position: absolute;
        inset: -25px 0 auto 0;
        border-bottom: 1px dotted var(--color-primary);
    }
}
@media screen and (min-width: 1400px) {
    .hotel-overview__list-wrapper + .hotel-overview__list-wrapper {
        margin-top: 100px;
    }
    .hotel-overview__list-wrapper + .hotel-overview__list-wrapper:after {
        inset: -50px 0 auto 0;
    }
}

.hotel-overview__list-title {
    font-family: "FS Emeric Bold", sans-serif;
    font-size: 26px;
    color: var(--color-primary);
    text-decoration: underline;
    text-transform: uppercase;
    line-height: calc(32/26);
}
@media screen and (min-width: 768px) {
    .hotel-overview__list-title {
        font-family: "FS Emeric Medium", sans-serif;
    }
}
@media screen and (min-width: 1400px) {
    .hotel-overview__list-title {
        font-size: 31px;
        line-height: calc(38/31);
    }
}

.hotel-overview__brand-title {
    font-size: 26px;
    line-height: calc(32/26);
    margin-top: 20px;
    font-family: "FS Emeric Medium", sans-serif;
    display: flex;
    align-items: center;
    column-gap: 17px;
}
@media screen and (max-width: 767px) {
    .hotel-overview__brand-title {
        font-size: 20px;
        margin-top: 16px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1599px) {
    .hotel-overview__brand-title {
        font-size: 18px;
        margin-top: 14px;
        column-gap: 10px;
    }
}
.hotel-overview__brand-indicator {
    height: 20px;
    width: 20px;
    border-radius: 50%;
}
@media screen and (max-width: 767px) {
    .hotel-overview__brand-indicator {
        height: 16px;
        width: 16px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1599px) {
    .hotel-overview__brand-indicator {
        height: 14px;
        width: 14px;
    }
}
.hotel-overview__brand-indicator-ath {
    background-color: var(--color-ath);
}
.hotel-overview__brand-indicator-bassena {
    background-color: var(--color-hilton);
}
.hotel-overview__brand-indicator-radisson-individuals {
    background-color: var(--color-radisson-individuals);
}
.hotel-overview__brand-indicator-radisson-blu {
    background-color: var(--color-radisson-blu);
}
.hotel-overview__brand-indicator-camping {
    background-color: var(--color-camping);
}
.hotel-overview__brand-indicator-hostels {
    background-color: var(--color-hostels);
}
.hotel-overview__brand-indicator-palais-events {
    background-color: var(--color-palais-events);
}
.hotel-overview__brand-indicator-astoria {
    background-color: var(--color-astoria);
}
.hotel-overview__brand-indicator-hilton {
    background-color: var(--color-hilton);
}

.hotel-overview__list {
    margin-top: 4px;
}
.hotel-overview__link {
    color: black !important;
    font-size: 18px;
    line-height: calc(26/18);
    text-decoration: none;
    display: flex;
    align-items: flex-start;
    border-bottom: none !important;
    column-gap: 18px;
    font-family: "FS Emeric Light", sans-serif;
}
@media screen and (min-width: 768px) {
    .hotel-overview__link {
        border-bottom: none !important;
        column-gap: 8px;
        font-size: 16px;
    }
}
@media screen and (min-width: 1600px) {
    .hotel-overview__link {
        font-size: 21px;
        line-height: calc(29/21);
        column-gap: 16px;
    }
}

.hotel-overview__link-icon {
    font-size: 14px;
    flex-shrink: 0;
    margin-top: 6px;
    transform: translateX(0);
    transition: transform 300ms ease;
}
@media screen and (min-width: 768px) {
    .hotel-overview__link-icon {
        font-size: 12px;
        margin-top: 6px;
    }
}
@media screen and (min-width: 1600px) {
    .hotel-overview__link-icon {
        font-size: 14px;
        margin-top: 9px;
    }
}

.hotel-overview__link:hover .hotel-overview__link-icon {
    transform: translateX(6px);
}

.hotel-overview__map-wrapper {
    position: relative;
    margin-top: 30px;
}
@media screen and (min-width: 768px) {
    .hotel-overview__map-wrapper {
        margin-top: 70px;
    }
}
@media screen and (max-width: 767px) {
    .hotel-overview__map-wrapper .infoBox {
        display: block;
    }
}

.hotel-overview__map-wrapper .infoBox,
.hotel-overview__map-wrapper .infoBox:focus,
.hotel-overview__map-wrapper .infoBox:focus-visible,
.hotel-overview__map-wrapper .infoBox:focus-within,
.hotel-overview__info-card,
.hotel-overview__info-card:focus,
.hotel-overview__info-card:focus-within,
.hotel-overview__info-card:focus-visible {
    outline: none;
    border: none;
}

.hotel-overview__map {
    display: block;
    width: 100%;
    height: 490px;
}
@media screen and (min-width: 768px) {
    .hotel-overview__map {
        height: 638px;
    }
}

#hotelOverviewMap {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 490px;
}
@media screen and (min-width: 768px) {
    #hotelOverviewMap {
        height: 638px;
    }
}

.hotel-overview__filter {
    position: absolute;
    top: 0;
    left: 0;
    width: 280px;
    display: flex;
    flex-direction: column;
}
@media screen and (min-width: 768px) {
    .hotel-overview__filter {
        width: 300px;
        top: auto;
        left: auto;
        inset: 0 auto auto 0;
    }
}
.hotel-overview__filter-title {
    font-family: 'FS Emeric Medium', sans-serif;
    text-transform: uppercase;
    font-size: 18px;
    border: none;
    padding: 14px 20px;
    line-height: 1;
    width: auto;
    text-align: left;
    display: flex;
    align-items: center;
    column-gap: 14px;
    background-color: transparent;
}
@media screen and (min-width: 768px) {
    .hotel-overview__filter-title {
        width: 100%;
        background-color: rgba(255,255,255,.9);
        padding: 20px;
    }
}

.hotel-overview__filter-checkboxes {
    padding: 27px 23px;
    background-color: rgba(255,255,255,.9);
    height: 100%;
}
@media screen and (min-width: 768px) {
    .hotel-overview__filter-checkboxes {
        padding: 20px;
        border-top: 1px dotted var(--color-primary);
    }
}

.hotel-overview__filter-close-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: transparent;
    border: none;
    padding: 7px;
    line-height: 1;
}

.hotel-overview__checkbox {
    display: flex;
    align-items: center;
    column-gap: 16px;
    margin-bottom: 10px;
}

.hotel-overview__checkbox:hover {
    cursor: pointer;
}

.hotel-overview__checkbox-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.hotel-overview__checkmark {
    width: 24px;
    height: 24px;
    border-radius: 0;
    margin: 0;
    border: 1px solid #707070;
    position: relative;
}

.hotel-overview__checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 8px;
    top: 0;
    width: 6px;
    height: 18px;
    border: solid #1E1D1B;
    border-width: 0 1px 1px 0;
    transform: rotate(52deg) skewX(15deg);
}

.hotel-overview__checkbox-input:checked ~ .hotel-overview__checkmark:after {
    display: block;
}

.hotel-overview__color-indicator {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
}

.hotel-overview__color-indicator--ath {
    background-color: var(--color-ath);
}
.hotel-overview__color-indicator--bassena {
    background-color: var(--color-hilton);
}
.hotel-overview__color-indicator--radisson-individuals {
    background-color: var(--color-radisson-individuals);
}
.hotel-overview__color-indicator--radisson-blu {
    background-color: var(--color-radisson-blu);
}
.hotel-overview__color-indicator--camping {
    background-color: var(--color-camping);
}
.hotel-overview__color-indicator--hostels {
    background-color: var(--color-hostels);
}
.hotel-overview__color-indicator--palais-events {
    background-color: var(--color-palais-events);
}
.hotel-overview__color-indicator--astoria {
    background-color: var(--color-astoria);
}
.hotel-overview__color-indicator--hilton {
    background-color: var(--color-hilton);
}

.hotel-overview__checkbox-text {
    font-family: 'FS Emeric Medium', sans-serif;
    display: flex;
    align-items: center;
    column-gap: 6px;
    font-size: 18px;
}

.hotel-overview__info-card {
    position: relative;
    background: var(--color-ath);
    padding: 20px 18px 24px;
    border-radius: 8px;
    color: white;
    width: 260px;
}

.hotel-overview__info-card:before {
    position: absolute;
    top: 180px;
    left: -20px;
    content: '';
    display: block;
    width: 45px;
    height: 45px;
    background: var(--color-ath);
    z-index: -1;
    transform: rotate(135deg) skew(15deg, 15deg);
}

.hotel-overview__info-card--bassena, .hotel-overview__info-card--bassena:before,
.hotel-overview__info-card--hilton, .hotel-overview__info-card--hilton:before {
    background: var(--color-hilton);
}

.hotel-overview__info-card--camping, .hotel-overview__info-card--camping:before {
    background: var(--color-camping);
}

.hotel-overview__info-card--hostels, .hotel-overview__info-card--hostels:before {
    background: var(--color-hostels);
}

.hotel-overview__info-card--palais-events, .hotel-overview__info-card--palais-events:before {
    background: var(--color-palais-events);
}

.hotel-overview__info-card--radisson-blu, .hotel-overview__info-card--radisson-blu:before {
    background: var(--color-radisson-blu);
}

.hotel-overview__info-card--radisson-individuals, .hotel-overview__info-card--radisson-individuals:before {
    background: var(--color-radisson-individuals);
}

.hotel-overview__info-card--astoria, .hotel-overview__info-card--astoria:before {
    background: var(--color-astoria);
}

.hotel-overview__info-card--hilton, .hotel-overview__info-card--astoria:before {
    background: var(--color-hilton);
}

.hotel-overview__info-card-close-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: transparent;
    border: none;
    font-size: 20px;
    z-index: 10;
    cursor: pointer;
    padding: 0;
}
.hotel-overview__info-card-title {
    font-family: 'FS Emeric Light', sans-serif;
    font-size: 23px;
    line-height: calc(26/23);
    letter-spacing: 0;
    margin-top: 12px;
}

.hotel-overview__info-card-cta {
    font-family: 'FS Emeric Light', sans-serif;
    font-size: 18px;
    margin-bottom: 4px;
}

.hotel-overview__info-card-icon {
    font-size: 13px;
    margin-right: 12px;
    transform: translateX(0);
    transition: transform 300ms ease;
}

.hotel-overview__info-card-cta:hover .hotel-overview__info-card-icon {
    transform: translateX(4px);
}

.hotel-overview__nav-wrapper {
    max-width: calc(100vw - 280px);
    position: relative;
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
    .hotel-overview__nav-wrapper .closeHotelMainNav {
        position: absolute;
    }
}
@media screen and (min-width: 1400px) {
    .hotel-overview__nav-wrapper {
        padding-left: 100px;
    }
}