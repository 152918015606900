.cart-box {
    background: #ffffff;
}
.cart-box__body {
    padding: 13px 25px;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
}
.cart-box__title {
    font-family: var(--font-default-bold);
    font-size: 14px;
    text-transform: uppercase;
    margin: 10px 0 10px -12px;
}
@media screen and (min-width: 768px) {
    .cart-box__title {
        margin-left: 0;
        font-size: 16px;
    }
}