.loading-spinner-container {
    min-height: 5em;
}
.loading-spinner,
.loading-spinner:before,
.loading-spinner:after {
    background: #8c0f00;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
}
.loading-spinner:before,
.loading-spinner:after {
    position: absolute;
    top: 0;
    content: '';
}
.loading-spinner:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.loading-spinner {
    color: #8c0f00;
    text-indent: -9999em;
    margin: 38px auto 10px;
    position: relative;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
@media screen and (min-width: 768px) {
    .loading-spinner {
        margin: 88px auto;
    }
}
.loading-spinner:after {
    left: 1.5em;
}
@-webkit-keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}
@keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

.loading-spinner__screen-container {
    position: relative;
    min-height: 40vh;
}
.loading-spinner__screen {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.btn-with-loading-spinner .loading-spinner__screen {
    position: fixed;
    background: rgba(0,0,0,.5);
    z-index: 100;
    opacity: 0;
    display: none;
    cursor: auto;
}
.btn-with-loading-spinner .loading-spinner-container {
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
}
.btn-with-loading-spinner.is-loading .loading-spinner__screen {
    display: block;
    opacity: 1;
}