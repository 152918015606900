@font-face {
    font-family: 'FS Emeric Bold';
    src: url('/static/fonts/fs_emeric-bold-webfont.woff2') format('woff2'),
    url('/static/fonts/fs_emeric-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: 'FS Emeric Light';
    src: url('/static/fonts/fs_emeric-light-webfont.woff2') format('woff2'),
    url('/static/fonts/fs_emeric-light-webfont.woff') format('woff');
    font-weight: bold;
    font-display: swap;
}
@font-face {
    font-family: 'FS Emeric Medium';
    src: url('/static/fonts/fs_emeric-medium-webfont.woff2') format('woff2'),
    url('/static/fonts/fs_emeric-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}