#pc-cookie-notice {
    background: rgba(0,0,0,0.3)!important;
    bottom: inherit!important;
    top: 0;
}

input:focus,
select:focus,
a:focus{
  border: none;
  box-shadow: none;
  outline: 0;
}
.form-control:focus{
    outline: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

b,
strong {
    font-family: "FS Emeric Medium", sans-serif;
}

h1.red{
    color: #8c0f00;
}

.margin-bottom-40{
    margin-bottom: 40px;
}

.margin-bottom-45{
    margin-bottom: 45px;
}

section.top-account,
section.contact-slide-hotelkontakt,
section.seminarraeume,
section.downloadcenter,
section.big-gallery,
section.top-text,
section.raumspecials,
section.pauschale-more-hotel,
section.pauschal-detail,
section.pauschalenliste,
section.restaurants,
section.top-restaurant-content,
section.zimmerdetails,
section.text-mit-infobox,
section.zitat,
section.hotelausstattungdetail,
section.zitat-beige,
section.text,
section.breiter-teaser,
section.mosaik,
section.contact-slide,
section.booking-bar-portal,
section.top-slider,
section.fullslider,
.wetter-slide,
section.starttable,
section.distanz-map,
section.corporate-booking,
body.portal section.hotel-standard,
section.dreier-teaser{
    margin-bottom: 35px;
    position: relative;
}
section.big-gallery{
    margin-bottom: 0;
    position: relative;
}

.page-wrapper section.text .col-sm-6 p a{
    border-bottom: none;
}



section.top-account{
    background: #ffffff;
    padding-top: 100px;
    padding-bottom: 40px;
    min-height: 57vh;
}


section.top-account h3{
    margin: 0 0 5px 0;
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 18px;
}



section.top-account .bonuststufen{
    position: relative;
}

section.top-account .bonuststufen .bonusstufe{
    position: relative;
    float: left;
    display: block;
    width: 40px;
    height: 40px;
    border: 2px solid #ffffff;
    background: #dfdcd6;
    margin-right: 4px;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 1;
}

section.top-account .bonuststufen .bonusstufe span{
    display: block;
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: relative;
    left: 7px;
    top: -6px;
    font-size: 17px;
    font-family: FS Emeric Light, sans-serif;
}


section.top-account .bonuststufen .bonusstufe.active{
    z-index: 2;
    border: 3px solid #aa1500;
    width: 60px;
    height: 60px;
    background: #fff;
    top: -10px;
    margin: 0 10px 0 6px;
}

section.top-account .bonuststufen .bonusstufe.active span{
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 24px;
    color: #aa1500;
    left: 11px;
    top: -8px;
}

.red-link{
    color: #aa1500;
    font-size: 16px;
    font-family: "FS Emeric Medium", sans-serif;
}


.red-link i{
    position: relative;
    font-size: 14px;
    margin-right: 1px;
    top: 1px;
}


table.edittable tr td{
    height: 48px;
}




section.text-mit-infobox .infobox {
    background: #eae8e3;
    padding: 30px;
}

section.text-mit-infobox .infobox .tophead {
    text-transform: uppercase;
    font-size: 20px;
    font-family: "FS Emeric Medium", sans-serif;
    margin-bottom: 10px;
}

section.text-mit-infobox .infobox .zahl {
    color: #8c0f00;
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 24px;
    line-height: 22px;
}

section.text-mit-infobox .infobox .row{
    margin-bottom: 15px;
}

.infobox .rowblock_0{
    border-bottom: 1px dotted #000000;
}

section.bigimage{
    position: relative;
    width: 100%;
    height: 530px;
    /*background: url('http://lorempixel.com/1400/530/fashion/') no-repeat center center;*/
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    overflow: hidden;
}

section.bigimage .container{
    padding-top: 95px;
}

section.bigimage h2{
    position: relative;
    color: #ffffff;
    font-size: 36px;
    font-family: "FS Emeric Medium", sans-serif;
    margin: 0 0 30px 0;
    padding-top: 20px;
    z-index: 2;
}

section.bigimage h2:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: #ffffff;
    width: 70px;
    height: 3px;
    border-radius: 1px;
}

section.bigimage p{
    position: relative;
    width: 330px;
    color: #ffffff;
    font-size: 18px;
    font-family: "FS Emeric Medium", sans-serif;
    margin: 0 0 40px 0;
    z-index: 2;
}

section.bigimage .red-button{
    position: relative;
    z-index: 2;
}

.red-button{
    display: inline;
    background: linear-gradient(to left, #991200 50%, #7A0E00 50%) ;
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all 1s ease;
    color: #ffffff!important;
    font-size: 16px!important;
    font-family: FS Emeric Light, sans-serif!important;
    padding: 10px 25px!important;
    border: none;
}

.red-button-register{
    display: inline-block;
    background: #991200!important;
    color: #ffffff!important;
    font-size: 16px!important;
    font-family: FS Emeric Light, sans-serif!important;
    padding: 13px 25px 10px 25px;
    position: relative;
    margin-right: 10px;
    margin-bottom: 15px;
    float: left;
}

.red-button:hover{
    background-position: left bottom;
}

.white-button{
    display: inline;
    background: linear-gradient(to left, #3f3f3f 50%, #000000 50%) ;
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all 1s ease;
    color: #ffffff!important;
    font-size: 16px!important;
    font-family: FS Emeric Light, sans-serif!important;
    padding: 9px 25px!important;
    border: 1px solid white;
    text-decoration: none !important;
}
.white-button:hover{
    background-position: left bottom;
}

.red-button-block{
    display: block;
    width: 100%;
    color: #ffffff!important;
    font-size: 16px;
    font-family: FS Emeric Light, sans-serif;
    padding: 10px 25px;
    text-align: center;
    background: #9b1200;
    background: -moz-linear-gradient(top,  #9b1200 0%, #8d1000 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#9b1200), color-stop(100%,#8d1000));
    background: -webkit-linear-gradient(top,  #9b1200 0%,#8d1000 100%);
    background: -o-linear-gradient(top,  #9b1200 0%,#8d1000 100%);
    background: -ms-linear-gradient(top,  #9b1200 0%,#8d1000 100%);
    background: linear-gradient(to bottom,  #9b1200 0%,#8d1000 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9b1200', endColorstr='#8d1000',GradientType=0 );
}

.red-button-block:hover{
    background: #8d1000;
    background: -moz-linear-gradient(top,  #8d1000 0%, #9b1200 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#8d1000), color-stop(100%,#9b1200));
    background: -webkit-linear-gradient(top,  #8d1000 0%,#9b1200 100%);
    background: -o-linear-gradient(top,  #8d1000 0%,#9b1200 100%);
    background: -ms-linear-gradient(top,  #8d1000 0%,#9b1200 100%);
    background: linear-gradient(to bottom,  #8d1000 0%,#9b1200 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8d1000', endColorstr='#9b1200',GradientType=0 );

}



.black-button,
.black-button-block{
    display: block;
    width: 100%;
    color: #ffffff!important;
    font-size: 16px;
    font-family: FS Emeric Light, sans-serif;

    padding: 10px 25px;
    text-align: center;
    border: none;

    background: #1e1d1b;
}

.black-button{
    display: inline-block;
    width: auto;
}

/*.black-button,
.black-button-block:hover{
    background: #1e1e1c;
    background: -moz-linear-gradient(top,  #1e1e1c 0%, #33312e 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#1e1e1c), color-stop(100%,#33312e));
    background: -webkit-linear-gradient(top,  #1e1e1c 0%,#33312e 100%);
    background: -o-linear-gradient(top,  #1e1e1c 0%,#33312e 100%);
    background: -ms-linear-gradient(top,  #1e1e1c 0%,#33312e 100%);
    background: linear-gradient(to bottom,  #1e1e1c 0%,#33312e 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e1e1c', endColorstr='#33312e',GradientType=0 );
}*/

.black-button:hover{
    background: #292927;
}

section.zitat-beige,
section.zitat{
    background: #9b8868 url('/static/img/pattern_gold.png');
    background-size: 40px 38px;
    min-height: 280px;
    padding: 70px 0 60px 0;
    text-align: center;
}

section.zitat-beige p,
section.zitat p{
    text-align: center;
    color: #ffffff;
    font-size: 36px;
    font-family: FS Emeric Light, sans-serif;
    margin: 0 auto 36px auto;
    line-height: 42px;
    width: 100%;
}

section.zitat-beige span,
section.zitat span{
    display: block;
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    font-family: FS Emeric Light, sans-serif;
    margin: 0 auto;
    width: 70%;
}

section.dreier-teaser{

}

section.zimmerdetails .top-headline,
section.dreier-teaser .top-headline{
    font-size: 36px;
}

section.zimmerdetails .top-link,
section.dreier-teaser .top-link{
    color: #8c0f00;
    font-size: 16px;
    font-family: "FS Emeric Medium", sans-serif;
}

section.zimmerdetails .topspace,
section.dreier-teaser .topspace{
    padding-top: 21px;
}

section.dreier-teaser figure{
    margin-bottom: 10px;
    overflow: hidden;
}

section.dreier-teaser a{
    display: block;
    color: #8c0f00;
    font-size: 24px;
    font-family: "FS Emeric Medium", sans-serif;
    line-height: 26px;
    margin-bottom: 19px;
}

section.dreier-teaser a.bookinglink{
    position: absolute;
    bottom: 10px;
    right: 5px;
    margin: 0;
    font-size: 18px;
}

section.dreier-teaser .dreierTeaser{
    position: relative;
    margin-bottom: 40px;
    padding-bottom: 10px;
    height: 30em;
    transition: all .4s ease-out;
}

section.dreier-teaser .dreierTeaser:after,
section.dreier-teaser .breiterTeaser:after{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    border-bottom: 1px dotted #000000;
    transition: all .4s ease-out;
}
section.dreier-teaser .breiterTeaser:after{
    left: 15px;
    right:15px;
}


section.dreier-teaser .dreierTeaser:hover *{
    color: #aa1500;
}


section.dreier-teaser .dreierTeaser .img-responsive{
    transition: all .4s ease-out;
}


section.dreier-teaser .dreierTeaser:hover .img-responsive{
    transform: scale(1.02);
}

section.dreier-teaser .dreierTeaser:hover:after,
section.dreier-teaser .breiterTeaser:hover:after{
    bottom: 5px;
}


section.dreier-teaser p{
    line-height:1.4;
}

section.dreier-teaser p.info{
    margin: 0;
}

section.dreier-teaser span{
    color: #8c0f00;
    font-size: 24px;
    font-family: "FS Emeric Medium", sans-serif;
    position: absolute;
    bottom: 5px;
}

section.dreier-teaser .dreierTeaser.mitarbeiter h2{
    color: #8c0f00;
    font-size: 24px;
    font-family: "FS Emeric Medium", sans-serif;
}

section.dreier-teaser .dreierTeaser.mitarbeiter p{
    margin: 0 0 5px 0;
}

section.dreier-teaser .dreierTeaser.mitarbeiter a{
    color: #000000;
    font-size: 16px;
    font-family: FS Emeric Light, sans-serif;
    border:0;
}


section.dreier-teaser .breiterTeaser{
    position: relative;
    display: inline-block;
    width:100%;
    border:0 !important;
}
section.dreier-teaser .breiterTeaser span{
    position: relative;
    bottom:auto;
}
section.dreier-teaser .breiterTeaser h3 a {
    font-size: 16px;
}
section.dreier-teaser .breiterTeaser p {
    line-height:1.7;
}
section.dreier-teaser .breiterTeaser a.bookinglink{
    margin-top:5px;
    position: relative;
    bottom: auto;
    right: auto;
    font-size: 18px;
}


.breiterTeaser span.price {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #8c0f00;
    font-size: 16px;
    font-family: FS Emeric Medium, sans-serif;
}


section.mosaik{

}

section.mosaik h3{
    font-family: FS Emeric Light, sans-serif;
    font-size: 36px;
    font-weight: normal;
    margin:15px 0 20px 0;
}

section.mosaik h4{
    margin: 0;
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 20px;
}

section.mosaik .no-rightpadding-desktop{
    padding-right: 0;
}

section.mosaik .no-leftpadding-desktop{
    padding-left: 0;
}

section.mosaik .redblock{
    min-height: 313px;
    overflow: hidden;
    color: #ffffff;
}

section.mosaik .mosaik__text {
    padding: 13px 24px 9px;
}

@media screen and (max-width: 767px) {
    section.mosaik .mosaik__image {
        width: 100%;
    }
}

section.mosaik .redblock span{
    font-family: FS Emeric Light, sans-serif;
    font-size: 18px;
    display: block;
}

section.mosaik .bgcontainer{
    background: #8c0f00 url('/static/img/pattern_red.png');
    background-size: 40px 38px;
}

section.distanz-map{
    position: relative;
}

section.distanz-map .distanz{
    background: #eae8e4;
    height: 440px;
}

section.distanz-map .hotelinfo .distanz{
    padding: 0;
    height: auto;
}

section.distanz-map .col-sm-6,
section.distanz-map .col-sm-8{
    padding: 0;
}

section.distanz-map .distanz{
    padding: 40px;
}

section.distanz-map .distanz h3 {
  margin: 0;
  font-family: FS Emeric Light, sans-serif;
  font-size: 36px;
  font-weight: 400;
  margin-bottom: 36px;
}

section.distanz-map .distanz table{
    width: 100%;
}

section.distanz-map .distanz table tr{
    border-bottom: 1px dotted #d2cec6;
}

section.distanz-map .distanz table tr th{
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 18px;
    color: #8c0f00;
    font-weight: normal;
    width: 102px;
    padding: 10px 0;
}

section.distanz-map .distanz table tr td{
    padding: 9px 0;
}




section.distanz-map #distanzMap{
    position: relative;
    width: 100%;
    height: 440px;
    background: #eae8e4;
    box-shadow: inset 3px 3px 10px 1px #c2c2c2;
}

section.distanz-map #distanzMap:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 7px;
    background: url('/static/img/mapshadow-top.png');
}

section.distanz-map .gm-style:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 7px;
    height: 100%;
    background: url('/static/img/mapshadow-left.png');
}

.kontakt-transit,
section.distanz-map #showTransit{
    position: absolute;
    left: 67px;
    bottom: 25px;
    background: #eae8e4;
    padding: 7px;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid #666;
    height: 42px;
}

.kontakt-transit:before,
section.distanz-map #showTransit:before{
    content: '';
    background: #ffffff;
    width: 42px;
    height: 42px;
    position: absolute;
    top: -1px;
    left: -42px;
    border: 1px solid #666;
}
.kontakt-transit:after,
section.distanz-map #showTransit:after{
    display: none;
}
.kontakt-transit.on:after,
section.distanz-map #showTransit.on:after{
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
    content: "\E04E";
    position: absolute;
    top: 13px;
    left: -33px;
    display: block;
}

.map-route-btn {
    position: absolute;
    bottom: 80px;
    left: 26px;
    width: 201px;
    text-align: left;
    background: #8c0f00;
    height: 42px;
    font-size: 16px;
    padding-top: 9px;
}
@media screen and (max-width: 767px) {
    .map-route-btn {
        position: static;
    }
}
.map-route-btn__icon {
    font-size: 1.2em;
    vertical-align: -0.2em;
    margin-right: 12px;
}

section.distanz-map .mapControls,
.top-map .mapControls{
    position: absolute;
    top: 25px;
    right: 58px;
    width: 35px;
    height: 72px;
}

section.distanz-map .mapControls .in,
.top-map .mapControls .in{
    position: relative;
    background: #ffffff;
    width: 35px;
    height: 35px;
    margin-bottom: 2px;
    cursor: pointer;
}

section.distanz-map .mapControls .out,
.top-map .mapControls .out{
    position: relative;
    background: #ffffff;
    width: 35px;
    height: 35px;
    cursor: pointer;
}

section.distanz-map .mapControls i,
.top-map .mapControls i{
    position: absolute;
    top: 9px;
    left: 8px;
}

.distance-map__info-window p { margin-bottom: 0; }
.distance-map__info-window a { color: #337ab7; }

.top-map .mapNavigation{
    position: absolute;
    top: 99px;
    right: 95px;
    width: 35px;
    height: 72px;
}

.top-map .mapNavigation div{
    position: absolute;
    background: #ffffff;
    width: 35px;
    height: 35px;
    margin-bottom: 2px;
    cursor: pointer;
}

.top-map .mapNavigation .top{
    top: 0;
    left: 37px;
}

.top-map .mapNavigation .bottom{
    top: 74px;
    left: 37px;
}

.top-map .mapNavigation .left  {
    top: 37px;
    left: 0;
}

.top-map .mapNavigation .right  {
    top: 37px;
    left: 74px;
}

.top-map .mapNavigation .top i{
    top: 8px;
    left: 13px;
    position: absolute;
}

.top-map .mapNavigation .bottom i{
    top: 8px;
    left: 13px;
    position: absolute;
}

.top-map .mapNavigation .left i {
    position: absolute;
    top: 8px;
    left: 11px;
}

.top-map .mapNavigation .right i {
    position: absolute;
    top: 8px;
    left: 11px;
}

.shadowslide{
    padding-top: 40px;
}

.shadowslide:before{
    position: absolute;
    top: 0px;
    content: '';
    width: 100%;
    height: 70px;
    background: url(/static/img/topshadow.png) no-repeat top center;
}

.wetter-slide{
    padding-bottom: 28px;
}

.wetter-slide .col-sm-4 {
    border-right: 1px dotted #000000;
    min-height: 101px;
    padding-right: 5px;
}

.wetter-slide .col-sm-4:last-child {
    border-right: none;
    padding-right: 15px;
}

.wetter-slide h2,
.wetter-slide .h2{
    margin: 0;
    font-family: FS Emeric Light, sans-serif;
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 32px;
}

.wetter-slide h3{
    font-family: "FS Emeric Medium", sans-serif;
    font-weight: normal;
    font-size: 24px;
    margin: 0 0 17px 0;
}

.wetter-slide .datarow div{
    display: block;
    font-size: 24px;
    color: #1e1e1e;
}

.wetter-slide .datarow span{
    position: absolute;
    top: 32px;
    display: block;
    font-size: 14px;
    color: #666666;
}

.wetter-slide .datarow i{
    position: absolute;
    top: 5px;
}

.wetter-slide .datarow p{
    margin: 0;
    padding: 0;
    position: absolute;
    left: 50px;
}

section.contact-slide{
    margin:20px 0 60px 0;
}

section.contact-slide h2,
section.contact-slide .h2{
    position: relative;
    text-align: center;
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 18px;
    font-weight: normal;
    margin: 0 0 30px 0;
    padding-top: 20px;
}

section.contact-slide h2:before,
section.contact-slide .h2:before{
    content: '';
    display: block;
    width: 100px;
    height: 2px;
    position: relative;
    margin: 0 auto;
    top: -20px;
    background: #000000;
}

section.contact-slide h2 span,
section.contact-slide .h2 span{
    display: block;
    font-family: FS Emeric Light, sans-serif;
    padding-top: 8px;
}

section.contact-slide .contactinfo p{
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 20px;
    margin: 0;
}

section.contact-slide .contactinfo p span{
    display: block;
    font-family: FS Emeric Light, sans-serif;
    font-size: 17px;
    margin: 0;
}

section.contact-slide.kontakt{
    padding: 0 0 25px 0;
}

section.contact-slide.kontakt h2:before,
section.contact-slide.kontakt .h2:before{
    display: none;
}

section.contact-slide.kontakt.background h2:before,
section.contact-slide.kontakt.background .h2:before{
    display: block;
}

section.contact-slide.kontakt.background{
    padding: 65px 0 45px 0;
    background: #d2cec6 url('/static/img/pattern_light.png');
    background-size: 40px 38px;
}

section.contact-slide.kontakt.background:before {
    content: '';
    display: block;
    position: absolute;
    top: -30px;
    left: 50%;
    background: #000000;
    width: 60px;
    height: 60px;
    transform: translateX(-50%) rotate(45deg);
}

section.contact-slide.kontakt.background:after {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 60px;
    content: "\E01D";
    bottom: 216px;
    font-size: 23px;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

section.contact-slide .contactinfo p a.tellink{
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 20px;
    margin: 0;
    color: #333;
    font-weight: normal;
    border-color:transparent;
}

.page-wrapper p a.telephonelink{
    font-family: FS Emeric Light, sans-serif;
    font-size: 18px;
    margin: 0;
    color: #333;
    font-weight: normal;
    border-color:transparent;
}

.page-wrapper p a.telephonelink.inline{
    display: inline;
}



.trendred{
    color: #8c0f00;
}

section.four-teaser{
    background: #eae8e3;
    padding-bottom: 30px;
    padding-top: 30px;
    line-height: 1.3;
}

section.four-teaser.noBg{
    background: none;
}

section.four-teaser h2{
    margin: 35px 0 25px 0;
}

section.four-teaser .four-teaser figure{
    margin-bottom: 10px;
    overflow: hidden;
}

@media screen and (min-width: 768px) {
    section.four-teaser .four-teaser figure{
        margin-bottom: 20px;
    }
}

section.four-teaser .four-teaser .img-responsive{
    transition: all .4s ease-out;
}

section.four-teaser .four-teaser:hover .img-responsive{
    transform: scale(1.02);
}

section.four-teaser .four-teaser span{
    color: #c81900;
    font-size: 16px;
    font-family: FS Emeric Light, sans-serif;
    display: block;
    margin-bottom: 5px;
}

@media screen and (min-width: 768px) {
    section.four-teaser .four-teaser span{
        margin-bottom: 10px;
    }
}

section.four-teaser .four-teaser a{
    font-size: 16px;
    font-family: "FS Emeric Medium", sans-serif;
    display: inline;
    margin-bottom: 15px;

    border-bottom: 1px solid rgba(51, 51, 51, 0);
    -webkit-transition: border 500ms ease;
    -moz-transition: border 500ms ease;
    -ms-transition: border 500ms ease;
    -o-transition: border 500ms ease;
    transition: border 500ms ease;
}

section.four-teaser .four-teaser:hover a{
    border-bottom: 1px solid rgba(51, 51, 51, 1);
}



section.footer{
    background: #1e1d1b;
    color: #d9d9d9;
    padding: 40px 0 14px 0;
}

section.footer .socialline{
    border-bottom: 1px dotted #4b4a49;
    padding-bottom: 25px;
}

section.footer .socialline a {
    font-family: "FS Emeric Bold", sans-serif;
    font-size: 15px;
}

section.footer a:hover {
    color: #61605f;
}
section.footer .copytext a:hover{
    color: #808080;
}

section.footer .socialline i {
    margin-right: 10px;
    position: relative;
    top: 3px;
}

section.footer .socialline i.icon-linkedIn {
    top: 1px;
}

section.footer .socialline i.icon-tripadvisor {
    top: 2px;
}

section.footer .socialline i.icon-soc-pinterest {
    font-size: 1.9em;
    line-height: 0.5;
    vertical-align: -0.15em;
}

section.footer .textline {
    border-bottom: 1px dotted #4b4a49;
    padding-bottom: 30px;
    padding-top: 40px;
}

section.footer .textline p {
    font-size: 15px;
}

section.footer .textline a {
    display: block;
    font-family: "FS Emeric Bold", sans-serif;
    font-size: 15px;
}

section.footer form input.txt {
    border: none;
    height: 46px;
    padding: 0 0px 0 10px;
    background: #61605f;
    color: #1e1d1b;
    float: left;
    font-family: FS Emeric Light, sans-serif;
    font-size: 16px;
    width: 80%;
}

section.footer ::-webkit-input-placeholder {
    color: #1e1d1b;
    font-family: FS Emeric Light, sans-serif;
    font-size: 16px;
}

section.footer ::-moz-placeholder {  /* Firefox 19+ */
    color: #1e1d1b;
    font-family: FS Emeric Light, sans-serif;
    font-size: 16px;
}

section.footer :-ms-input-placeholder {
    color: #1e1d1b;
    font-family: FS Emeric Light, sans-serif;
    font-size: 16px;
}

section.footer form button.nlsubmit {
    border: none;
    color: #1e1d1b;
    float: right;
    position: relative;
    right: 0;
    height: 46px;
    background: #61605f;
    width: 20%
}

section.footer form button.nlsubmit i {
    top: 3px;
    position: relative;
}

section.footer .copytext{
    padding-top: 30px;
    color: #61605f;
}

section.footer .copytext p{
    font-family: FS Emeric Light, sans-serif;
    font-size: 15px;
}

section.footer .copytext span{

}

section.footer .copytext a{
    font-family: FS Emeric Light, sans-serif;
    font-size: 15px;
    margin-left: 15px;
    color:inherit;
    border:0;
}

section.footer a.vkb{
    text-transform: uppercase;
    font-weight: bold;
    font-family: Arial;
    margin: 0 0 0 60px;
    font-size: 19px;
    line-height: 18px;
}

section.footer a.vkb span{
    font-weight: normal;
    display: block;
    margin-left: 60px;
}

section.footer a.vkb span:after{
    display: inline-block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0,0);
    -ms-transform: translate(0,0);
    transform: translate(0,0);
    content: "\E008";
    top: 5px;
    font-size: 32px;
    position: absolute;
    left: 0;
}


section.footer .icon-group_logo{
    font-size: 31px;
    top: 8px;
    position: relative;
}



/*section.top-slider h1:after,
section.top-slider h2:after{
    content: '';
    position: relative;
    top: 29px;
    left: 38%;
    display: block;
    width: 100px;
    height: 3px;
    background: #ffffff;
    box-shadow: 1px 1px 1px #a7a7a7;
}*/

.breiterTeaser {
    margin-bottom: 30px;
}

.breiterTeaser strong {
    position: relative;
    margin: 0 0 5px;
    padding-top: 10px;
    display: block;
}

.breiterTeaser strong:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 2px;
    background: #000000;
}

.breiterTeaser h3 {
    position: relative;
    margin: 0 0 15px 0;
    display: block;
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 24px;
    font-weight: normal;
    color: #8c0f00;
}

.breiterTeaser p {
    margin: 0;
}

.breiterTeaser a {
    color: #8c0f00;
    font-size: 16px;
    font-family: "FS Emeric Medium", sans-serif;
}

section.fullslider{
    overflow: hidden;
}

section.fullslider .slick-slide,
section.restaurants .slick-slide{
    position: relative;
}

.pimcore_area_media a.image.video span,
.pimcore_area_media a.image.gallery span,
section.fullslider .slick-slide a.image span,
section.restaurants .slick-slide a.image span{
    position: absolute;
    display: block;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);

    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}


.pimcore_area_media a.image.video i,
.pimcore_area_media a.image.gallery i,
section.fullslider .slick-slide a.image i,
section.restaurants .slick-slide a.image i{
    color: #ffffff;
    position: relative;
    display: table;
    text-align: center;
    font-size: 21px;
    top: 50%;
    left: 50%;
    width: 21px;
    height: 21px;
    margin-top: -51px;
    margin-left: -10px;
    z-index: 5;
    opacity: 0;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.pimcore_area_media a.image.gallery.threesixty i{
    font-size: 34px;
    margin-top: -64px;
    margin-left: -14px;
    top: 51.3%;
}

/*.pimcore_area_media a.image.video span:before,*/
.pimcore_area_media a.image.gallery span:before,
section.fullslider .slick-slide a.image span:before,
section.restaurants .slick-slide a.image span:before{
    content: '';
    position: relative;
    display: block;
    top: 50%;
    left: 50%;
    margin-left: -37px;
    margin-top: -37px;
    width: 75px;
    height: 75px;
    background: rgba(0,0,0,0.5);
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}

/*.pimcore_area_media a.image.video span:after,*/
.pimcore_area_media a.image.gallery span:after,
section.fullslider .slick-slide a.image span:after,
section.restaurants .slick-slide a.image span:after{
    content: '';
    position: relative;
    display: block;
    top: 50%;
    left: 50%;
    margin-left: -27px;
    margin-top: -35px;
    width: 55px;
    height: 55px;
    background: rgba(0,0,0,0.8);
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}

.pimcore_area_media a.image.video .showPlayButton:before{
    content: '';
    position: relative;
    display: block;
    top: 50%;
    left: 50%;
    margin-left: -37px;
    margin-top: -37px;
    width: 75px;
    height: 75px;
    background: rgba(0,0,0,0.5);
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}

.pimcore_area_media a.image.video .showPlayButton:after{
    content: '';
    position: relative;
    display: block;
    top: 50%;
    left: 50%;
    margin-left: -27px;
    margin-top: -35px;
    width: 55px;
    height: 55px;
    background: rgba(0,0,0,0.8);
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}

.pimcore_area_media a.image.video .showPlayButton{
    position: absolute;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    left:48%;
    width: 55px;
    height: 55px;
}

.pimcore_area_media .dreier-block a.image.video .showPlayButton{
    left: 43%;
}


.pimcore_area_media a.image.video .titleText,
.pimcore_area_media a.image.gallery .titleText{
    position: absolute;
    opacity: 0;
    left: 0;
    bottom: 0;
    color: #000000;
    background: rgba(255,255,255,0.5);
    font-size: 16px;
    padding: 5px 15px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.pimcore_area_media a.image.video:hover .titleText,
.pimcore_area_media a.image.video:hover i,
.pimcore_area_media a.image.video:hover span,
.pimcore_area_media a.image.gallery:hover .titleText,
.pimcore_area_media a.image.gallery:hover i,
.pimcore_area_media a.image.gallery:hover span,
section.fullslider .slick-slide a.image:hover i,
section.fullslider a.image:hover span,
section.restaurants .slick-slide a.image:hover i,
section.restaurants a.image:hover span{
    opacity: 1;
}




section.fullslider .slick-prev,
section.restaurants .slick-prev{
    position: absolute;
    top: 50%;
    margin-top: -37px;
    left: -37px;
    border: none;
    background: none;
    width: 75px;
    height: 75px;
    background: rgba(0,0,0,0.5);
    transform: rotate(45deg);
    text-indent: -99em;
}

section.fullslider .slick-prev:before,
section.restaurants .slick-prev:before{
    position: absolute;
    content: '';
    top: 11px;
    right: 11px;
    width: 64px;
    height: 62px;
    background: rgba(0,0,0,0.8);
}

section.fullslider .slick-prev:after,
section.restaurants .slick-prev:after{
    content: "\E005";
    position: absolute;
    top: 22px;
    right: 14px;
    width: 28px;
    font-size: 14px;
    line-height: 12px;
    transform: rotate(-225deg);
    display: inline-block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #ffffff;
    text-indent: 0;
}

section.fullslider .slick-next,
section.restaurants .slick-next{
    position: absolute;
    top: 50%;
    margin-top: -37px;
    right: -37px;
    border: none;
    background: none;
    width: 75px;
    height: 75px;
    background: rgba(0,0,0,0.5);
    transform: rotate(45deg);
    text-indent: -99em;
}

section.fullslider .slick-next:before,
section.restaurants .slick-next:before{
    position: absolute;
    content: '';
    top: 8px;
    right: -4px;
    width: 69px;
    height: 57px;
    background: rgba(0,0,0,0.8);
}

section.fullslider .slick-next:after,
section.restaurants .slick-next:after{
    content: "\E005";
    position: absolute;
    top: 40px;
    left: 14px;
    width: 28px;
    font-size: 14px;
    line-height: 12px;
    transform: rotate(-45deg);
    display: inline-block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #ffffff;
    text-indent: 0;
}


section.bildreihe .bildreihe-img p{
    font-size: 15px;
    font-family: FS Emeric Light, sans-serif;
    color: #9d9a94;
    line-height: 19px;
}

.myContentTable h2{
    margin-bottom: 35px;
}

.myContentTable table{
    width: 100%;
}


.myContentTable table thead{
    background: #d7d3cc;
}

.myContentTable table thead th{
    padding: 5px 15px;
    font-family: FS Emeric Light, sans-serif;
    font-weight: normal;
}

.myContentTable table tbody th{
    padding: 5px 15px;
    font-family: FS Emeric Light, sans-serif;
    font-weight: normal;
    color: #c7432d;
}

.myContentTable table td{
    padding: 0 15px;
}

.myContentTable table tbody tr{
    border-bottom: 1px solid #d7d3cc;
}

.slick-slider{
    overflow: hidden;

}

.page-wrapper{
    background: #f6f4f0;
}

.page-wrapper.white{
    background: #ffffff;
}



body.portal .page-header-bar.onPortal{
    opacity: 0;
    top: -60px;
    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    -ms-transition: opacity 1s;
    -o-transition: opacity 1s;
    transition: opacity 1s;
}

body.portal .page-header-bar.onPortal.showMe{
    top: 0;
    opacity: 1;

}

body.portal section.zitat {
    background: #8c0f00 url(/static/img/pattern_red.png);
    background-size: 40px 38px;
    min-height: 248px;
}

body.portal section.zitat:before {
    content: '';
    display: block;
    position: absolute;
    top: -30px;
    left: 46%;
    background: #000000;
    width: 60px;
    height: 60px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

body.portal section.zitat h2 {
    font-size: 36px;
    color: #fff;
    margin-top: 0;
    margin-bottom: 20px;
}

/*body.portal section.zitat h2:before {
    position: absolute;
    top: -16px;
    left: 46%;
    width: 60px;
    height: 60px;
    content: "\E00A";
    bottom: 216px;
    font-size: 30px;
    display: inline-block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #ffffff;
}*/

.iconzitatholder{
    position: absolute;
    top: -20px;
    left: 46.7%;
    color: #ffffff;
    font-size: 40px;
}

body.portal section.zitat p {
    text-align: center;
    color: #fff;
    font-size: 18px;
    font-family: FS Emeric Light, sans-serif;
    margin: 0 auto 0;
    line-height: 31px;
    width: 100%;
}

.starttable p{
    line-height: 21px;
    font-family: "FS Emeric Medium", sans-serif;
}

.starttable h2{
    font-size: 36px;
    margin-bottom: 40px;
}

.starttable h3.left,
.starttable .h3.left{
    margin-top: 70px;
    font-family: "FS Emeric Medium", sans-serif;
}

/*body.portal .starttable table.rowtable tr{
    position: relative;
}

body.portal .starttable table.rowtable tr:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 1px;
    border-bottom: 1px dotted #3e3f41;
}*/

.starttable table.rowtable .dotline{
    display: block;
    width: 100%;
    height: 2px;
    border-bottom: 1px dotted #3e3f41;
}

.starttable .flagrow{
    position: relative;
}

.starttable .flagrow .flag{
    position: relative;
    float: left;
    margin-right: 2px;
    display: block;
    width: 110px;
    height: 110px;
    background: url('/static/img/flags.png') no-repeat;
    background-size: 110px 330px;
    text-transform: uppercase;
    font-family: FS Emeric Light, sans-serif;
    text-align: center;
    padding-top: 33px;
}


.starttable .flagrow .flag.comfort{
    background-position: 0 -110px;
}

.starttable .flagrow .flag.smart{
    background-position: 0 -220px;
}




body.portal table{
    font-family: FS Emeric Light, sans-serif;
    /*font-size: 16px;*/
    width: 100%;
}

body.portal .ui-datepicker-calendar tr{
    border-bottom: none;
}


.starttable td.check-col{
    width: 113px;
    text-align: center;
    padding: 4px 0;
}

.starttable table .check{
    display: inline-block;
    margin-top: 10px;
    color: #000000;
    font-size: 12px;
}

.starttable table td.info-col{
    padding-left: 20px;
}


body.portal .hotel-standard h3{
    font-size: 18px;
    font-family: "FS Emeric Medium", sans-serif;
    font-weight: normal;
    margin-bottom: 25px;
}

.hotelausstattungdetail .rot,
.pimcore_area_iconreihe .rot,
.pimcore_area_hotelausstattung .rot,
body.portal .hotel-standard .rot{
    position: relative;
    width: 60px;
    height: 60px;
    margin: 20px auto 30px auto;
}

.hotelausstattungdetail .rot .square,
.pimcore_area_iconreihe .rot .square,
.pimcore_area_hotelausstattung .rot .square,
body.portal .hotel-standard .rot .square{
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
    background: #dfdcd6;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

body.portal .hotel-standard{
    line-height: 19px;
    font-size: 16px;
}

.hotelausstattungdetail .icn-badge,
.pimcore_area_hotelausstattung .icn-badge,
body.portal .hotel-standard .icn-badge{
    height: 178px;
    overflow: hidden;
    display: inline-block;
    float: none;
   /* width: 24%;*/
}
.hotelseite .page-wrapper .top-text .container  p:last-child {
    margin-bottom: 0px;
}
.hotelausstattungdetail .icn-badge.hotels {
    height: 200px;
}
.centericons{
    text-align: center;
}

.icn-badge{
    display: inline-block;
    float: none;
}


.pimcore_area_iconreihe .rot .iconholder,
.pimcore_area_hotelausstattung .rot .iconholder,
body.portal .hotel-standard .rot .iconholder{
    position: relative;
    top: 10px;
    font-size: 40px;
}

.hotelausstattungdetail .rot .iconholder{
    position: relative;
    top: 11px;
    font-size: 41px;
}
.hotelausstattungdetail .iconholder.icon-sleeping-experience {
    top: 8px;
    font-size: 35px;
}

.distanz-map.landingpage .hotelinfo,
body.portal .distanz-map .hotelinfo{
    background: #eae8e4;
    height: 440px;
    padding: 29px 60px 0 60px;
    text-align: center;
    font-size: 18px;
}

.distanz-map.landingpage .hotelinfo img,
body.portal .distanz-map .hotelinfo img{
    margin-bottom: 8px;
}

.distanz-map.landingpage .hotelinfo a,
body.portal .distanz-map .hotelinfo a{
    clear: both;
    display: block;
    font-size: 18px;
    font-family: "FS Emeric Medium", sans-serif;
    position: relative;
    padding-top: 20px;
}

.distanz-map.landingpage .hotelinfo a.telephonelink,
body.portal .distanz-map .hotelinfo a.telephonelink{
    clear: both;
    display: block;
    font-size: 18px;
    font-family: FS Emeric Light, sans-serif;
    position: relative;
    padding-top: 15px;
}

.distanz-map.landingpage .hotelinfo a.telephonelink:before,
body.portal .distanz-map .hotelinfo a.telephonelink:before{
    display: none;
}

.distanz-map.landingpage .hotelinfo a:before,
body.portal .distanz-map .hotelinfo a:before{
    content: '';
    position: relative;
    top: -10px;
    margin: 0 auto;
    display: block;
    width: 100px;
    height: 2px;
    background: #000000;
}

.distanz-map.landingpage .hotelinfo p,
body.portal .distanz-map .hotelinfo p{
    margin-bottom: 5px;
}

.distanz-map.landingpage .hotelinfo a.normallink,
body.portal .distanz-map .hotelinfo a.normallink{
    font-family: FS Emeric Light, sans-serif;
    padding-top: 0;
    border-color:transparent;
}

.distanz-map.landingpage .hotelinfo a.normallink:before,
body.portal .distanz-map .hotelinfo a.normallink:before{
    display: none;
}

.topstoerer,
#topstoerer {
    display: block;
    background-color: rgba(239, 232, 228, .8);
    position: absolute;
    right: 5%;
    top: 50px;
    width: 25%;
    font-size: 16px;
    z-index: 10;
}
#topstoerer {
    width: 35%;
    overflow: hidden;
}
@media screen and (min-width: 1400px) {
    #topstoerer {
        width: 25%;
    }
}
.topstoerer__text {
    padding: 15px;
    width: 60%;
}
.topstoerer__text h4 {
    font-size: 1.25em;
    font-weight: 700;
    margin-bottom: 0.25em;
    margin-top: 0;
}
.topstoerer__text p {
    font-size: 1em;
    line-height: initial;
    margin-bottom: 0.5em;
}
.topstoerer__text p:last-child  { margin-bottom: 0; }
.topstoerer__link {
    bottom: 15px;
    color: white;
    font-size: 0.875em;
    line-height: initial;
    position: absolute;
    right: 15px;
    text-align: right;
    text-transform: uppercase;
    width: 22%;
    z-index: 10;
}

.topstoerer__link:hover { color: #ffffff; }
.triangle-down-right {
    width: 45%;
    height: 0;
    padding-top: 45%;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    right: 0;
}

#topstoerer.blogstoerer {
    width: 243px;
}

#topstoerer.blogstoerer i {
    position: absolute;
    top: 6px;
    left: 8px;
    font-size: 80px;
}
@media (max-width: 1024px), screen and (max-width: 1280px), (max-height: 800px) {
    #topstoerer.blogstoerer i {
        font-size: 65px;
    }
}

#topstoerer.blogstoerer p{
    width: 133px;
    float: right;
    margin: 7px 15px 10px 0;
}

#topstoerer.blogstoerer a{
    display: inline-block;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 20px!important;
}

@media screen and (min-width: 1720px) {
    .topstoerer,
    #topstoerer { min-height: 125px; }

    .triangle-down-right { width: 31%; }
    .topstoerer__link { width: 29%; }
}

@media screen and (min-width: 1640px ) and (max-width: 1719px) {
    .triangle-down-right { width: 35%; }
}

.triangle-down-right:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    margin-top: -500px;
    border-top: 500px solid transparent;
    border-right: 500px solid #8c0f00;
}

@media screen and (min-width: 768px) and (max-width: 1399px) {
    #topstoerer .triangle-down-right:after {
        margin-top: -470px;
    }
}

/*
.topstoerer,
#topstoerer{
    position: absolute;
    top: 50px;
    right: 5%;
    width: 222px;
    height: 222px;
    border-radius: 50%;
    background: rgba(0,0,0,0.8);
    color: #ffffff;
    font-size: 24px;
    text-align: center;
    line-height: 30px;
    padding: 55px 35px;
    font-family: "FS Emeric Medium", sans-serif;
}

.topstoerer{
    font-size: 45px;
    line-height: 65px;
}

.topstoerer:before,
#topstoerer:before{
    content: '';
    position: absolute;
    top: 11px;
    left: 11px;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 1px dashed #ffffff;
}
*/
.start-here.hotel .isPortal{
    display: none;
}

.start-here.hotel .isContent {
    display: block;
}

.start-here.hotel .isContent .info {
    display: block;
    padding-bottom: 6px;
    margin-bottom: 9px;
    border-bottom: 1px dotted #ffffff;
    text-transform: uppercase;
    font-size: 15px;
}

.start-here.hotel.closeFlag .isContent .info {
    display: none;
}

section.hotelausstattungdetail .panel-default>.panel-heading {
    color: #333;
    border-color: #d2cec6;
    border: none;
    border-top: 2px solid #d2cec6;
}

.comments__answer .panel-default>.panel-heading {
    color: #333;
    border: none;
}

section.hotelausstattungdetail .panel-heading, .comments__answer .panel-heading {
    border-radius: 0;
}

section.hotelausstattungdetail .panel, .comments__answer .panel{
    border: 0;
    box-shadow: none;
    background: none;
}


section.hotelausstattungdetail .panel-group .panel-heading+.panel-collapse>.panel-body,
section.hotelausstattungdetail .panel-group .panel-heading+.panel-collapse>.list-group,
.comments__answer .panel-group .panel-heading+.panel-collapse>.panel-body,
.comments__answer .panel-group .panel-heading+.panel-collapse>.list-group{
    border: none;
    padding-top: 5px;
    font-family: "FS Emeric Medium", sans-serif;
    padding-left: 44px;
    font-size: 16px;
}

.comments__answer .panel-group .panel-heading+.panel-collapse>.panel-body {
    font-family: inherit;
}

section.hotelausstattungdetail .panel-title>a, .comments__answer .panel-title>a{
    color: #000000;
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 16px;
    display: flex;
}

section.hotelausstattungdetail .clickbox, .comments__answer .clickbox{
    width: 16px;
    height: 16px;
    margin-right: 13px;
    position: relative;
    top: 2px;
    display: inline-block;
}

section.hotelausstattungdetail .clickbox-holder, .comments__answer .clickbox-holder{
    display: inline-block;
    width: 29px;
    height: 10px;
    flex-shrink: 0;
}

section.hotelausstattungdetail .collapsed .clickbox:before, .comments__answer .collapsed .clickbox:before{
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
    content: "\E03D";
    color: #8c0f00;
}



section.hotelausstattungdetail .clickbox:before, .comments__answer .clickbox:before{
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
    content: "\E033";
    color: #8c0f00;
}

section.runners figure,
section.restaurants figure,
section.zimmerdetails figure{
    position: relative;
}

section.runners figure span.photo,
section.restaurants figure span.photo,
section.zimmerdetails figure span.photo{
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 40px;
    background: #000000;
    color: #ffffff;
}

section.runners figure span.photo:before,
section.restaurants figure span.photo:before,
section.zimmerdetails figure span.photo:before {
    position: absolute;
    top: 8px;
    left: 17px;
    font-size: 23px;
}

section.runners .infos,
section.restaurants .infos,
section.zimmerdetails .infos{
    position: relative;
}

section.runners .infos .detail,
section.restaurants .infos .detail,
section.zimmerdetails .infos .detail{
    position: relative;
    max-height: 0;
    overflow: hidden;
    -moz-transition: max-height 1s ease;
     -webkit-transition: max-height 1s ease;
     -o-transition: max-height 1s ease;
     transition: max-height 1s ease;
}

section.runners .infos .detail.open,
section.restaurants .infos .detail.open,
section.zimmerdetails .infos .detail.open{
    max-height: 2000px;
}

section.runners .infos p,
section.restaurants .infos p{
    margin: 0;
}

section.restaurants .infos .detail.open p{
    margin: 15px 0;
}

section.runners .infos h3,
section.restaurants .infos h3,
section.zimmerdetails .infos h3{
    position: relative;
    font-size: 24px;
    font-family: "FS Emeric Medium", sans-serif;
    font-weight: normal;
    margin: 0 0 5px 0;
    padding-top: 30px;
}

section.runners .infos h4,
section.restaurants .infos h4,
section.restaurants .infos .h4,
section.zimmerdetails .infos h4{
    font-weight: normal;
    margin: 0 0 30px 0;
    font-size: 16px;
    font-family: FS Emeric Light, sans-serif;
}

section.runners .infos h4,
section.restaurants .infos h4,
section.restaurants .infos .h4{
    margin: 0 0 14px;
}

section.runners .infos h3:before,
section.restaurants .infos h3:before,
section.zimmerdetails .infos h3:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 2px;
    background: #000000;
}

section.runners .infos ul,
section.restaurants .infos ul,
section.zimmerdetails .infos ul{
    display: block;
    margin: 0;
    padding: 0 0 0 20px;
}

section.runners .infos ul li,
section.restaurants .infos ul li,
section.zimmerdetails .infos ul li{
    margin: 0;
    padding: 0;
    font-size: 16px;
    list-style-type: square;
}

section.runners .infos .detail ul,
section.restaurants .infos .detail ul,
section.zimmerdetails .infos .detail ul{
    margin-bottom: 15px;
}

section.runners .infos .detail p
section.restaurants .infos .detail p,
section.zimmerdetails .infos .detail p{
    font-size: 16px;
}

section.runners .infos .detail a,
section.restaurants .infos .detail a,
section.zimmerdetails .infos .detail a{
    color: #8f1000;
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 16px;
}


section.runners .bar,
section.restaurants .bar,
section.zimmerdetails .bar{
    margin-top: 20px;
}
section.runners .bar {
    overflow: hidden;
    margin-bottom: 10px;
}

section.bewertungs-highlights .more,
section.runners .more,
section.restaurants .more,
section.zimmerdetails .more{
    float: left;
    display: block;
    border-top: 2px solid #d2cec6;
    border-bottom: 2px solid #d2cec6;
    width: 50%;
    font-size: 16px;
    font-family: "FS Emeric Medium", sans-serif;
    color: #8f1000;
    padding: 3px 0;
    cursor: pointer;
}

section.zimmerdetails .more{
    width: 50%!important;
}

section.runners .more,
section.bewertungs-highlights .more,
section.restaurants .more{
    width: 100%;
}

section.runners .more .zu,
section.restaurants .more .zu,
section.zimmerdetails .more .zu{
    display: none;
}

section.runners .more .auf,
section.restaurants .more .auf,
section.zimmerdetails .more .auf{
    display: block;
}

section.runners .more.on .zu,
section.restaurants .more.on .zu,
section.zimmerdetails .more.on .zu{
    display: block;
}

section.runners .more.on .zu i,
section.restaurants .more.on .zu i,
section.zimmerdetails .more.on .zu i{
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}


section.runners .more.on .auf,
section.restaurants .more.on .auf,
section.zimmerdetails .more.on .auf{
    display: none;
}

section.bewertungs-highlights .more i,
section.runners .more i,
section.restaurants .more i,
section.zimmerdetails .more i{
    margin: 0 10px;
    position: relative;
    top: 2px;
}
.runners {
    font-size: 16px;
}
.runners__btn {
    display: block;
    height: 60px;
    background-color: #ece9e4;
    position: relative;
}
.runners__btn-logo {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 10px;
    transform: translateY(-50%);
    /*padding: 0 10px; */
}
.runners__title,
.runners__detail-bar,
.runners__logo-bar {
    flex: none;
}
.runners__logo-bar {
    margin-top: 10px;
}
.runners__expandable-wrapper {
    flex: auto;
}
.runners__expandable {
    line-height: 1.7;
    max-height: calc(2em * 1.7);
    overflow: hidden;
    position: relative;
}
@media screen and (min-width: 1100px) {
    .runners__expandable {
        max-height: calc(3em * 1.7);
    }
}
.runners__expandable::after {
    content: "…";
    position: absolute;
    bottom: 0;
    right: 0;
    background:#f6f4f0;
    padding: 4px;
    padding-bottom: 0;
}
.open > .runners__expandable {
    max-height: none;
}
.open > .runners__expandable::after {
    visibility: hidden;
}

@media screen and (max-width: 1199px) {
    section.runners  h3.runners__title {
        padding-top: 10px;
    }
    section.runners .bar {
        margin: 13px 0;
    }
}

.pruefen,
section.zimmerdetails .pruefen{
    float: left;
    display: block;
    background: #8f1000;
    text-align: center;
    color: #ffffff;
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 16px;
    padding: 5px 0;
    width: 50%;
    cursor: pointer;
}

section.zimmerdetails .pruefen.full{
    width: 100%
}


section.zimmerdetails .vergleichbox{
    position: absolute;
    top: 0;
    right: 0;
    font-size: 16px;
    font-family: FS Emeric Light, sans-serif;
    padding-right: 40px;
    cursor: pointer;
}

section.zimmerdetails .vergleichbox:before{
    content: '';
    position: absolute;
    top: 1px;
    right: 0;
    width: 25px;
    height: 25px;
    background: #ffffff;
}

section.zimmerdetails .vergleichbox.on:after{
    position: absolute;
    top: 9px;
    right: -5px;
    width: 25px;
    height: 25px;

    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
    content: "\E04E";
    font-size: 11px;
}

section.zimmerdetails .topspace i{
    font-size: 11px;
    margin-right: 5px;
}

#compare{
    font-family: FS Emeric Light, sans-serif;
    font-size: 16px;
}

#compare h3{
    font-size: 24px;
    font-family: "FS Emeric Medium", sans-serif;
}

#compare h4{
    font-size: 18px;
    font-family: "FS Emeric Medium", sans-serif;
}

#compare p{
    margin: 0 0 20px 0;
}

#compare ul{
    margin: 0 0 15px 0;
    padding: 0 0 0 20px;
}

#compare a.red-button{
    float: left;
}

#compare figure{
    clear: both;
}

#compare .delete{
    float: right;
    color: #aeaca9;
    cursor: pointer;
}

#compare .delete i{
    font-size: 10px;
    margin-right: 5px;
}

.top-restaurant-content p{
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 18px;
    line-height: 30px;
}

.top-restaurant-content h1{
    font-family: FS Emeric Light, sans-serif;
    font-size: 36px;
    line-height: 45px;
    font-weight: normal;
    margin: 0;
}

.restaurants p{
    font-size: 16px;
    line-height: 24px;
}

.restaurants h5{
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    margin: 0;
}

.pauschalform label{
    display: block;
    font-family: FS Emeric Light, sans-serif;
    font-size: 18px;
    line-height: 24px;
}

.pauschalform select{
    border: none;
    background: #ffffff;
    width: 100%;
    padding: 9px 10px;
    cursor: pointer;
}

.pauschalform button.filter{
    border: none;
    background: #000;
    display: block;
    width: 100%;
    height: 43px;
}

.pauschalform button.filter i{
    color: #fff;
    font-size: 23px;
    line-height: 35px;
}

select.nonactive{
    color: #d3d3d3;
}

section.pauschal-detail h2,
section.pauschal-detail .h2{
    margin: 0 0 20px 0;
    font-family: "FS Emeric Medium", sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
}

section.pauschal-detail h2.nomargin,
section.pauschal-detail .h2.nomargin{
    margin: 0;
}

.landingdata .price-badge,
section.bigimage .price-badge,
section.pauschal-detail .price-badge{
    position: relative;
    display: block;
    width: 121px;
    height: 120px;
    background: #8a0f00;
    border-radius: 50%;
    float: right;
}

section.bigimage .container:before{

    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    z-index: 1;
    background: url('/static/img/sujet_gradient.png') no-repeat;
    opacity: .7;

}

.landingdata .price-badge,
section.bigimage .price-badge{
    float: left;
    margin-left: 42%;
}

.landingdata .price-badge:before,
section.bigimage .price-badge:before,
section.pauschal-detail .price-badge:before{
    content:'';
    position: absolute;
    top: 10px;
    left: 11px;
    width: 100px;
    height: 100px;
    background: #aa1500;
    z-index: 0;
    border: 1px dashed #fff;
    border-radius: 50%;
}

.landingdata .price-badge div,
section.bigimage .price-badge div,
section.pauschal-detail .price-badge div{
    position: relative;
    top: 38px;
    left: 1px;
    text-align: center;
    color: #ffffff;
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 24px;
}

.landingdata .price-badge div,
section.bigimage .price-badge div{
    top: 31px;
    left: -1px;
}

.landingdata .price-badge div:after,
section.bigimage .price-badge div:after,
section.pauschal-detail .price-badge div:after{
    content: '';
    position: relative;
    display: block;
    top: 0;
    left: 35%;
    width: 40px;
    height: 2px;
    background: #ffffff;
}

.landingdata .price-badge div:after,
section.bigimage .price-badge div:after{
    top: 13px;
}

.topstoerer span,
.landingdata .price-badge div span,
section.bigimage .price-badge div span,
section.pauschal-detail .price-badge div span{
    display: block;
    font-family: FS Emeric Light, sans-serif;
    font-size: 15px;
    line-height: 3px;
}


.landingdata .price-badge div span,
section.bigimage .price-badge div span{
    font-size: 14px;
    line-height: 6px;
}

.topstoerer span{
    font-size: 25px;
    line-height: 22px;
}

.topstoerer span.bo,
.landingdata .price-badge div span.bo,
section.bigimage .price-badge div span.bo{
    margin-top: 1px;
}

section.pauschal-detail p{
    font-size: 18px;
    line-height: 30px;
}

section.pauschal-detail ul{
    padding: 0 0 0 20px;
}

section.pauschal-detail .buchungszeitraum{
    /*margin-bottom: 40px;*/
}

section.pauschale-more-hotel h2.headline{
    font-size: 36px;
    font-family: FS Emeric Light, sans-serif;
    color: #000000;
}

section.pauschale-more-hotel h3{
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 15px;
    margin: 15px 0 5px 0;
}

section.pauschale-more-hotel h2{
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 24px;
    color: #8c0f00;
    margin: 0 0 25px 0;
    padding: 0 10px 0 0;
}

section.pauschale-more-hotel .preis{
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 24px;
    color: #8c0f00;
    margin-bottom: 15px;
}

section.pauschale-more-hotel .preis span{
    font-family: FS Emeric Light, sans-serif;
    font-size: 16px;
    color: #000000;
    display: block;
    line-height: 22px;
}

.mapInfo .cta,
section.pauschale-more-hotel .cta{
    position: relative;
}

.mapInfo .cta .hoteldetail,
section.pauschale-more-hotel .cta .hoteldetail{
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 16px;
    width: 50%;
    display: block;
    float: left;
    color: #8c0f00;
    border-top: 2px solid #d2cec6;
    border-bottom: 2px solid #d2cec6;
    line-height: 27px;
    padding: 5px 0;
}

.mapInfo .cta .hoteldetail{
    width: 100%;
}

.mapInfo .cta .hoteldetail i,
section.pauschale-more-hotel .cta .hoteldetail i{
    margin-right: 7px;
    top: 2px;
    position: relative;
}

.mapInfo .cta .hoteldetail i{
    top: 0px;
    font-size: 12px;
}



.mapInfo .cta .hoteldetail{
    padding: 5px 0 5px 15px;
}


.mapInfo .cta .buchen,
section.pauschale-more-hotel .cta .buchen{
    background: #8c0f00;
    display: inline-block;
    width: 50%;
    color: #ffffff;
    text-align: center;
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 16px;
    padding: 7px 0;
}


i#my-pricebadge{
    font-style: normal;
}

.special-teaser{
    position: relative;
}

.special-teaser figure{
    margin-bottom: 20px;
}

.special-teaser h3{
    margin: 0 0 15px 0;
    color: #8c0f00;
    font-weight: normal;
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 24px;
    line-height: 30px;
}

.special-teaser p{
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 15px 0;
}

.special-teaser a{
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 16px;
    line-height: 24px;
}

.special-teaser a i{
    position: relative;
    font-size: 12px;
    top: 1px;
    margin-right: 12px;
}

.meetings section.fullslider{
    margin: 0;
}

.pimcore_area_hotelumgebungsmap h2{
    margin-bottom: 20px;
}

.mediarow{
}

.mediarow [class^="col-"],
.mediarow [class*=" col-"]{
    padding: 0;
}

.mediarow .dreier-block{
    display: block;
    width: 100%;
    border:1px solid #fff;
    border-width:1px 0;
    /*min-height: 284px;*/
}

.mediarow .einser-block{
    display: block;
    width: 100%;
    min-height: 530px;
    border:1px solid #fff;
    border-width:1px 0;
}

.mediarow .einser-block-links{
    display: block;
    width: 100%;
    min-height: 570px;
}

.mediarow .einser-block-rechts{
    display: block;
    width: 100%;
    /*min-height: 285px;*/
    clear: both;
    position: relative;
}

a.image.video,
a.image.gallery{
    display: block;
    position: relative;
}

.mediarow .icon-play{

}

.pimcore_area_media a.image.video i{
    top: 51%;
    left: 50.5%;
    opacity: 1;
}

.pimcore_area_media .dreier-block a.image.video i {
    top: 55%;
    left: 61.5%;
    opacity: 1;
}

.pimcore_area_media .einser-block a.image.video i {
    top: 55.5%;
    left: 60.3%;
    position: relative;
}

.downloadcenter .download-teaser{
    border-top: 2px solid #d2cec6;
    border-bottom: 2px solid #d2cec6;
    height: 168px;
    padding: 15px 0 15px 0;
}

.downloadcenter .download-teaser h3{
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 18px;
    font-weight: normal;
    margin: 0 0 7px 0;
}

.downloadcenter .download-teaser p{
    font-family: FS Emeric Light, sans-serif;
    font-size: 16px;
    margin: 0;
    line-height: 19px;
}

.downloadcenter .download-teaser a.img-link{
    float: right;
    margin-right: 20px;
    -moz-box-shadow: 0 0 5px #888;
    -webkit-box-shadow: 0 0 5px#888;
    box-shadow: 0 0 5px #888;
}

.downloadcenter .download-teaser a.download{
    display: inline;
    position: absolute;
    bottom: 18px;
    left: 15px;
}

.downloadcenter .download-teaser a.download i{
    color: #8c0f00;
    position: relative;
    top: 9px;
}


.ovlay{
    position: absolute;
    z-index: 500;
    width: 100%;
}


.sidebar-bottom .language.open{
    color: #ffffff;
}

.openLogin{
    position: absolute;
    top: -160px;
    left: 0;
    height: 160px;
    width: 280px;
    display: none;
}


.red-button-search{
    background: #8c0f00;
    border: none;
    color: #ffffff;
    float: left;
    position: relative;
    font-size: 16px;
    margin: 0;
    padding: 3px 20px;
}



.red-button-login,
.red-button-login:hover {
    background: #8c0f00;
    border: none;
    color: #ffffff;
    padding: 12px 22px 11px 22px;
    float: left;
    position: relative;
    font-size: 16px;
    margin-right: 10px;
    margin-bottom: 14px;
}
.facebook-button-login {
    background: #32599D;
    border: none;
    color: #ffffff;
    padding: 12px 12px 11px;
    font-size: 16px;
}
.facebook-button-login:hover,
.facebook-button-login:focus {
    color: #ffffff;
}
.facebook-button-login .icon {
    font-size: 18px;
    vertical-align: -.15em;
    margin-right: 3px;
}

.openLogin input{
    background: #ffffff;
    height: 50px;
    border-top: 1px solid #ebe8e3;
}

.openLogin input.password{
    border-bottom: 1px solid #ebe8e3;
}

.openLogin .form-group{
    margin: 0;
}

.search .do-close,
.profile .do-close{
    display: none;
}

.search.open .do-close,
.profile.open .do-close{
    display: block;
}

.search.open .do-close i,
.profile.open .do-close i{
    top: 4px;
    position: relative;
    color: #ffffff;
}

.search.open .do-open,
.profile.open .do-open{
    display: none;
}

.search.open .do-close i{
    font-size: 28px;
    position: absolute;
    top: 19px;
    left: 9px;
}

.openSearch{
    position: absolute;
    top: -50px;
    left: 0;
    height: 50px;
    width: 100%;
    display: none;
}

.openSearch .form-group{
    margin: 0;
}

.openSearch input{
    float: left;
    width: 100%;
    background: #fff;
    height: 50px;
    border-top: 1px solid #ebe8e3;
}

.openSearch button{
    float: left;
    width: 20%;
    border: none;
    background: #e9e8e4;
    border-bottom: 1px solid #ffffff;
    padding: 14px 0 15px
}

.openSearch button i{
    font-size: 22px;
    position: relative;
    top: 6px;
}

section.seminarraeume table{
    background: #ece9e3;
    font-size: 18px;
}

section.seminarraeume table tr.main{
    border-bottom: 2px solid #d2cec6;
    cursor: pointer;
}

section.seminarraeume table tr.main i.icon-minus{
    display: none;
}

section.seminarraeume table tr.main.open i.icon-minus{
    display: inline-block;
}

section.seminarraeume table tr.main i.icon-plus{
    display: inline-block;
    margin-right: 15px;
}

section.seminarraeume table tr.main.open i.icon-plus{
    display: none;
}

section.seminarraeume table tr.main.open{
    border: none;
}


section.seminarraeume table tr.main i{
    font-size: 14px;
    position: relative;
    top: 1px;
    margin-right: 5px;
}

section.seminarraeume table th{
    border: none;
    background: #d2cec6!important;
}

section.seminarraeume table td{
    border: none;
    background: #ece9e3!important;
}

section.seminarraeume table th.saalinfo{
    text-align: left;
    width: 320px;
}

section.seminarraeume table td.saalinfo{
    text-align: left;
    width: 320px;
    color: #8c0f00;
}

section.seminarraeume table tr.open td.saalinfo{
    font-family: "FS Emeric Medium", sans-serif;
}


section.seminarraeume table tr.detail{
    border-bottom: 2px solid #d2cec6;
}


section.seminarraeume .roomDetail{
    padding: 20px;
}

section.seminarraeume .infoData{
    text-align: left;
    font-size: 16px;
}

section.seminarraeume .infoData h3{
    margin: 0 0 10px 0;
    font-size: 16px;
    font-family: "FS Emeric Medium", sans-serif;
}

section.seminarraeume .infoData a{
    font-family: "FS Emeric Medium", sans-serif;
    color: #8c0f00;
}

section.seminarraeume .infoData .col{
    margin-bottom: 5px;
}

section.seminarraeume .konferenzpauschalen table{
    width: 100%;
    max-width: 100%;
    margin: 0;
}

section.seminarraeume .konferenzpauschalen table th,
section.seminarraeume .konferenzpauschalen table td{
    padding: 10px 15px;
}

section.seminarraeume .konferenzpauschalen table tr{
    border-bottom: 1px solid #d2cec6;
}

section.seminarraeume .konferenzpauschalen table tr td:last-child{
    text-align: left;
}

section.seminarraeume .konferenzpauschalen table tr th:first-child{
    text-align: left;
   /* width: 320px;*/
}

section.seminarraeume .konferenzpauschalen table tr td:first-child{
    text-align: left;
    width: 320px;
    color: #8c0f00;
}

section.contact-slide-hotelkontakt h2{
    font-size: 36px;
}

section.contact-slide-hotelkontakt table.hotelInfoTable *{
    color: #000000!important;
}

section.contact-slide-hotelkontakt table.hotelInfoTable tr{
    border-bottom: 2px solid #e4e1da;
}

section.contact-slide-hotelkontakt table.hotelInfoTable tr:last-child{
    border-bottom: none;
}

section.contact-slide-hotelkontakt table.hotelInfoTable tr td{
    padding: 12px;
}

section.contact-slide-hotelkontakt table.hotelInfoTable span{
    float: right;
}

.top-map{
    display: block;
    width: 100%;
    height: 664px;
}

#topMap{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 664px;
}


section.distanz-map.landingpage{
    margin: 0;
    overflow: hidden;
}

.landingpage section.footer p,
.landingpage section.footer a{
    font-size: 15px;
    display: inline-block;
    margin-right: 15px;
}
.landingpage section.footer .copytext .col-sm-8 a {
    margin-right: 0;
}

.landingpage section.footer {
  padding: 30px 0 0;
}

.landingpage a.landinglink{
    position: absolute;
    top: -266px;
    font-size: 22px!important;
}


.landingdata .price-badge{
    margin: 0;
    float: none;
    position: absolute;
    top: -400px;
    right: 5px;
}


.top-text h3,
.top-text .h3{
    font-family: "FS Emeric Medium", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    margin: 0 0 40px 0;
}

.page-wrapper p a, .page-wrapper li a{
    color: #8c0f00;
    border-bottom:1px dotted #8c0f00;
}

.page-wrapper .zitat p a:hover,
.page-wrapper .zitat p a{
    font-family: "FS Emeric Medium", sans-serif;
    color: #ffffff;
    border-bottom:1px dotted #ffffff;
}

.page-wrapper p a:hover, .page-wrapper p a:focus,
.page-wrapper li a:hover, .page-wrapper li a:focus{
    color: #000;
    border-color: transparent;
}

.page-wrapper .jump-nav a {
    border:0;
}

.page-wrapper .redblock p a {
    color: #fff;
    border-bottom:1px dotted #fff;
}
.page-wrapper .redblock p a:hover,
.page-wrapper .redblock p a:focus{
    border-color:transparent;
}

.page-wrapper section .footer p a{
    color: #61605f;
    font-weight: normal;
}

.more-images-gallery{
    display: none;
}

.hotelInfoTable .icon-email{
    font-size: 15px;
    position: relative;
    right: 5px;
}


.left_bullet ul li{
    float: left;
    width: 48%;
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 18px;
}

section.corporate-booking,
section.newsletter{
    background: #ece9e3;
    padding: 50px 0 50px 0;
}

form.nl-form select,
form.nl-form input[type="text"],
form.nl-form input[type="email"]{
    height: 46px;
    border: 2px solid #ffffff;
}

form.nl-form span.use{
    color: #8c0f00;
}

form.nl-form button{
    border: none;
    color: #ffffff;
    background: #a81400;
    text-transform: uppercase;
    padding: 10px 20px;
}

form.nl-form .formerror{
    border: 2px solid #a81400!important;
}

section.newsletter h3{
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 24px;
}

section.newsletter span.error{
    font-family: FS Emeric Light, sans-serif;
    font-size: 16px;
    color: #a81400;
    margin-left: 30px;
}


span.fake-checkbox{
    position: relative;
    display: block;
    background: #ffffff;
    width: 29px;
    height: 29px;
    float: left;
    border: 2px solid #ffffff;
}

span.fake-checkbox:after{
    display: none;
}


span.fake-checkbox.active:after{
    display: inline-block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0,0);
    -ms-transform: translate(0,0);
    transform: translate(0,0);
    content: "\E04E";
    color: #000000;
    position: absolute;
    top: 7px;
    left: 4px;
    font-size: 12px;
}

span.fake-checkbox + label {
    max-width: 95%;
}

a.fbloginBt{
    display: inline-block;
    position: relative;
    top: 10px;
    left: 15px;
}



#notify{
    background: rgba(0, 0, 0, .5);
    color: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    font-family: "FS Emeric Bold", sans-serif;
    font-weight: 400;
    z-index: 3000;
    display: none;
}

#notify div{
    position: relative;
    top: 300px;
    background-color: #ab1500;
    width: 200px;
    margin: 0 auto;
    padding: 20px;
}

.startVergleich{
    display: none;
    cursor: pointer;
}

.showAll{
    cursor: pointer;
}

.barForm{
    position: relative;
    z-index: 500;
    /*top: -441px;*/
    /*min-height: 380px;*/
}
.bar-form + .ovlay {
    display: block;
    bottom: 60px;
}

.barForm .ui-datepicker-inline {
    width: auto;
    max-width: 100%;
    margin: 0;
    padding: 0;
    height: 0;
}

.barForm .ui-datepicker-inline td span,
.barForm .ui-datepicker-inline td a {
    padding: 4px 0;
}

.right-select-choice label{
    margin: 0;
    line-height: 37px;
}

.right-select-choice select{
    width: 80px;
    background: #e9e8e4;
}

.right-select-box {
    background: #e9e8e4;
    padding: 0 10px;
    width: 80%;
    margin-left: 10%;
}

.place{
    padding: 2px 10px;
}

#contentBar-iHotelier-identifier{
    border: none;
    background: #e9e8e4;
    width: 169px;
}

.right-select-choice ::-webkit-input-placeholder {
    color: #333;
}

.right-select-choice :-moz-placeholder { /* Firefox 18- */
    color: #333;
}

.right-select-choice ::-moz-placeholder {  /* Firefox 19+ */
    color: #333;
}

.right-select-choice :-ms-input-placeholder {
    color: #333;
}

.showHotelsForSelect,
.showDatesSelect,
.showDatesForSelect{
    display: none;
}

.closeContentBar{
    display: none;
}

.telephonelink{
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 20px;
    margin: 0;
    display: block;
}

section.footer .textline a {
    display: block;
}
.dreierTeaser {
    visibility: visible;
}
section.dreier-teaser .dreierTeaser.withoutInfo {
    height: 22em;
}

/* TABLET */
@media (max-width: 1024px), screen and (max-width: 1280px), (max-height: 800px) {

    #page-header__date,
    #page-header__hotel,
    #page-header__hotel_bar,
    #page-header__date_bar {
        font-size: 14px;
    }
    #page-header__hotel {
        padding-top: 12px;
    }
    #page-header__hotel_bar {
        padding-top: 10px;
    }
    .header-bar__detail .form-inline .form-group {
        margin-bottom: 11px;
    }
    .right-select-choice label {
        font-size: 12px;
    }
    .right-select-choice select {
        width: 66px;
    }
    #contentBar-iHotelier-identifier {
        width: 130px;
    }
    section.zitat p {
        font-size: 27px;
        line-height: 32px;
    }
    section.dreier-teaser .top-headline {
        font-size: 34px;
    }
    section.dreier-teaser .topspace {
        padding-top: 15px;
    }
    section.mosaik h3 {
        font-size: 29px;
        margin-bottom: 25px;
    }
    section.mosaik .mosaik__text {
        padding: 16px 40px;
    }
    section.mosaik .redblock {

    }
    section.mosaik .righttextblock p,
    section.mosaik .redblock p {
        font-size: 15px;
        line-height: 21px;
    }
    .wetter-slide .datarow div {
        font-size: 20px;
    }
    .wetter-slide .datarow p {
        left: 38px;
    }
    .wetter-slide h2,
    .wetter-slide .h2 {
        font-size: 31px;
        margin-bottom: 27px;
    }
    .wetter-slide h3 {
        font-size: 21px;
    }
    section.footer a.vkb {
        font-size: 12px;
        margin: 0 0 0 43px;
        line-height: 11px;
    }
    section.footer a.vkb span {
        margin: 0 0 0 43px;
    }
    section.footer a.vkb span:after {
        font-size: 24px;
    }
    section.footer .copytext p {
        font-size: 12px;
    }
    section.footer form input.txt {
        height: 42px;
        font-size: 11px;
        line-height: 10px;
        width: 100%;
    }
    .sidebar-bottom .language span {
        padding: 26px 0;
    }
    .sidebar-bottom .language i {
        font-size: 11px;
        position: relative;
        left: 1px;
        top: 1px;
    }
    section.footer ::-webkit-input-placeholder {
        font-size: 11px;
        line-height: 10px;
    }
    section.footer ::-moz-placeholder { /* Firefox 19+ */
        font-size: 11px;
        line-height: 10px;
    }
    section.footer :-ms-input-placeholder {
        font-size: 11px;
        line-height: 10px;
    }
    section.footer form button.nlsubmit {
        top: -42px;
        height: 42px;
    }
    section.footer .textline {
        padding: 22px 0 0px;
    }
    section.footer .textline a,
    section.footer .textline p {
        font-size: 12px;
    }
    section.fullslider .slick-slide a.image span,
    section.fullslider .slick-slide a.image i {
        display: none;
    }
    section.bildreihe .bildreihe-img {
        width: 234px;
    }
    /*.start-here.closeFlag {*/
    /*    padding-top: 63px;*/
    /*}*/
    .start-here .isPortal {
        font-size: 27px;
        line-height: 24px;
    }
    .topstoerer,
    #topstoerer {
        font-size: 12px;
    }
    /*
    .topstoerer,
    #topstoerer {
        top: 10px;
        right: 10%;
        width: 150px;
        height: 150px;
        font-size: 15px;
        line-height: 22px;
        padding: 39px 32px;
    }

    .topstoerer:before,
    #topstoerer:before {
        top: 10px;
        left: 10px;
        width: 130px;
        height: 130px;
    }
    */
    section.pauschal-detail .price-badge {
        float: none;
        margin: 0 auto;
    }
    .mediarow .einser-block {
        min-height: 294px;
    }
    .mediarow .dreier-block {
        min-height: 129px;
    }
    .mediarow .einser-block-rechts {
        min-height: 129px;
    }
    .mediarow .einser-block-links {
        min-height: 258px;
    }
}
@media (max-width: 1024px), screen and (max-width: 1280px) {
    .main-nav__sub-column.full {
        width: 1340px;
    }
}
@media (max-width: 1024px), screen and (max-width: 1280px), (max-height: 800px){
    .hotelnav-switch .big-flags {
        background-size: 75px 225px;
        width: 75px;
        height: 75px;
        font-size: 14px;
    }
    .hotelnav-switch .big-flags.comfort {
        background-position: 0 -75px;
    }
    .hotelnav-switch .big-flags.smart {
        background-position: 0 -150px;
    }
    .hotelnav-switch ul li a {
        font-size: 14px;
    }
    .main-nav__sub-column.full .linktext {
        width: 75%
    }


    .start-here.hotel .isContent .info {
        font-size: 11px;
        line-height: 14px;
        margin-bottom: 6px;
    }

    .start-here .isContent .headline {
        font-size: 10px;
        line-height: 16px;
        margin-bottom: -1px;
    }

    .start-here .isContent .subline {
        font-size: 11px;
    }

    .inShow{
        top: -40px;
        width: 732px;
    }


    section.contact-slide.kontakt.background:after{
        left: 50.1%;
    }


    .nl-container {
        margin: 150px 13%;
    }

    #compare ul.vergleichsliste li {
        font-size: 12px;
    }

}

@media screen and (max-width: 767px){
    .nl-container {
        margin: 150px auto !important;
        width: 90%;
    }
}



/* MOBILE */
@media (max-width: 768px){
    #topMap {
        height: 220px;
    }

    .top-map{
        height: 220px;
    }

    .infoBox {
        display: none;
    }

    .mapInfo {
        display: none;
    }

    .top-map .mapControls {
        right: 10px;
    }

    .left_bullet ul li {
        float: none!important;
        width: 100%!important;
    }

    section.newsletter span.error {
        margin-left: 0px;
        display: block;
        margin-top: 15px;
    }

    form.nl-form button {
        margin-bottom: 15px;
    }

    .stylecheckbox{
        overflow: hidden;
    }

    .stylecheckbox label {
        width: 90%;
    }

    .bilreiheslider button.slick-prev,
    .bilreiheslider button.slick-next{
        display: none!important;
    }

    .bilreiheslider p{
        margin: 0;
    }

    .mobile-bt{
        margin-bottom: 25px;
    }

    section.mosaik .no-rightpadding-desktop{
        padding-right: 15px;
    }

    section.mosaik .no-leftpadding-desktop{
        padding-left: 15px;
    }

    section.distanz-map .distanz {
      height: auto;
    }

    .wetter-slide .col-xs-12 {
        border-bottom: 1px dotted #000;
        padding-right: 15px;
        border-right: none;
    }

    section.contact-slide{
        margin:10px 0 20px 0;
    }

    section.contact-slide .contactinfo p {
        margin-bottom: 15px;
    }

    section.footer .socialline a {
        font-size: 27px;
    }

    section.footer .socialline i {
        margin-right: 0;
        top: 0;
    }

    section.footer .leftpipe{
        position: relative;
    }

    section.footer .leftpipe:before{
        content: '';
        position: absolute;
        background: #353432;
        top: -12px;
        left: 0;
        width: 1px;
        height: 54px;
    }

    section.footer form input.txt{
        width: 100%;
        font-size: 17px;
        border-radius: 0;
    }

    section.footer ::-webkit-input-placeholder {
        font-size: 17px;
        line-height: 22px;
    }

    section.footer ::-moz-placeholder {  /* Firefox 19+ */
        font-size: 17px;
        line-height: 22px;
    }

    section.footer :-ms-input-placeholder {
        font-size: 17px;
        line-height: 22px;
    }

    .mobileNlSubsription{
        font-size: 18px;
        margin: 15px 0 15px 0;
        display: block;
        color: #71706e;
    }

    section.footer .lastrow {
        color: #71706e;
        font-size: 18px;
    }

    section.footer .lastrow .middle {
        margin: 0 15px 0 15px;
    }

    section.footer form{
        position: relative;
        margin-bottom: 15px;
    }

    section.footer form button.nlsubmit {
        position: absolute;
        top: 0;
    }


    .breiterTeaser {
        border-bottom: 1px dotted #000;
        padding-bottom: 10px;
    }

    .breiterTeaser strong:before {
        display: none;
    }

    section.fullslider .slick-prev,
    section.fullslider .slick-next {
        display: none!important;
    }

    section.fullslider .slick-slide a.image span,
    section.fullslider .slick-slide a.image i{
        display: none;
    }

    section.fullslider{

    }

    .slick-dots
    {
        position: relative;
        bottom: 0px;

        display: block;

        width: 100%;
        padding: 0;

        list-style: none;

        text-align: center;
    }
    .slick-dots li
    {
        position: relative;

        display: inline-block;

        width: 20px;
        height: 20px;
        margin: 0 5px;
        padding: 0;

        cursor: pointer;
    }
    .slick-dots li button
    {
        font-size: 0;
        line-height: 0;

        display: block;

        width: 20px;
        height: 20px;
        padding: 5px;

        cursor: pointer;

        color: transparent;
        border: 0;
        outline: none;
        background: transparent;
    }
    .slick-dots li button:hover,
    .slick-dots li button:focus
    {
        outline: none;
    }
    .slick-dots li button:hover:before,
    .slick-dots li button:focus:before
    {
        opacity: 1;
    }
    .slick-dots li button:before
    {
        font-family: 'slick';
        font-size: 38px;
        line-height: 20px;

        position: absolute;
        top: 0;
        left: 0;

        width: 20px;
        height: 20px;

        content: '•';
        text-align: center;

        opacity: .25;
        color: black;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .slick-dots li.slick-active button:before
    {
        opacity: .75;
        color: black;
    }

    section.bildreihe .bildreihe-img{
        float: none;
        clear: both;
        margin-bottom: 15px;
    }




    body.portal section.zitat:before{
        left: 42%;
    }

    .iconzitatholder{
        left: 44.5%;
    }


    body.portal .flagrow .flag {
        margin-right: 5px;
    }

    body.portal .starttable h2.left{
        margin-top: 10px;
    }

    body.portal .starttable p {
        font-size: 20px;
    }

    body.portal .distanz-map .hotelinfo {
        min-height: 309px;
        padding: 14px 0px 0;
    }

    .topstoerer,
    #topstoerer {
        display: none;
    }

    .zimmerdetails .popImage{
        margin-bottom: 15px;
    }

    section.restaurants figure,
    section.zimmerdetails figure {
        margin-bottom: 15px;
    }

    section.pauschal-detail .price-badge{
        float: none;
        margin: 0 auto;
    }

    section.pauschal-detail .black-button-block{
        margin-bottom: 25px;
    }

    .special-teaser{
        margin-bottom: 20px;
    }

    .mediarow .einser-block {
        min-height: 151px;
    }

    .mediarow .einser-block-links {
        min-height: 198px;
    }

    .hotelausstattungdetail .icn-badge,
    .pimcore_area_hotelausstattung .icn-badge,
    body.portal .hotel-standard .icn-badge{
        float: left;
    }

    section.top-bewertung {
        padding-top: 0px;
    }

    section.bewertungs-highlights:before {
        display: none;
    }

    section.top-bewertung .bewertungsbox {
        width: 137px;
        margin: 40px auto 0 auto;
    }

    section.top-bewertung .bewertungs-detail {
        text-align: center;
    }

    section.top-bewertung .bewertungs-text {
        text-align: center;
    }

    section.dreier-teaser .dreierTeaser {
        height: 477px
    }
    section.dreier-teaser .dreierTeaser.withoutInfo{
        height: 27em;
    }
    .pimcore_area_media a.image.video .showPlayButton{
        left: 43%;
    }



    h1 { margin-top: 0; }

    .trustyouWidget {
        top: 20px;
    }

    .starttable .flagrow .flag {
        width: 91px;
        height: 108px;
    }

    section.distanz-map .mapControls {
        right: 12px;
    }

    section.seminarraeume .konferenzpauschalen table tr td:first-child {
        width: auto;
    }




    .seminarraeume table caption { background-image: none; }

    section.seminarraeume .konferenzpauschalen tr {
        display: table-row;
    }

    section.seminarraeume .konferenzpauschalen table {
        width: auto;
        max-width: none;
    }

    .seminarraeume .konferenzpauschalen table tbody td ul {
        padding-left: 20px;
    }
    .seminarraeume .konferenzpauschalen table tbody td ul li { margin-bottom: 1em; }

    .seminarraeume .konferenzpauschalen  table tbody tr td:first-child { background: #666; }

    section.seminarraeume .konferenzpauschalen table td{
        width: 100%!important;
        border-top: 1px solid #ddd;
    }

    section.seminarraeume .konferenzpauschalen table td:first-child { border-top: none; }

    .konferenzpauschalen p { margin: 0; }
}

.vjs-default-skin .vjs-play-progress,
.vjs-default-skin .vjs-volume-level { background-color: #8c0f00 }

.vjs-default-skin .vjs-big-play-button{
    border: none;
    box-shadow: none;
    width: 100px;
    height: 100px;
    left: 50%;
    top: 50%;
    margin-left: -50px;
    margin-top: -50px;
    border-radius: 50%;
}


.vjs-default-skin .vjs-big-play-button:after{
    content: ' ';
    background: transparent;
    position: absolute;
    top:    10px;
    right:  10px;
    bottom: 10px;
    left:   10px;
    border-radius: 50%;
    border: 1px dashed #fff;
}
.vjs-default-skin .vjs-big-play-button:before{
    display: block;
    font-size: 45px;
    line-height: 2.25em;
    left: 4px;
    color: #fff;
    text-shadow: none;
}

.vjs-default-skin:hover .vjs-big-play-button,
.vjs-default-skin .vjs-big-play-button:focus{
}


.landselect{
    display: block;
    width: 100%;
}


#ui-datepicker-div{
    background: #ffffff;
}


.pauschalHotelTeaser{
    height: 450px;
    margin-bottom: 20px;
}

.pauschalHotelTeaser.klein{
    height: 294px;
}


/* LIGHTBOX */
.mfp-bg {
    opacity: 1;
    filter: alpha(opacity=100);
}
.mfp-container, .mfp-iframe-holder {
    padding:0 !important;
    overflow: hidden;
}
.mfp-content, .mfp-iframe-holder .mfp-content {
    padding:0;
    background: #fcfbf7;
    -webkit-box-shadow: inset 0px 0px 75px 10px rgba(0,0,0,0.2);
    -moz-box-shadow: inset 0px 0px 75px 10px rgba(0,0,0,0.2);
    box-shadow: inset 0px 0px 75px 10px rgba(0,0,0,0.2);
    width: 100%;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
}

.mfp-figure:after {
    display:none;
}
.mfp-iframe-scaler iframe {
    top: 80px;
    box-shadow: none;
    background: transparent;
}

img.mfp-img {
    padding:80px 0 0 0;
}
@media (min-width: 1200px){
    .mfp-content:before {
        content:' ';
        display: block;
        position: absolute;
        left:260px;
        top:-60px;
        height:238px;
        width:260px;
        background: url(/static/img/fahne.svg) 50% 0 no-repeat;
        z-index:2000;
    }
    .mfp-content:after {
        content:'Start here.';
        display: block;
        position: absolute;
        left:285px;
        width:230px;
        height:200px;
        top:12px;
        font-family: FS Emeric Light, sans-serif;
        font-size: 56px;
        color:#fff;
        line-height: 51px;
        z-index:2500;
        background:transparent;
        box-shadow:none;
    }
}
.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
    cursor:pointer !important;
    width: 100%;
    position:absolute;
    top:0;
    right:0;
    padding:0;
    height:80px;
    color: #fff;
    opacity: 1;
    filter: alpha(opacity=100);
    background:url(/static/img/austria-trend-hotels-logo.png) #fff 30px 20px no-repeat;
}



.mfp-image-holder .mfp-content{
    width: 100%;
}

.mfp-close:before, .mfp-close:after {
    content:' ';
    display: block;
    position: absolute;
    right:30px;
    top:35px;
    height:2px;
    width:30px;
    background:#241e12;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.mfp-close:after {
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.mfp-arrow {
    opacity: 1;
    filter: alpha(opacity=100);
    margin: 0;
    margin-top: -37px;
    width: 75px;
    height: 75px;
    background: rgba(0,0,0,.5);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    z-index: 5000;
    cursor: auto;
}
.mfp-arrow:active {
    margin-top: -36px;
}
.mfp-arrow:before {
    position: absolute;
    top: 34px;
    left: 49px;
    display:block;
    content: "\E004";
    font-family: iconfont;
    color:#fff;
    text-align: center;
    font-size:20px;
    margin:0 !important;
    border:0 !important;
    background:transparent !important;
    z-index:300;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.mfp-arrow:after {
    position: absolute;
    bottom:10px;
    top:10px;
    right:10px;
    left:10px;
    display:block;
    width:55px;
    height:55px;
    background-color:#231f14 !important;
    margin:0 !important;
    border:0 !important;
    z-index:250;
}
.mfp-arrow-left {
    left: -40px;
}
.mfp-arrow-right {
    right: -40px;
}
.mfp-arrow-right:before {
    position: absolute;
    top: 17px;
    left: auto;
    right: 43px;
    display: block;
    content: "\E005";
}
.mfp-counter {
    position: absolute;
    top: 0;
    right: 20px;
    color: #000;
    font-size: 18px;
    font-family: "FS Emeric Medium", sans-serif;
    white-space: nowrap;
} 
.mfp-title {
    font-size: 18px;
    font-family: "FS Emeric Medium", sans-serif;
    font-weight: normal;
    color: #fff;
    display: inline-block;
    background: rgba(0,0,0,0.7);
    padding: 10px 9px;
}

.mfp-ajax-holder .mfp-close:after,
.mfp-ajax-holder .mfp-close:before{
    display: none;

}


@media (max-width: 767px){
    .mfp-title {
        display:none;
    }
    .mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
        height:40px;
        background-size:95px 20px;
        background-position:10px 10px;
    }
    .mfp-iframe-scaler iframe {
        top:40px;
    }
    img.mfp-img {
        padding-top:40px;
    }
    .mfp-close:before, .mfp-close:after {
        right:10px;
        top:20px;
        width:20px;
    }
    button.mfp-arrow {
        margin-top:0;
    }

    button.mfp-arrow-left {
        top:152px;
        left: -30px;
    }

    button.mfp-arrow-right:active,
    button.mfp-arrow-left:active{
        margin-top: 0;
    }


    button.mfp-arrow-right {
        top:100px;
        right: -30px;
    }


    .mfp-arrow-left:before {
        top: 33px;
        left: 57px;
    }

    .mfp-arrow-right:before {
        top: 10px;
        right: 43px;
    }

    .jump-headline-mobile{
        font-size: 22px;
    }

    .jump-nav {
        height: auto;
    }

}


/* LIGHTBOX END */

.searchTeaser{

}

.cse .pagination a,
.searchTeaser h2,
.searchTeaser b{
    color: #8c0f00;
}

.navbar-collapse.collapse{
    display: none !important;
}

.closeHotelMainNav{
    position: relative;
    top: 0;
    right: 0;
    cursor: pointer;
}

.selected-hotels li:hover{
    font-family: "FS Emeric Bold", sans-serif;
}

section.contact-slide-hotelkontakt table.hotelInfoTable a.hotellink{
    color: #8c0f00 !important;
}


.leftfilter{
    position: relative;
}

.leftfilter.out:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(234,232,228,0.6);
}

.topBarText{
    height: 100px;
}

section.top-bewertung{
    background: #ffffff;
    min-height: 400px;
    padding-top: 90px;
}

section.top-bewertung .left-bewertung{
    padding-top: 35px;
}

section.top-bewertung .bewertungs-detail{
    display: block;
    font-family: FS Emeric Light, sans-serif;
    font-size: 18px;
}

section.top-bewertung .bewertungs-text{
    display: block;
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 32px;
    line-height: 16px;
}

section.top-bewertung .bewertungsbox{
    background: #f6f4f0;
    font-family: "FS Emeric Medium", sans-serif;
    font-size: 60px;
    line-height: 40px;
    text-align: center;
    height: 137px;
    padding-top: 32px;
}

section.top-bewertung .bewertungsbox span{
    display: block;
    font-family: FS Emeric Light, sans-serif;
    font-size: 18px;
}

section.top-bewertung .source-teaser{
    float: left;
    position: relative;
    margin-bottom: 25px;
}


section.top-bewertung .source-teaser h3{
    float: left;
    margin: 0;
    font-size: 18px;
    font-family: "FS Emeric Medium", sans-serif;
}

section.top-bewertung .source-teaser h4{
    float: left;
    margin: 0;
    font-size: 14px;
    font-family: FS Emeric Light, sans-serif;
    clear: both;
}

section.top-bewertung .source-teaser .details{
    float: right;
    font-size: 14px;
    font-family: FS Emeric Light, sans-serif;
    position: relative;
    top: -15px;
}

section.top-bewertung .source-teaser .details span{
    font-size: 25px;
    font-family: "FS Emeric Medium", sans-serif;
}

section.top-bewertung .source-teaser .points{
    float: right;
    position: relative;
    top: -20px;
}


section.top-bewertung .source-teaser .points .point{
    position: relative;
    float: left;
    margin-right: 7px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #dfdcd6;
    overflow: hidden;
}

section.top-bewertung .source-teaser .points .point.last{
    margin: 0;
}

section.top-bewertung .source-teaser .points .point.full{
    background: #008232;
}


section.top-bewertung .source-teaser .points .point.semicircle:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 7px;
    margin: 0 auto;
    background-color: #008232;
    -moz-border-radius: 100px 0 0 100px;
    -webkit-border-radius: 100px 0 0 100px;
}

section.top-bewertung .top-bewertungs-row{
    margin-bottom: 55px;
}

section.top-bewertung .top-bewertungs-row .trust-verify{
    position: relative;
    width: 192px;
    height: 36px;
    background: url('/buchungsportalicons/trustyou.jpg') no-repeat;
    margin: 48px auto 0 auto;
    background-size: 192px 36px;
}

section.bewertungs-highlights{
    position: relative;
    margin-top: 62px;
}

section.bewertungs-highlights .highlight-teaser{
    position: relative;
    margin-bottom: 25px;
}

section.bewertungs-highlights .highlight-teaser .topline .pull-left{
    font-size: 18px;
    font-family: "FS Emeric Medium", sans-serif;
}

section.bewertungs-highlights .highlight-teaser .topline .pull-right{
    font-size: 13px;
    font-family: FS Emeric Light, sans-serif;
    position: relative;
    top: 6px;
}

section.bewertungs-highlights .highlight-teaser .percentline{
    clear: both;
    position: relative;
    background: #dfdcd6;
    display: block;
    height: 30px;
}

section.bewertungs-highlights .highlight-teaser .percentline .full{
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    background: #008232;
}

section.bewertungs-highlights .highlight-teaser .percentline .numbers{
    position: absolute;
    top: -3px;
    left: 5px;
    font-size: 14px;
    font-family: FS Emeric Light, sans-serif;
    color: #ffffff;
}

section.bewertungs-highlights .highlight-teaser .percentline .numbers span{
    font-size: 20px;
    font-family: "FS Emeric Medium", sans-serif;
}

section.bewertungs-highlights .higlightcontainer{
    margin-bottom: 70px;
}

section.bewertungs-highlights .comments span.date{
    color: #c81900;
}

section.bewertungs-highlights .comments a.score{
    color: #c81900;
    font-family: inherit;
}

section.bewertungs-highlights .comments a.score .score__val{
    font-size: 20px;
    font-family: FS Emeric Medium,sans-serif;
}

section.bewertungs-highlights .comments{
    border-bottom: 1px dotted #3c4042;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

section.bewertungs-highlights .comments.last{
    border-bottom: none;
}

section.bewertungs-highlights h2{
    margin-bottom: 25px;
}

section.bewertungs-highlights .comments a{
    font-family: "FS Emeric Medium", sans-serif;
}

section.bewertungs-highlights .comments p{
    margin: 0;
}

section.bewertungs-highlights .bar .more{
    margin-bottom: 40px;
}

section.bewertungs-highlights:before {
    content: '';
    display: block;
    position: absolute;
    top: -161px;
    left: 46%;
    background: #f6f4f0;
    width: 60px;
    height: 60px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

section.jump-pfeil:before {
    position: absolute;
    top: -71px;
    left: 47.2%;
    width: 60px;
    height: 60px;
    content: "\E007";
    bottom: 216px;
    font-size: 30px;
    display: inline-block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000000;
    z-index: 50;
}



section.bewertungs-highlights.review:before {
    left: 48.5%;
}

section.jump-pfeil.review:before {
    left: 49.6%;
}



.bewertungsoffers{
    margin-bottom: 50px;
}

.trustyouWidget{
    position: absolute;
    top:auto;
    bottom: 10px;
    right: 0;
    display: flex;
    align-items: stretch;
}
@media screen and (min-width: 1400px) {
    .trustyouWidget {
        bottom: 70px;
    }
}
@media screen and (max-width: 767px) {
    .trustyouWidget.trustyouWidget {
        top: 0;
        left: 12px;
        right: auto;
        bottom: auto;
        padding: 0 7px 1px;
    }
}
.trustyouWidget__tripadvisor-coe-18,
.trustyouWidget__tripadvisor-tc-17,
.trustyouWidget__Holidaycheck,
.trustyouWidget__meetingsStartCertificate {
    display: inline-block;
    width: 60px;
    height: 60px;
    margin-right: 5px;
    overflow: hidden;
    background: url(/static/img/holidaycheckaward2017.png) no-repeat center center;
    background-size: contain;
    text-indent: -999em;
    vertical-align: middle;
}
.trustyouWidget__customerExcellence22 {
    display: inline-block;
    width: 100px;
    height: 100px;
    margin-right: 5px;
    overflow: hidden;
    background: url(/static/img/customerExcellence22.jpg) no-repeat center center;
    background-size: contain;
    text-indent: -999em;
    vertical-align: middle;
}
@media screen and (min-width: 1400px) {
    .trustyouWidget__customerExcellence22 {
        width: 130px;
        height: 130px;
    }
}
.trustyouWidget__tripadvisor-coe-18 {
    height: 80px;
    width: 108px;
    background-image: url(/static/img/tripadvisor-2018_COE.png);
}
.trustyouWidget__meetingsStartCertificate {
    background-color: #1e1d1b;
    height: 80px;
    width: 83px;
    background-image: url(/static/img/meetings-star-logo.png);
}
@media screen and (min-width: 1400px) {
    .trustyouWidget__tripadvisor-coe-18 {
        height: 100px;
        width: 134px;
    }
}
.trustyouWidget__tripadvisor-tc-17 {
    width: 63px;
    background-image: url(/static/img/Tripadvisor_TC_2017.png);
}

.trust-you-content-wrapper{
    background: #1e1d1b;
    color: #fff;
    padding: 8px 16px;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.trustyouWidget .trust-you {
    color: #fff;
    margin: auto;
}

.trustyouWidget-with-badge{
    position: absolute;
    top: 30px;
    right: 0;
    width: 221px;
    height: 110px;
}

.trustyouWidget-with-badge .left{
    position: relative;
    float: left;
    width: 110px;
    height: 110px;
    background: rgba(255,255,255,0.5);
    font-size: 14px;
    font-family: FS Emeric Light, sans-serif;
    text-align: center;
    line-height: 48px;
    padding-top: 12px;
    border-right: 1px solid #535457;
}

.trustyouWidget-with-badge .left span{
    position: relative;
    top: 20px;
    display: block;
    font-size: 42px;
    font-family: "FS Emeric Medium", sans-serif;
}

.trustyouWidget-with-badge .left .logo{
    position: absolute;
    top: 15px;
    right: 15px;
    width: 77px;
    height: 8px;
    background: url('/static/img/trust_top.png') no-repeat;
    background-size: 77px 8px;
}

.trustyouWidget-with-badge .right{
    position: relative;
    float: left;
    width: 110px;
    height: 110px;
    background: rgba(255,255,255,0.5);
    font-size: 14px;
    font-family: FS Emeric Light, sans-serif;
    text-align: center;
    line-height: 48px;
    /*padding-top: 12px;*/
}

.trustyouWidget-with-badge .right .logo{
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 72px;
    height: 73px;
}

.trustyouWidget-with-badge.trustyouWidget-with-badge--Tripadvisor .right .logo {
    background: url('/static/img/tripadvisor_excelence.png') no-repeat;
    background-size: 72px 73px;
    width: 72px;
    height: 73px;
}

.trustyouWidget-with-badge.trustyouWidget-with-badge--Holidaycheck .right .logo {
    background: url('/static/img/holidaycheckaward2017.png') no-repeat;
    background-size: 90px 90px;
    width: 90px;
    height: 90px;
}

.trustyouWidget-with-badge.trustyouWidget-with-badge--tripadvisor-tc-17 .right .logo {
    background: url('/static/img/Tripadvisor_TC_2017.png') no-repeat;
    background-size: 90px 86px;
    width: 90px;
    height: 86px;
}

.trustyouWidget-with-badge.trustyouWidget-with-badge--HolidaycheckRO .right .logo {
    background: url('/static/img/holidaycheck_ro2017.png') no-repeat;
    background-size: 90px 90px;
    width: 90px;
    height: 90px;
}

.restaurantGuruWidget,
.radissonBluWidget,
.courtyardWidget {
    position: absolute;
    top: 30px;
    right: 0;
    width: 329px;
    height: 110px;
}
.restaurantGuruWidget,
.radissonBluWidget,
.courtyardWidget {
    width: auto;
    height: auto;
    padding: 20px;
    padding-right: 50px;
    background: rgba(255,255,255,.4);
}
@media screen and (min-width: 768px) {
    .radissonBluWidget{
        max-width: 230px;
    }
    .restaurantGuruWidget {
        max-width: none;
    }
    .courtyardWidget {
        max-width: 170px;
        padding: 5px 30px;
    }
    .restaurantGuruWidget {
        padding: 10px;
        top: 10px;
        transform: scale(.6);
        transform-origin: top right;
    }
}
@media screen and (min-width: 1400px) {
    .courtyardWidget  {
        padding: 20px;
        padding-right: 50px;
        max-width: none;
    }
    .restaurantGuruWidget {
        padding: 10px;
    }
    .courtyardWidget  {
        padding: 5px 50px;
    }
    .restaurantGuruWidget {
        max-width: none;
        padding: 15px;
        top: 30px;
        transform: scale(.8);
    }
}
@media screen and (max-width: 767px) {
    .restaurantGuruWidget,
    .radissonBluWidget,
    .courtyardWidget {
        position: absolute;
        top: 0;
        right: 12px;
        left: auto;
        display: block;
        padding: 10px;
        text-align: center;
        max-width: 110px;
    }
    .restaurantGuruWidget {
        max-width: none;
        transform-origin: top left;
        transform: scale(.6);
    }
    .courtyardWidget {
        max-width: 100px;
    }
}


.extraBox .stylecheckbox input{
    display: none;
}

.extraBox .stylecheckbox span{
    cursor: pointer;
}

.extraBox .stylecheckbox label{
    float: left;
    width: 89%;
    cursor: auto;
}

.extraBox .stylecheckbox a{
    font-weight: bold;
}


.parsley-errors-list{
    display: none;
}

form.nl-form select.parsley-error,
form.nl-form input.parsley-error {
    border: 2px solid #a81400;
}

.logo-teaser{
    margin-bottom: 25px;
}

.mfp-ajax-holder{
    line-height: 1.1;
}

.overlayStart{
    margin-top: 200px;
}

.switchLink:hover,
.switchLink{
    display: inline;
    background: #991200;
    color: #fff;
    font-size: 16px;
    font-family: FS Emeric Light, sans-serif;
    padding: 10px 25px;
}

.padding-top-70{
    padding-top: 70px;
}

@media (max-width: 1024px), screen and (max-width: 1280px), (max-height: 800px){

    section.bewertungs-highlights.review:before {
        left: 47%;
    }

    section.jump-pfeil.review:before {
        left: 49%;
    }
}

@media (min-width: 768px) {
    .toTop-link { display: none; }
}





#nl-overlay{
    display: block;
    background: rgba(0,0,0,0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 500;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}


.nl-container{
    position: relative;
    margin: 150px 29%;
    display: block;
    width: 520px;
    min-height: 200px;
    padding: 0 30px 50px 30px;
    background-color: #edece7;
}

.nl-container:before{
    content: '';
    display: block;
    width: 100px;
    height: 100px;
    position: relative;
    top: -50px;
    margin: 0 auto;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background: #e0ddd6;
}

.nl-container:after{
    content: "\E01D";
    width: 100px;
    height: 100px;
    position: absolute;
    top: -22px;
    left: 50%;
    font-size: 39px;
    text-align: center;
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translateX(-50%);
}

.nl-container h2,
.nl-container .h2{
    margin-top: 0;
    font-weight: bold;
}

.close-nl-overlay{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 17px;
    height: 17px;
    cursor: pointer;
}

#nl-overlay ul{
    margin: 0;
    padding: 0 20px;
}

#nl-error *{
    color: #8f1000;
}

span.check-info-txt{
    display: inline-block;
    float: left;
    width: 89%;
    margin-left: 20px;
}

#popup-info{
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 44%;
    min-height: 50px;
    padding: 20px 10px;
    background: #ffffff;
    border: 1px solid #f6f4f0;
    z-index: 5000;
    -webkit-box-shadow: 0 8px 6px -6px #61605f;
    -moz-box-shadow: 0 8px 6px -6px #61605f;
    box-shadow: 0 8px 6px -6px #61605f;
    display: none;
}

#popup-info .close-pop-up{
    position: absolute;
    top: 5px;
    right: 5px;
    width: 13px;
    height: 13px;
    cursor: pointer;
    font-size: 13px;
}

#popup-info p{
    margin: 0 0 5px 0;
    font-size: 15px;
}

#popup-info .pimage{
    float: left;
    margin: 0 15px 7px 0;
}

.camp-hotels-text {
    color: #8c0f00;
    font-family: "FS Emeric Bold", sans-serif;
    bottom: -23px;
}

#compare ul.vergleichsliste{
    margin: 0;
    padding: 0;
}

#compare ul.vergleichsliste li{
    height: 55px;
    list-style: none;
    margin-bottom: 5px;
    border-bottom: 1px dotted #000000;
}

.compare-header{
    height: 280px;
}

.cpcol{
    padding: 0 5px;
}

.cpcheck{
    text-align: center;
    padding-top: 18px;
    font-size: 12px;
}

#compare ul.vergleichsliste li.cpcheck-title{
    text-align: center;
    font-size: 13px;
    height: 110px;
}

#compare ul.vergleichsliste li.cpcheck-title-left{
    height: 110px;
}

.nl-form .checkbox label{
    display: block;
    margin-left:20px;
}

.nl-form .checkbox label p{
    padding-right:10px;
}

.nl-form .checkbox label p + span.use{
    position:absolute;
    right:0;
    top:0;
}

.nl-form .checkbox input[type=checkbox]{
    margin-left:0;
}

.nl-form .checkbox label a{
    color: #8c0f00;
    border-bottom: 1px dotted #8c0f00;
}

.nl-form .checkbox label a:hover, .nl-form .checkbox label a:focus{
    color: #000;
    border-color: transparent;
}

.nl-form .select2-container{
	width: 100%!important;
}

.select2-container--default .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-radius: 0;
    border: none;
    min-height: 46px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
	background-color: #ece9e3;
    border-radius: 0;
}

.footerbloglink i{
    font-size: 50px;
    line-height: 0;
    vertical-align: -0.25em;
}
@media screen and (min-width: 768px) {
    .footerbloglink i{
        margin-left: 10px;
    }
}

.footerbloglink span{
    margin-left: 64px;
}

.zumblogteaser{
    position: absolute;
    z-index: 5;
}

.zumblogteaser a{
    width: 100%;
    text-align: center;
    font-weight: bold;
}

.zumblogteaser a:hover{
    color: #ffffff;
}

/* blog__tips */
section.blog__tips {
    background: #ffffff;
    padding: 4em 0;
    margin: 0;
}

.blog__tips h2 {
    margin-bottom: 20px;
    margin-top: 0;
}

.blog__teaser--high{
    min-height: 556px;
    margin-bottom: 25px;
}

/* =teaser--high */
.blog__teaser--high h3 { margin: 1.5em 0 1em; }

.blog__teaser--high__img-container { position: relative; }

.scaling-container { overflow: hidden; }

.hover-overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 1s ease;
    z-index: 5;
}

.blog__teaser--high .hover-overlay {
    transform: scaleX(1);
    transition: opacity 400ms ease-in-out, transform 400ms ease-in-out;
    transform-origin: left;
}

.blog__teaser--high:hover .hover-overlay {
    opacity: 1;
    transform: scaleX(1);
}
.blog__teaser--high__img-container .img-responsive{ transition: all .4s ease-out; }

.blog__teaser--high:hover .blog__teaser--high__img-container .img-responsive{ transform: scale(1.1); }

.blog__teaser--high__info-btn {
    bottom: 0.5em;
    display: block;
    position: absolute;
    right: 0.5em;
    width: 100px;
    height: 100px;
}

@media screen and (min-width: 768px) {
    .blog__teaser--high__info-btn {
        z-index: 9;
    }
}

.signrow .blog__teaser--high__info-btn{
    position: relative;
    width: 130px;
    height: 130px;
}

.signrow .blog__teaser--tooltip-box:after{
    top: -7px;
}

.signrow .blog__teaser--tooltip-box {
    top: 122%;
    bottom: auto;
}

.blog__teaser--high__info-btn i { font-size: 80px; }

.blog__teaser--high__info-btn .blog__teaser--tooltip-box i {
    font-size: 20px;
    line-height: 20px;
}

.blog__teaser--high__info-btn .author-info__list td {
    padding: 0.5em;
}

.blog__teaser--high__date { margin: 0; }

.blog__teaser--high .icon-stempel { color: #ffffff; }

.blog__teaser--high__link {
    font-family: 'FS Emeric Medium', sans-serif;
    color: var(--color-primary);
}
.blog__teaser--high__link:hover { color: #D6D6D6; }

.blog__teaser--tooltip-box {
    color: #000000;
    font-size: 16px;
    visibility: hidden;
    position: absolute;
    bottom: 100%;
    background: #ffffff;
    min-width: 180px;
    text-align: left;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 1em;
    z-index: -1;
}
.blog__teaser--tooltip-box:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -7px;
    left: 50%;
    background: #ffffff;
    width: 14px;
    height: 14px;
    transform: translateX(-50%) rotate(45deg);
}

.blog__teaser--tooltip-box header,
.blog__teaser--tooltip-box p { padding: 0.5em 1em; }

.blog__teaser--tooltip-box header {
    background: #40403F;
    color: #ffffff;
    padding: 0.5em 1em;
}

.blog__teaser--tooltip-box p { margin: 0; }

.blog__teaser--high__info-btn:hover .blog__teaser--tooltip-box {
    visibility: visible;
    z-index: 10;
}

.blog__author-info b,
.blog__teaser--tooltip-box b {
    font-family: "FS Emeric Light", sans-serif;
    font-weight: normal;
    text-transform: uppercase;
}

.author__portrait {
    display: inline-block;
    margin-bottom: 5px;
    border: 3px solid #ffffff;
    border-radius: 50%;
}

/* mobile */
@media screen and (max-width: 767px) {
    .blog__teaser--high{
        min-height: 20px;
        margin-bottom: 20px;
    }
}

.blog__more-btn {
    /*background: linear-gradient(to left, #991200 50%, #7A0E00 50%) ;
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all 1s ease;*/
    color: #ffffff;
    display: inline-block;
    margin-top: 2em;
    background: #991200;
    font-family: "FS Emeric Bold", sans-serif;
    font-size: 18px;
    padding:.5em 3em;
}
.blog__more-btn:hover {
    /*background-position: left bottom;*/
    background: #7A0E00;
    color: #ffffff;
}

.cse-tabs .nav-tabs{
    border-bottom: none;
}

.cse-tabs .nav-tabs>li>a{
    border: none;
    border-radius: 0;
    background: none;
    color: #ab1500;
    padding: 0 0 5px 0;
    margin-right: 40px;
}

.cse-tabs .nav-tabs>li.active>a,
.cse-tabs .nav-tabs>li.active>a:hover,
.cse-tabs .nav-tabs>li.active>a:focus {
    border: none;
    color: #000;

    border-bottom: 2px solid #000;
}

.cse-tabs .nav>li>a:hover,
.cse-tabs .nav>li>a:focus{
    background: none;
}

.cse-tabs .searchTeaser h2 {
    color: #262626;
    margin-top: 15px;
}

.cse-tabs .searchTeaser h2:hover {
    color: #ab1500;
}



.form-control.searchinput {
    height: 50px;
}


/*--------------------trustyouWidget--kayak-----------------*/
.trustyouWidget--kayak-info {
    background: #1e1d1b;
    color: white;
    padding: 8px 16px;
    font-family: var(--font-default-light);
    margin: 4px 0;
    font-size: 14px;
}
.trustyouWidget--kayak-container {
    text-align: -webkit-center;
}
.trustyouWidget--kayak-score {
    font-family: var(--font-default-bold);
}

@media screen and (min-width: 767px) {
    .trustyouWidget--kayak {
        right: 10px;
    }
    .trustyouWidget--kayak-info {
        line-height: 1;
        margin-top: 10px;
    }
}

@media screen and (max-width: 1199px) and (min-width: 767px) {
    .trustyouWidget--kayak-img {
        width: 100px;
    }
}

@media screen and (min-width: 1200px) {
    .trustyouWidget--kayak-img {
        width: 130px;
    }
}

@media screen and (min-width: 1400px) {
    .trustyouWidget--kayak {
        bottom: 20px;
    }
    .trustyouWidget--kayak-score {
        font-size: 24px;
    }
    .trustyouWidget--kayak-info {
        width: 70%;
        font-size: 12px;
    }
}

@media screen and (max-width: 767px) {
    .trustyouWidget--kayak-img {
        width: 100px;
        position: absolute;
        left: 8px;
        top: 50px;
    }
    .trustyouWidget--kayak-info {
        margin: 0;
    }
    .trustyouWidget--kayak-info, .trustyouWidget--kayak-score {
        font-size: 12px;
        line-height: 1.7;
    }
}