
.inline-datepicker {
    position: relative;
    padding: 15px 0;
}
.inline-datepicker__cal + .inline-datepicker__cal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.inline-datepicker__cal {
    padding-top: 25px;
    transition: all 300ms 100ms ease, visibility 0.01ms;
    will-change: opacity;
}
.inline-datepicker__cal.is-hidden {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-50px);
    transition: all 300ms ease, visibility 0.01ms 300ms;
}
.inline-datepicker__cal + .inline-datepicker__cal.is-hidden {
    transform: translateY(50px);
}