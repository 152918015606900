.main-nav {
    font-size: 16px;
}
@media screen and (min-width: 1500px) and (min-height: 800px) {
    .main-nav {
        font-size: 18px;
        margin-top: 95px;
    }
    .hotelseite .main-nav{
        margin-top: 0;
    }
    .menubloglink span{
        font-size: 18px;
        margin-left:30px;
    }
}
.main-nav--no-margin,
.main-nav.main-nav--no-margin {
    margin-top: 0;
}

@media screen and (max-width: 767px) {
    .main-nav {
        display: none;
    }
}


/***Main Nav List***/
.main-nav__list {
    margin-bottom: 73px;
}
.main-nav__list--mb-small {
    margin-bottom: 40px;
}

.main-nav__headline ~ .main-nav__list {
    margin-top: 17.85%;
}

.main-nav__list-item,
.main-nav__list>ul>li {
    display: block;
    position: relative;
    font-family: "FS Emeric Bold", sans-serif;
}

.main-nav__list-text,
.main-nav__list>ul>li>a,
.main-nav__list>ul>li>span {
    padding-left: 20px;
    padding-right: 20px;
    text-transform: uppercase;
    cursor: pointer;
}
@media screen and (min-width: 1500px) {
    .main-nav__list-text,
    .main-nav__list>ul>li>a,
    .main-nav__list>ul>li>span {
        padding-left: 30px;
        padding-right: 30px;
    }
}
.main-nav__title {
    font-size: 20px;
    line-height: 1.5;
    color: #1E1D1B;
}
.main-nav__title:after {
    content: '';
    display: block;
    width: 31px;
    height: 1px;
    margin-top: 0;
    border-bottom: 1px dotted;
}

/*Triangle*/
.main-nav__list>ul>li>a:before,
.main-nav__list>ul>li>span:before,
.main-nav__list-text:before {
    content: '';
    display: block;
    position: absolute;
    left: 100%;
    top: 50%;
    margin-top: -7px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 0 15px 15px;
    border-color: transparent transparent transparent #ffffff;
    z-index: -1;
    transform: translateX(-100%);
    will-change: transform;
    transition: transform 120ms ease;
}

/*Hover & Active State*/
.main-nav__list-text:hover,
.is-open>.main-nav__list-text {
    font-family: "FS Emeric Bold", sans-serif;
}

.main-nav__list a.active{
    font-family: "FS Emeric Bold", sans-serif;
}

.main-nav__list>ul>.is-open>a:before,
.main-nav__list>ul>.is-open>span:before,
.is-open>.main-nav__list-text:before {
    transform: translateX(0);
}

@media (hover: hover) and (pointer: fine) {
    li.expand:hover>a:before,
    li.expand:hover>span:before {
        transform: translateX(0);
    }
}

/*Headline*/
.main-nav__headline {
    margin: 30px 0 0 0;
    padding-top: 30px;
    font-size: 21px;
    position: relative;
    overflow: hidden;
}
.main-nav__headline:before {
    content: '';
    display: block;
    position: absolute;
    top: -220px;
    left: 0;
    right: 0;
    height: 220px;
    box-shadow: 0 0 30px rgba(155, 136, 104,0.52);
    border-radius: 100px / 60px;
}
@media screen and (min-width: 1500px) {
    .main-nav__headline {
        font-size: 23px;
    }
}
@media screen and (max-height: 880px){
    .main-nav__list {
        margin-bottom: 20px;
        margin-top: 20px;
    }
}
@media screen and (max-height: 610px) and (min-width: 768px){
    .main-nav__list.main-nav__list {
        margin-bottom: 5px;
        margin-top: -11px;
    }

    .page-sidebar__logo.page-sidebar__item{
        width: 91%;
    }
}
/*Subnav*/
.main-nav__sub {
    /*for more styles see main-nav__sub.css*/
    display: none;
}

/*mobile*/
.mobile-nav-toggle {
    height: 50px;
    margin: 0;
    line-height: 50px;
    text-align: center;
}
.mobile-nav-toggle .navbar-toggle {
    margin: 0;
}
.mobile-nav-btn {
    height: 50px;
    margin: 0;
    line-height: 50px;
    border-left: 1px solid #cccccc;
    text-align: center;

}

.youLoginLeft {
    background: #8c0f00;
    position: relative;
    text-align: center;
    color: #ffffff;
    font-family: "FS Emeric Medium", sans-serif;
    padding: 6px 0;
}

.inShow a,
.youLoginLeft a,
.youLoginMiddle a {
    display: block;
    width: 100%;
    height:100%;
}

.youLoginMiddle{
    background: #8c0f00;
    position: relative;
    text-align: center;
    color: #ffffff;
    font-family: "FS Emeric Medium", sans-serif;
    padding: 6px 0;
    margin-top: 15px;
    width: 80%;
}

.inShow{
    position: absolute;
    top: -42px;
    width: 940px;
    background: #8c0f00;
    text-align: center;
    color: #ffffff;
    font-family: "FS Emeric Medium", sans-serif;
    padding: 6px 0;
    display: none;
}

.menubloglink span{
    font-family: FS Emeric Light,sans-serif;
    margin-left:20px;
    text-transform: uppercase;
    font-size: 14px;
}


.menubloglink:hover span{
    font-family: "FS Emeric Bold", sans-serif;
}
.menubloglink i{
    position: relative;
    top: 22px;
    left: 23px;
    font-size:50px;
}