.page-sidebar {
    /*display: none;*/
}
.page-sidebar__logo {
    display: block;
}
.page-sidebar__bottom {
    display: block;
}
@media screen and (max-width: 767px) {
    .page-sidebar {
        position: relative;
        z-index: 3;
    }
    .page-sidebar__header {
        background-color: #ffffff;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
        overflow: hidden;
    }
    .page-sidebar {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background: #ffffff;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
        max-height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }
    body {
        padding-top: 50px;
    }

    /*
    .page-sidebar {
        box-shadow: 0 0 10px rgba(0,0,0,.2);
        position: fixed;
        left: 0;
        top: 0;
        z-index: 1024;
        overflow: scroll;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
    }
    */

    .mobile-main-nav--dark {
        background-color: #1e1d1b;
        color: #ffffff;
    }

    .page-sidebar__logo>img {
        height: 50px;
        width: auto;
        max-width: none;
        padding: 10px 10px 10px 12px;
        margin: auto;
    }
}
@media screen and (min-width: 768px) {
    .page-sidebar {
        display: block;
        position: fixed;
        width: 220px;
        top: 0;
        left: 0;
        bottom: 0;
        background: #fff;
        z-index: 200;
        padding-top: 20px;
        box-shadow: 0 0 30px rgba(0,0,0,.1);
        /*transform: translateZ(0);*/
    }
    .page-sidebar:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: inherit;
        z-index: -1;
    }
    .page-sidebar__logo {
        margin-bottom: 25px;
    }
    .page-sidebar__item {
        padding-left: 20px;
        padding-right: 20px;
    }
    .page-sidebar__bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
@media screen and (min-width: 1200px){
    .page-sidebar {
        width: 280px;
    }
}
@media screen and (min-width: 1500px) {
    .page-sidebar {
        width: 280px;
    }
    .page-sidebar__item {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media screen and (min-width: 768px) {
    .page-sidebar--not-fixed {
        position: absolute;
    }
}
