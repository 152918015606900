:root {
    --default-margin: 30px;
}
.content-block {
    margin-top: calc(var(--default-margin)/2);
    margin-bottom: calc(var(--default-margin)/2);
}
.content-box {
    padding-top: calc(var(--default-margin)/2);
    padding-bottom: calc(var(--default-margin)/2);
}
.slide {
    padding-top: var(--default-margin);
    padding-bottom: var(--default-margin);
}
.section {
    margin-top: var(--default-margin);
    margin-bottom: var(--default-margin);
}

@media screen and (min-width: 768px) {
    .content-block {
        margin-top: calc(var(--default-margin)*2/3);
        margin-bottom: calc(var(--default-margin)*2/3);
    }
    .content-box {
        padding-top: calc(var(--default-margin)*2/3);
        padding-bottom: calc(var(--default-margin)*2/3);
    }
    .slide {
        padding-top: calc(var(--default-margin) * 1.2);
        padding-bottom: calc(var(--default-margin) * 1.2);
    }
    .section {
        margin-top: calc(var(--default-margin)*4/3);
        margin-bottom: calc(var(--default-margin)*4/3);
    }
}

@media screen and (min-width: 1200px) {
    .content-block {
        margin-top: var(--default-margin);
        margin-bottom: var(--default-margin);
    }
    .content-box {
        padding-top: var(--default-margin);
        padding-bottom: var(--default-margin);
    }
    .slide {
        padding-top: calc(var(--default-margin) * 1.5);
        padding-bottom: calc(var(--default-margin) * 1.5);
    }
    .section {
        margin-top: calc(var(--default-margin) * 2);
        margin-bottom: calc(var(--default-margin) *     2);
    }
}

.margin-bottom-20{
    margin-bottom: 20px;
}
.margin-top-20{
    margin-top: 20px;
}

.start-here{
    display: block;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 210px;
    width: 260px;
    height: 238px;
    color: #ffffff;
    background: url('/static/img/fahne.svg') no-repeat;
    background-size: contain;
    background-position: top left;
    padding: 0 25px 25px;
    padding-top: 15px;
    transition: opacity,top 0.5s;
}
.start-here.closeFlag {
    top: -40px;
    padding-top: 53px;
}

.start-here .isPortal{
    font-family: var(--font-default-light);
    font-size: 44px;
    line-height: 1;
}

.start-here--small {
    width: 156px;
    height: 143px;
    background-size: 156px 143px;
    font-size: 25px;
    line-height: 1;
    padding: 15px 0 15px 15px;
}
.start-here--small .isPortal {
    font-size: 25px;
}

.start-here.start-here--small.closeFlag {
    padding-top: 15px;
    top: 0;;
}


.start-here .isContent{
    display: none;
}

.start-here .isContent .headline{
    text-transform: uppercase;
    font-family: "FS Emeric Bold", sans-serif;
    font-size: 21px;
    line-height: 26px;
    display: block;
    margin-bottom: 5px;
}

.start-here .isContent .subline{
    text-transform: uppercase;
    font-family: FS Emeric Light, sans-serif;
    font-size: 17px;
    display: block;
}

.start-here.hide-me{
    opacity: 0;
    top: -500px;
}
@media (hover: hover) and (pointer: fine) {
    .start-here.hide-me-hover{
        opacity: 0;
        top: -500px;
        display: none;
    }
}

.sidebar-bottom span{
    background: #e9e8e4;
    display: block;
    height: 60px;
    position: relative;
    cursor: pointer;
    width: 99%;
    text-align: center;
}

.sidebar-bottom{
    padding-left: 15px;
    padding-right: 14px;
}


.sidebar-bottom .col-xs-2,
.sidebar-bottom .col-xs-5,
.sidebar-bottom .col-sm-2,
.sidebar-bottom .col-sm-5{
    padding: 0;
}

.sidebar-bottom .profile{
    border-right: 1px solid #ffffff;
    border-left: 1px solid #ffffff;
    text-align: center;
}

.sidebar-bottom .language{
    text-align: center;
}

.sidebar-bottom .language.open .dropdown-menu{
    top: auto;
    bottom: 100%;
    left: -164px;
    width: 280px;
}

.sidebar-bottom .profile span{
    padding: 16px 0;
}

.sidebar-bottom .language span{
    padding: 22px 0 20px 0;
}

.sidebar-bottom .language i,
.sidebar-bottom .profile i{
    font-size: 26px;
}

.sidebar-bottom .language i.icon.icon-tick {
    font-size: 8px;
    position: relative;
    left: 0;
    top: 0;
    margin-right: 7px;
}

.sidebar-bottom .language i{
    font-size: 16px;
    position: relative;
    left: 5px;
    top: 3px;
}

.sidebar-bottom i.search{
    font-size: 26px;
    left: 0;
    right: 0;
    top: 16px;
    position: absolute;
}

.sidebar-bottom .language .dropdown-menu{
    border: none;
    box-shadow: none;
    padding: 0;
}

.sidebar-bottom .language .dropdown-menu li a{
    padding-left: 37px;
}

.sidebar-bottom .language .dropdown-menu li a.active,
.sidebar-bottom .language .dropdown-menu li:hover a.active i,
.sidebar-bottom .language .dropdown-menu li:hover a,
.sidebar-bottom .language .dropdown-menu li:hover a i{
    background: none;
    color: #8c0f00;
}

.sidebar-bottom .language .dropdown-menu li a.active{
    padding-left: 18px;
}

.sidebar-bottom .language .dropdown-menu li{
    border-top: 1px solid #e8e9e4;
    padding: 10px 0;
}


@media (max-width: 1024px), screen and (max-width: 1280px) {
    .container {
        width: 100%;
    }
}
@media (max-width: 1024px), screen and (max-width: 1280px), (max-height: 800px){
    .start-here {
        width: 156px;
        height: 143px;
        background-size: 156px 143px;
        font-size: 25px;
        line-height: 1;
        padding: 15px 0 15px 15px;
    }
    .start-here.closeFlag {
        padding-top: 15px;
        top: 0;
    }

    .main-nav__list {
      margin-top: 8px;
    }

    .nav-list>ul>li>a{
        font-size: 14px;
        padding-top: 6px;
    }

    .main-nav__list-text{
        font-size: 14px;
    }

    .start-here .isContent .subline {
        font-size: 11px;
    }


    .start-here.hotel .isContent .info {
        font-size: 11px;
        line-height: 14px;
        margin-bottom: 6px;
    }

    .start-here .isContent .headline {
        font-size: 10px;
        line-height: 16px;
        margin-bottom: -1px;
    }
}

@media screen and (min-width: 1200px){
    .start-here {
        left: 260px;
    }
}


/* show elements */
@media screen and (min-width: 768px){
    .page-header-bar {
        display:block;
    }
}
.top-slider {
    opacity: 1;
}



.hoteluebersicht{
    padding-top: 142px;
}

.hoteluebersicht.hotelnav-switch .big-flags {
    /*background-size: 45px 135px;*/
    /*width: 45px;*/
    /*height: 45px;*/
    /*font-size: 9px;*/
    /*padding-top: 12px;*/
    margin-right: 6px;
    margin-bottom:10px;
}

/*.hoteluebersicht.hotelnav-switch .big-flags.smart {*/
    /*background-position: 0 0;*/
/*}*/