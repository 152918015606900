.map-text-marker {
    cursor: pointer;
    white-space: nowrap;
    background-color: #4a4a4a;
    color: #ffffff;
    transform: translate(-50%, -100%);
    font-family: var(--font-default-medium);
    font-size: 14px;
    margin-top: -7px; /*arrow height*/
}
.map-text-marker__box {
    padding: 8px 9px;
    text-align: center;
}
@media screen and (min-width: 768px) {
    .map-text-marker__box {
        max-width: 175px;
        min-width: 140px;
    }
    .map-text-marker {
        white-space: normal;
    }
    .map-text-marker__box .star-rating {
        vertical-align: baseline;
    }
}
.map-text-marker:before,
.map-text-marker:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7px 0 7px;
    border-color: #4a4a4a transparent transparent transparent;
}
.map-text-marker__close {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    padding: 0;
    border-radius: 50%;
    background: #ffffff;
    color: var(--color-primary);
    border: none;
    font-size: 22px;
    line-height: 1;
    z-index: 5;
}


/*disabled*/
.map-text-marker--disabled {
    background-color: #9d9d9d;
}
.map-text-marker--disabled:after {
    border-color: #9d9d9d transparent transparent transparent;
}


/*active*/
.map-text-marker.is-active {
    z-index: 4;
}
@media screen and (max-width: 767px) {
    .map-text-marker.is-active {
        background-color: #8c0f00;
        box-shadow: 0 1px 0 #000000;
    }
    .map-text-marker.is-active:before {
        border-width: 8px 8px 0 8px;
        border-color: #000 transparent transparent transparent;
    }
    .map-text-marker.is-active:after {
        border-color: #8c0f00 transparent transparent transparent;
    }
}
@media screen and (min-width: 768px) {
    .map-text-marker.is-active:before {
        margin-top: -1px;
        border-width: 8px 8px 0 8px;
        border-color: #494949 transparent transparent transparent;
    }
    .map-text-marker.is-active:after {
        margin-top: -2px;
        border-color: #ffffff transparent transparent transparent;
    }
    .map-text-marker.is-active .map-text-marker__box {
        display: none;
    }
    .map-text-marker__detail {
        position: relative;
        display: none;
        background: #ffffff;
        color: #000;
        width: 230px;
        border-bottom: 2px solid #494949;
    }
    .map-text-marker.is-active .map-text-marker__detail{
        display: block;
    }
}

