.radio-list {
    margin: 0;
    padding: 0;
    list-style: none;
}
.radio-list__item {
    position: relative;
    padding-top: 2px;
    padding-bottom: 2px;
}
.radio-list__active-border {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -10px;
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #d1cec8;
    opacity: 0;
}
.radio-list__active-border-arrow {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-2px, -50%);
    background: #ffffff;
    width: 16px;
    height: 15px;
    overflow: hidden;
    border-left: 2px solid #fff;
}
.radio-list__active-border-arrow:after {
    content: '';
    display: block;
    width: 11px;
    height: 11px;
    /*background: red;*/
    transform: rotate(45deg) ;
    transform-origin: center top;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -4px;
    margin-left: -1px;
    box-shadow: inset 0 1px 4px rgba(0,0,0,.1);
    border: 1px solid #d1cec8;
}
input:checked ~ .radio-list__active-border {
    opacity: 1;
}
