/*Subnav*/
/*:root {
    --main-nav-animation-duration: 200ms;
    --main-nav-sub-width: 230px;
    --main-nav-sub-width--mq-1500: 300px;

    --base-layout-sidebar-width: 250px;
    --base-layout-sidebar-height-mobile: 50px;
    --base-layout-sidebar-width--mq-1500: 280px;
}*/




.main-nav__sub {
    display: block;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 220px;
    bottom: 0;
    transform: translateX(-100%);
    transform: translateX(-100%) translateZ(0);
    /*opacity: 0;*/
    z-index: -2;
    visibility: hidden;
    will-change: transform;
    transition: opacity 200ms, transform 200ms ease, visibility .1ms 200ms;
    background: #eceae6;
    webkit-overflow-scrolling: touch;
    white-space: nowrap;
}
@media screen and (min-width: 1200px) {
    .main-nav__sub {
        left: 280px;
    }
}
.main-nav__sub:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    z-index: -2;
}
.main-nav__sub--full-width {
    right: 0;
}

/*is open*/
.is-open>.main-nav__sub {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
    transform: translateX(0) translateZ(0);
    transition: opacity 200ms, transform 200ms ease, visibility .1ms;
}
@media (hover: hover) and (pointer: fine) {
    li.expand:hover .main-nav__sub {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
        transform: translateX(0) translateZ(0);
        transition: opacity 200ms, transform 200ms ease, visibility .1ms;
    }
    li.expand:hover>a {
        font-family: "FS Emeric Bold", sans-serif;
    }
    li.expand:hover:before {
        visibility: visible;
        opacity: .5;
        transition: visibility 0.1ms, opacity 200ms ease;
    }
}
.main-nav__list-item:before,
.main-nav__list>ul>li:before {
    /*overlay*/
    content: '';
    position: fixed;
    background: #1e1e1e;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -3;
    visibility: hidden;
    opacity: 0;
    transition: opacity 200ms ease, visibility 0.01ms 200ms;
    transform: translateZ(0); /*needed for chrome*/
}
.main-nav__list-item.is-open:before,
.main-nav__list>ul>.is-open:before {
    visibility: visible;
    opacity: .5;
    transition: visibility 0.1ms, opacity 200ms ease;
}


.main-nav__sub-column {
    width: 230px;
    display: inline-block;
    vertical-align: top;
    /*float: left;*/
    height: 100%;
    white-space: normal; /*reset from main-nav__sub*/
    overflow-x: hidden;
    overflow-y: auto;
}

.main-nav__sub-column.full {
    width: 1450px;
}
@media screen and (min-width: 1300px) {
    .main-nav__sub-column.full {
        width: 2000px;
    }
}
.main-nav__sub-column.full .main-nav__sub-column-body {
    padding: 45px 30px;
}
@media screen and (min-height: 800px) {
    .main-nav__sub-column.full .main-nav__sub-column-body {
        padding: 90px 40px;
    }
}


@media screen and (min-width: 1500px) {
    .main-nav__sub-column {
        width: 280px;
    }
}
.main-nav__sub-column-body {
    padding-left: 13%;
    padding-right: 10%;
    padding-top: 26%;
}
.main-nav__sub-close {
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 28px;
    padding: 10px;
}

/*Subsub nav*/
.main-nav__sub .main-nav__sub {
    left: 230px;
}

@media screen and (min-width: 1500px) {
    .main-nav__sub .main-nav__sub {
        left: 280px;
    }
}
.main-nav__sub .main-nav__sub--full-width {
    right: auto;
}

/* IE 11 hack */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .main-nav__sub .main-nav__sub {
        left: 560px;
    }
}