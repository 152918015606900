.badge {
    border-radius: 0;
    background-color: #eae8e4;
    color: inherit;
}

.bubble-badge {
    display: inline-block;
    border-radius: 50%;
    min-width: 24px;
    line-height: 24px;
    text-align: center;
    background-color: #1e1d1b;
    color: #ffffff;
    font-family: var(--font-default-bold);
    font-size: 13px;
}

/* todo this is code from the master branch, upper is from migration which one is correct ? */
.badge {
    font-size: .8em;
    border: none;
    font-family: inherit;
    font-weight: inherit;
    padding: 5px 10px;
    border-radius: 9.7px;
}
.badge__icon {
    font-size: 0.65em;
    vertical-align: -0.05em;
    margin-left: .2em;
}
button.badge:hover {
    background-color: #8c0f00;
}
