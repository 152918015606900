.quick-filter {
    color: #4a4a4a;
    font-size: 14px;
    line-height: calc(18 / 14);
    padding: 2px 5px 1px 5px;
    background-color: #ffffff;
    border: none;
    margin-right: 5px;
}
.quick-filter__x {
    content: '';
    font-family: var(--font-default-bold);
    color: var(--color-danger);
    margin-left: 5px;
}