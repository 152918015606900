.panomax {
    margin-bottom: 35px;
}
.panomax__cam {
    height: 450px;
    position: relative;
    overflow: hidden;
}
.panomax__bar {
    font-size: 17px;
    padding: 10px;
    color: #fff;
    background: #9b8868 url('/static/img/pattern_gold.png');
    background-size: 40px 38px;
    background-repeat: repeat;
}
.panomax__icon {
    font-size: 25px;
    margin-right: 10px;
    vertical-align: -0.2em;
}
.panomax__item {
    margin: 7px 0;
}
@media screen and (min-width: 768px) {
    .panomax__bar {
        font-size: 20px;
        padding: 15px;
    }
    .panomax__icon {
        font-size: 35px;
        margin-right: 10px;
        vertical-align: -0.3em;
    }
    .panomax__item {
        margin: 13px 0;
    }
}
.panomax__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
.panomax__cam-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-position-y: center;
    transition: transform 120ms ease;
    animation: infinite-image 120s linear 4s infinite;
}
.panomax__cam-bg.error {
    position: relative;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    animation: inherit;
    transition: inherit;
    top: 200px;
}
@keyframes infinite-image {
    0% {
        transform: translateX(0)
    }
    100% {
        transform: translateX(-66.66666%);
    }
}
.panomax__hover-bg {
    position: absolute;
    display: block;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .4);
    transition: all 1s ease;
}
.panomax__cam:hover .panomax__hover-bg {
    opacity: 1;
}
.panomax__hover-bg i {
    pointer-events: none;
    color: #fff;
    position: relative;
    display: table;
    text-align: center;
    left: 50%;
    width: 21px;
    height: 21px;
    z-index: 5;
    opacity: 0;
    transition: all 1s ease;
    font-size: 34px;
    margin-top: -64px;
    margin-left: -14px;
    top: 51.3%;
}
.panomax__cam:hover .panomax__hover-bg i {
    opacity: 1;
}
.panomax__hover-bg:before {
    pointer-events: none;
    content: '';
    position: relative;
    display: block;
    top: 50%;
    left: 50%;
    margin-left: -37px;
    margin-top: -37px;
    width: 75px;
    height: 75px;
    background: rgba(0, 0, 0, .5);
    transform: rotate(45deg);
}
.panomax__hover-bg:after {
    pointer-events: none;
    content: '';
    position: relative;
    display: block;
    top: 50%;
    left: 50%;
    margin-left: -27px;
    margin-top: -35px;
    width: 55px;
    height: 55px;
    background: rgba(0, 0, 0, .8);
    transform: rotate(45deg);
}