section.top-slider {
    margin-bottom: 35px;
    position: relative;
}

section.top-slider {
    position: relative;
    min-height: 6em;
    /*margin-bottom: -189px;*/
}

section.top-slider h1,
section.top-slider h2{
    position: absolute;
    bottom: 125px;

    text-align: center;
    font-size: 52px;
    color: #ffffff;
    left: 0;
    right: 0;
    text-shadow: 1px 1px 1px #a7a7a7;
}

section.top-slider h1.black,
section.top-slider h2.black{
    color: #333333;
}


section.top-slider h1:after,
section.top-slider h2:after{
    content: '';
    position: relative;
    top: 29px;
    display: block;
    width: 100%;
    height: 7px;
    background: url('/static/img/white_line.png') no-repeat center;
}

section.top-slider h1.black:after,
section.top-slider h2.black:after{
    background: #333333;
}

section.top-slider h3{
    position: absolute;
    bottom: 60px;
    text-align: center;
    font-size: 18px;
    color: #ffffff;
    font-family: "FS Emeric Bold", sans-serif;
    left: 0;
    right: 0;
    text-shadow: 1px 1px 1px #a7a7a7;
}

section.top-slider h3.black{
    color: #333333;
}

.white section.top-slider h3:after{
    background: #ffffff;
}

body.portal section.top-slider h3:after{
    background: #aa1500;
}

section.top-slider.no-image:after {
    content: "";
}

section.top-slider .slick-prev, section.top-slider .slick-next {
    position: absolute;
    top: 50%;
    margin-top: -30px;
    left: -30px;
    border: none;
    background: none;
    width: 60px;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.5);
    transform: rotate(45deg);
    text-indent: -99em;
}
section.top-slider .slick-next {
    left: auto;
    right: -30px;
}

section.top-slider .slick-prev:after, section.top-slider .slick-next:after {
    content: "\E005";
    position: absolute;
    top: 5px;
    right: 13px;
    width: 12px;
    height: 20px;
    line-height: 12px;
    transform: rotate(-225deg);
    display: inline-block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000000;
    text-indent: 0;
}

section.top-slider .slick-next:after{
    top: 34px;
    left: 15px;
    right: auto;
    transform: rotate(-45deg);
}


body.portal section.top-slider {
    margin-bottom: 0;
    z-index: 2;
}

body.portal section.top-slider h1 {
    position: absolute;
    bottom: 100px;
    text-align: center;
    font-size: 52px;
    color: #ffffff;
    padding: 0 36%;
    font-weight: normal;
    font-family: FS Emeric Light, sans-serif;
    text-shadow: 1px 1px 1px #a7a7a7;
}

body.portal section.top-slider h1.black {
    color: #000000;
}

section.top-slider.kontakt:after {
    bottom: -34px;
}

section.top-slider.kontakt h3 {
    top: auto;
    bottom: -525px;
    font-size: 48px;
    font-family: FS Emeric Light, sans-serif;
    color: #000000;
}

section.top-slider.kontakt h3:after {
    left: 46.7%;
}

/*.landingpage section.top-slider h2 {*/
/*position: absolute;*/
/*top: -510px;*/
/*text-align: left;*/
/*}*/

.landingpage section.top-slider h2:after {
    top: 16px;
    left: 0;
}

.landingpage section.top-slider h3 {
    top: -419px;
    text-align: left;
    font-size: 23px;
    font-family: "FS Emeric Medium", sans-serif;
    line-height: 36px;
    width: 600px;
}

.landingpage section.top-slider h3:after {
    position: absolute;
    top: 370px;
    left: 73%;
}

/*.landingpage section.top-slider:after {*/
/*bottom: 209px;*/
/*}*/

@media (max-width: 1024px), screen and (max-width: 1280px), (max-height: 800px) {

    section.top-slider{
        /*overflow: hidden;*/
    }

    section.top-slider h1,
    section.top-slider h2 {
        top: 155px;
        font-size: 37px;
    }

    section.top-slider h1:after,
    section.top-slider h2:after {
        top: 8px;
    }

    section.top-slider h3 {
        top: 215px;
        font-size: 17px;
    }

    section.top-slider h3:after {
        top: 13px;
        left: 47%;
    }

    body.portal section.top-slider h1 {
        font-size: 43px;
        padding: 0 22%;
    }
}



@media screen and (min-width: 1025px) and (max-width: 1280px){

    section.top-slider h1,
    section.top-slider h2 {
        top: 170px;
    }

    section.top-slider h3 {
        top: 235px;
    }

}

@media (max-width: 768px) {

    section.top-slider .slick-prev,
    section.top-slider .slick-next{
        display: none!important;
    }

    /*section.top-slider {*/
    /*margin-bottom: -105px;*/
    /*}*/

    section.top-slider h1,
    section.top-slider h2 {
        top: -143px;
        font-size: 27px;
        padding: 0 20px;

        display: none;
    }

    section.top-slider h3 {
        top: -140px;
        font-size: 14px;
        padding: 0 20px;
        display: none;
    }

    body.portal section.top-slider h1 {
        font-size: 34px;
        padding: 0 14%;
    }

    body.portal section.top-slider h1:after {
        display: none;
    }

    .top-slider img.embed-responsive-item{
        height: auto;
    }

    .top-slider .embed-responsive{
        overflow: visible;
    }

    .top-slider .slick-slide{
        height: 230px;
    }
}

@media only screen
and (max-device-width : 667px) {
    section.top-slider {
        min-height: 2em;
    }
}

/* iPad in portrait & landscape */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px) {
    section.top-slider {
        min-height: 2em;
    }
}

/* iPad in portrait */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
    section.top-slider.no-image { margin-top: 4em; }
}