.expandable-block {
    position: relative;
    overflow: hidden;
}
.expandable-block.is-collapsed {
    display: block;
    height: 200px;
    padding: 0;
    overflow: hidden;
}
@media screen and (min-width: 768px) {
    .expandable-block.is-collapsed {
        height: 275px;
    }
}

.expandable-block.is-collapsed .expandable-block__content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
}

.expandable-block:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 70px;
    visibility: hidden;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%);
}
.expandable-block__more,
.expandable-block__less {
    position: absolute;
    bottom: 0;
    left: 0;
    /*right: 0;*/
    visibility: hidden;
    z-index: 1;
    line-height: 1;
}
.expandable-block__less {
    position: static;
    margin-top: 20px;
}
.expandable-block.is-expandable.is-collapsed .expandable-block__more,
.expandable-block.is-expandable.is-collapsed:after {
    visibility: visible;
}
.expandable-block.is-expandable .expandable-block__less {
    visibility: visible;
}
.expandable-block.is-expandable.is-collapsed .expandable-block__less {
    visibility: hidden;
}
/*is-not-expandable*/
.expandable-block.is-not-expandable {
    height: auto;
    overflow: visible;
}
.expandable-block.is-not-expandable .expandable-block__content {
    position: static;
    height: auto;
    width: auto;
}
.expandable-block.is-not-expandable .expandable-block__more,
.expandable-block.is-not-expandable .expandable-block__less {
    display: none;
}
@media screen and (min-width: 768px) {
    .expandable-block.is-not-expandable {
        height: auto;
        padding-top: 0;
    }
}