.discount-overlay {
    position: fixed;
    top: 0;
    right: 60px;
    height: 60px;
    display: flex;
    background-color: #F6F4F0;
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);
}
@media screen and (max-width: 767px) {
    .discount-overlay {
        top: auto;
        bottom: 0;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
    }
}
.discount-overlay__icon-wrapper {
    font-size: 33px;
    flex: none;
    padding: 13px;
    line-height: 1;
}
.discount-overlay__text-wrapper {
    font-size: 18px;
    background-color: #EAE8E4;
    flex: none;
    padding: 12px 20px;
    line-height: 1;
}
@media screen and (max-width: 767px) {
    .discount-overlay__text-wrapper {
        font-size: 16px;
        white-space: nowrap;
    }
}