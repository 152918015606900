.simple-panel + .simple-panel {
    border-top: 1px solid #c0bdb7;
}
.simple-panel__head {
    display: block;
    padding: 10px 0;
}
.simple-panel__body {
    padding-bottom: 15px;
}
.simple-panel__title {
    font-family: var(--font-default-bold);
    font-size: 14px;
    text-transform: uppercase;
    margin: 0;
}
.simple-panel__collapse-icon {
    font-size: 8px;
    transform: rotate(180deg);
    transition: transform 120ms ease-out;
}
.simple-panel__head.collapsed .simple-panel__collapse-icon {
    transform: none;
}