:root {
    --header-bar-height: 60px;
}
.header-bar {
    display: block;
    box-shadow: 0 0 30px rgba(0,0,0,.1);
    height: 60px;
}
.header-bar__content {
    background: inherit;
    position: relative;
    z-index: 100;
}

.header-bar__logo { display: none !important; }

.header-bar__input,
.header-bar__btn {
    height: 60px;
    font-size: 18px;
}

.header-bar__input {
    background: transparent;
    /*border-left: 1px solid #eae8e4;*/
    /*border-right: 1px solid #eae8e4;*/
}
.header-bar__input.is-active {
    background: #eae8e4;
    border: none;
    box-shadow: none;
    font-family: "FS Emeric Bold", sans-serif;
}
.header-bar__input::-webkit-input-placeholder {  color: inherit; padding-top: 2px; }
.header-bar__input:-moz-placeholder {            color: inherit; }
.header-bar__input::-moz-placeholder {           color: inherit; }

/*header-bar__btn*/
.header-bar__btn {
    line-height: 59px;
    padding-top: 0;
    padding-bottom: 0;
    transition: transform 200ms 30ms ease;
    will-change: transform;
}

/*With flag*/
.header-bar--has-flag .header-bar__content,
.header-bar--has-flag .header-bar__detail {
    padding-left: 235px;
}
.header-bar__flag {
    position: absolute;
    top: 0;
    left: 30px;
}
.header-bar__detail {
    /*for more styles see header-bar__detail.css*/
    visibility: hidden;
}

.header-bar__detail .addInfos.form-inline .form-group { width: 95%; }

.header-bar__detail .addInfos.form-inline select,
.header-bar__detail .addInfos.form-inline input { float: right; }

.header-bar__detail .addInfos .iHotelier-identifier { width: 45%; }

.addInfos .header-bar__btn {
    height: 54px;
    line-height: 54px;
    background: #8c0f00 !important;
}

.header-bar__detail .youLoginMiddle {
    line-height: 60px;
    width: 100%;
    margin: 0;
    padding: 0;
    background: #000;
}

.header-bar__detail .youLoginLeft {
    line-height: 60px;
    display: block;
}

.you-login-row { margin-top: 1em; }

@media (max-width: 1750px) {
    .header-bar__detail .addInfos.form-inline select,
    .header-bar__detail .addInfos.form-inline input {
        float: none;
        width: 100%;
        display: block;
    }
}

@media (max-width: 1425px) {
    .addInfos .header-bar__btn { font-size: 16px; }
}

@media (max-width: 1210px) {
    .addInfos .header-bar__btn { font-size: 13px; }
}
